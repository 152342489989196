import { useEffect, useState } from "react";
import useSWR, { SWRResponse } from "swr";
import { useBackend } from "../backend/BackendProvider";
import { GroupRepresentation, UserRepresentation } from "../types";

export function UseGroup(): SWRResponse<UserRepresentation[], Error> {
  const backend = useBackend();
  return useSWR("group", async () => {
    return backend.connexion.getGroupUsers();
  });
}

export function GetGroup(): GroupRepresentation | undefined {
  const backend = useBackend();
  const [group, setGroup] = useState<GroupRepresentation>();
  useEffect(() => {
    async function fetchData() {
      const result = await backend.connexion.getCurrentGroup();
      result && setGroup(result);
    }
    fetchData();
  }, [backend, setGroup]);
  return group;
}
