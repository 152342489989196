import { isharrisbruno } from "./checkharrisbruno";

export const APPS = [
  "Dashboard",
  "Approve",
  "Register",
  "Connect",
  "Connect-connected",
] as const;
export type AppType = (typeof APPS)[number];

export function mapAppToUrl(app: AppType) {
  switch (app) {
    case "Approve":
      return process.env[
        isharrisbruno() ? "REACT_APP_APPROVE_URL_HB" : "REACT_APP_APPROVE_URL"
      ];
    case "Connect":
      return process.env[
        isharrisbruno() ? "REACT_APP_CONNECT_URL_HB" : "REACT_APP_CONNECT_URL"
      ];
    case "Dashboard":
      return process.env[
        isharrisbruno()
          ? "REACT_APP_DASHBOARD_URL_HB"
          : "REACT_APP_DASHBOARD_URL"
      ];
    case "Register":
      return process.env.REACT_APP_REGISTER_URL;
    case "Connect-connected":
      return `${
        process.env[
          isharrisbruno()
            ? "REACT_APP_KEYCLOAK_URL_HB"
            : "REACT_APP_KEYCLOAK_URL"
        ]
      }realms/${
        process.env.REACT_APP_KEYCLOAK_REALM
      }/protocol/openid-connect/auth?client_id=${
        process.env.REACT_APP_KEYCLOAK_CLIENT_ID
      }&redirect_uri=${
        process.env[
          isharrisbruno() ? "REACT_APP_CONNECT_URL_HB" : "REACT_APP_CONNECT_URL"
        ]
      }&response_mode=fragment&response_type=code&scope=openid`;
    default:
      return process.env[
        isharrisbruno() ? "REACT_APP_CONNECT_URL_HB" : "REACT_APP_CONNECT_URL"
      ];
  }
}
