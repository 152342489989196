import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import Menu from "./menu";
import Workteam from "./Workteam";
import Applications from "./Applications";
import Monprofil from "./monprofil";
import LandingApprove from "./ApproveProduct";
import LandingDashboard from "./DashboardProduct";
import Support from "./support";
import Homepage from "./homepage";

import styles from "./styles/home.module.scss";
import logo from "../images/logo/nothing.svg";
import { Route } from "react-router";
import { Navigate, Routes, useLocation } from "react-router-dom";
import { TopBar, useAuth, useUser } from "@mgi-labs/mgi-id";
import { useTranslation } from "react-i18next";
import Alert from "./Alert";
import { useBackend } from "../backend/BackendProvider";
import { toast } from "react-toastify";
import CreateAccount from "./CreateAccount";
import KMCreateAccount from "./KMCreateAccount";
import KMAccountsFetch from "./KMAccountsFetch";
import ValidateAccount from "./ValidateAccount";
import ProtectedRoute, { ProtectedRouteProps } from "./ProtectedRoute";
import MSDS from "./MSDS";
import Extensions from "./Extensions";
import Members from "./Members";
import { isharrisbruno } from "./Domain/checkharrisbruno";
export type ThemeType = "dark" | "light";

const Mgiconnect = () => {
  const { i18n } = useTranslation();

  const changeLang = useCallback(
    (lang: string) => {
      i18n.changeLanguage(lang);
    },
    [i18n]
  );

  const { t } = useTranslation();
  const backend = useBackend();
  const user = useUser();

  const { auth } = useAuth();

  const location = useLocation();

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    isAuthenticated: !!auth.authenticated,
    loginPath: "/createAccount",
    isKmPage: false,
    user: user,
    auth: auth,
  };

  const userAppTheme = useMemo(
    () => (typeof user === "object" ? user.appTheme : "dark"),
    [user]
  );

  const [theme, setTheme] = useState<ThemeType>(userAppTheme);

  useEffect(() => setTheme(userAppTheme), [userAppTheme]);

  //Modification connection automatique Julien MGI
  useEffect(() => {
    if (location.hash.includes("#login") && !auth.authenticated) {
      auth.login({
        redirectUri: `${
          isharrisbruno()
            ? process.env.REACT_APP_CONNECT_URL_HB
            : process.env.REACT_APP_CONNECT_URL
        }applications`,
      });
    }
  }, [auth, location.hash]);

  const handleUpdateUserTheme = useCallback(async () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    if (user?.id) {
      try {
        await backend.connexion.updateUserTheme(
          newTheme,
          user.id,
          isharrisbruno() ? "HB" : "MGI"
        );
        //toast.success(t("status_updated")); maybe use toast later
      } catch {
        toast.error(t("error_save_theme"));
      }
    }
  }, [theme, user?.id, backend.connexion, t]);

  const [isVisibleMenu, setIsVisibleMenu] = useState<boolean>(false);

  return (
    <>
      <div data-theme={theme} className={classNames(styles.home)}>
        {!location.pathname.includes("/createAccount") &&
          !location.pathname.includes("/validateAccount") && (
            <Menu
              isVisibleMenu={isVisibleMenu}
              setisVisibleMenu={setIsVisibleMenu}
              theme={theme}
              handleUpdateUserTheme={handleUpdateUserTheme}
            />
          )}

        <div className={classNames(styles.containerzone)}>
          <div
            className={classNames(
              styles.padding,
              styles.float_left,
              styles.heightbottom,
              "col-12"
            )}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href={isharrisbruno() ? t("hbCGUlink") : t("CGUlink")}
            >
              {t("CGU")}
            </a>
          </div>
          {!location.pathname.includes("/createAccount") &&
            !location.pathname.includes("/validateAccount") && (
              <div className={classNames(styles.navbarzone)}>
                <div
                  className={classNames(styles.btnmenu)}
                  onClick={() => setIsVisibleMenu(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#5f6368"
                  >
                    <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
                  </svg>
                </div>
                <TopBar
                  logo={logo}
                  pageName="Connect"
                  currentLang={i18n.language}
                  changeLang={changeLang}
                  theme={theme}
                  handleUpdateUserTheme={handleUpdateUserTheme}
                  backendUrl={process.env["REACT_APP_BACKEND_URL"]}
                  currentConnectPage={
                    location.pathname === "/workteam"
                      ? backend.limitation.packType !== "no" &&
                        backend.limitation.packType !== "beta"
                        ? "/workteam"
                        : "/members"
                      : location.pathname
                  }
                  connectUrl={
                    isharrisbruno()
                      ? process.env["REACT_APP_CONNECT_URL_HB"]
                      : process.env["REACT_APP_CONNECT_URL"]
                  }
                />
              </div>
            )}
          <Routes>
            <Route
              path="/workteam"
              element={
                backend.limitation.packType !== "no" &&
                backend.limitation.packType !== "beta" ? (
                  <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    outlet={<Workteam />}
                  />
                ) : (
                  <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    outlet={<Members />}
                  />
                )
              }
            />

            <Route path="/extensions" element={<Extensions />} />
            <Route
              path="/product-approve"
              element={<LandingApprove theme={theme} />}
            />
            <Route
              path="/product-dashboard"
              element={<LandingDashboard theme={theme} />}
            />
            <Route
              path="/applications"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<Applications />}
                />
              }
            />
            <Route
              path="/profil"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={
                    <Monprofil
                      theme={theme}
                      handleUpdateUserTheme={handleUpdateUserTheme}
                    />
                  }
                />
              }
            />

            <Route path="/support" element={<Support />}>
              <Route path=":to" element={<Support />} />
            </Route>

            <Route path="/home2" element={<Homepage theme={theme} />} />
            <Route path="/" element={<Homepage theme={theme} />} />

            {user && (
              <>
                <Route
                  path="/shareAccount"
                  element={
                    <ProtectedRoute
                      {...defaultProtectedRouteProps}
                      isKmPage={true}
                      outlet={
                        <KMCreateAccount
                          theme={theme}
                          changeLang={changeLang}
                        />
                      }
                    ></ProtectedRoute>
                  }
                />
                <Route
                  path="/KMAccountTable"
                  element={
                    <ProtectedRoute
                      {...defaultProtectedRouteProps}
                      isKmPage={true}
                      outlet={<KMAccountsFetch theme={theme} />}
                    ></ProtectedRoute>
                  }
                />
              </>
            )}
            <Route
              path="/createAccount"
              element={
                auth.authenticated ? (
                  <Navigate to="/" replace />
                ) : (
                  <div
                    data-theme={theme}
                    className={classNames(
                      styles.home,
                      styles.createaccountzone
                    )}
                  >
                    <CreateAccount changeLang={changeLang} />
                  </div>
                )
              }
            >
              <Route
                path=":groupId"
                element={
                  auth.authenticated ? (
                    <Navigate to="/" replace />
                  ) : (
                    <div
                      data-theme={theme}
                      className={classNames(
                        styles.home,
                        styles.createaccountzone
                      )}
                    >
                      <CreateAccount changeLang={changeLang} />
                    </div>
                  )
                }
              />
            </Route>

            <Route
              path="/msds"
              element={
                <div
                  data-theme={theme}
                  className={classNames(styles.home, styles.createaccountzone)}
                >
                  <MSDS changeLang={changeLang} />
                </div>
              }
            />

            <Route
              path="/validateAccount/:uuid"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={
                    <div
                      data-theme={theme}
                      className={classNames(
                        styles.home,
                        styles.createaccountzone
                      )}
                    >
                      <ValidateAccount />
                    </div>
                  }
                />
              }
            />
          </Routes>
        </div>

        <Alert />
      </div>
    </>
  );
};

export default Mgiconnect;
