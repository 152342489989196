import React, { SyntheticEvent, useCallback, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./styles/Modal.module.scss";
import logoconnectgriscomplet from "../images/logo/logoconnectmgi2.svg";
import logoconnectgriscompletwhite from "../images/logo/logoconnectmgi2white.svg";
import { useTranslation } from "react-i18next";
import { useBackend } from "../backend/BackendProvider";

import { toast } from "react-toastify";
import { ThemeType } from "@mgi-labs/mgi-id";
import { AppLanguage } from "../Internationalization/i18n";
import { useParams } from "react-router-dom";
import { mapAppToUrl } from "./Domain/redirectUrl";

const KMCreateAccount = ({
  changeLang,
  theme,
}: {
  theme: ThemeType;
  changeLang: (lang: AppLanguage) => void;
}) => {
  const { t } = useTranslation();

  const { groupId } = useParams<{
    groupId: string | undefined;
  }>();

  const backend = useBackend();

  const [email, setEmail] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [company, setCompany] = useState<string>();
  const [created, setCreated] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedCountry, setSelectedCountry] = useState<string>();

  const countryRef = useRef<HTMLInputElement>(null!);

  const handleCreateUser = useCallback(
    async (event: SyntheticEvent | KeyboardEvent) => {
      if (selectedCountry && !countries.includes(selectedCountry)) {
        event.preventDefault();
        countryRef?.current.setCustomValidity(t("valid_country"));
        countryRef.current?.reportValidity();
      }
      if (email && lastName && firstName && company && selectedCountry) {
        event.preventDefault();
        setIsLoading(true);
        try {
          await backend.connexion.createNewKMUser(
            email,
            lastName,
            firstName,
            company,
            selectedCountry,
            mapAppToUrl("Approve"),
            window.navigator.language.split("-")[0],
            groupId
          );

          toast.success(t("Sent_email") + email);
          setCreated(true);
        } catch (e) {
          setCreated(false);
          if (e instanceof Response && e.status === 409) {
            toast.error(t("email_used"));
          } else {
            toast.error("Server error");
          }
        } finally {
          setIsLoading(false);
        }
      }
    },
    [
      backend.connexion,
      company,
      email,
      firstName,
      groupId,
      lastName,
      selectedCountry,
      t,
    ]
  );

  const createaccount2 = t("createaccount2");
  const createAnotherAccount = t("KMcreateAccount.create_another_account");

  return (
    <div
      className={classNames(
        created ? styles.littlezonemodal : styles.zonemodal2
      )}
      data-theme={theme}
    >
      <div className={classNames(styles.modalcachemobile)}></div>
      <div className={classNames(styles.contactsupportzone)}>
        <a
          href={process.env.REACT_APP_CONNECT_URL + "support"}
          target="_blank"
          rel="noreferrer"
        >
          <p>{t("need_help")}</p>
        </a>
      </div>
      <div></div>
      <div className={classNames(created && styles.littlemodal, styles.modal2)}>
        <div className={classNames(styles.rightzone)}>
          {created ? (
            <form>
              <div className={classNames(styles.form, styles.littleform)}>
                <div
                  className={classNames(
                    styles.titleform,
                    styles.littletitleform
                  )}
                >
                  {/* <h2>Success</h2> */}
                  <div className={classNames(styles.logozoneconnect)}>
                    <img src={logoconnectgriscomplet} alt="Logo connect" />
                  </div>
                  <div className={classNames(styles.separationzone)}>
                    <div className={classNames(styles.separation)}></div>
                  </div>
                  {/* <h2>{t("createAccount.verification")}</h2> */}
                </div>

                <div className={classNames(styles.inputzone)}>
                  <div className={classNames(styles.inputzone, "col-12")}>
                    <p
                      className={classNames(
                        styles.textlittle,
                        styles.marginbot7
                      )}
                    >
                      {t("KMcreateAccount.success")}
                    </p>
                    <p
                      className={classNames(
                        styles.textlittle,
                        styles.marginbot11
                      )}
                    >
                      {t("KMcreateAccount.success_text")}
                    </p>
                    <p
                      className={classNames(
                        styles.textlittle,
                        styles.emailunderline
                      )}
                    >
                      {email}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  styles.btnzone,
                  styles.littlebtnzone,
                  styles.justifycenter
                )}
              >
                {/* <div
                className={classNames(styles.inputSubmit)}
                onClick={() =>
                  setIsOpenCreateAccount({ open: false, buy: false })
                }
              >
                <p>Ok</p>
              </div> */}
                <div
                  className={classNames(
                    styles.submit,
                    isLoading && styles.disabled
                  )}
                >
                  <input
                    className={classNames(styles.inputSubmit)}
                    value={createAnotherAccount}
                    type="submit"
                    onClick={() => {
                      setCreated(false);
                    }}
                  />
                </div>
              </div>
            </form>
          ) : (
            <form action="#">
              <div className={classNames(styles.form)}>
                <div className={classNames(styles.titleform)}>
                  <div className={classNames(styles.logozoneconnect)}>
                    <img
                      className={classNames(styles.dispnonew)}
                      src={logoconnectgriscomplet}
                      alt="Logo connect"
                    />
                    <img
                      className={classNames(styles.dispnoneb)}
                      src={logoconnectgriscompletwhite}
                      alt="Logo connect white"
                    />
                  </div>
                  <h2>
                    {t("KMcreatetext1")} <br /> {t("KMcreatetext2")}
                  </h2>
                  <p>{t("confirmmail")}</p>
                  <div className={classNames(styles.separation)}></div>
                </div>
                <div className={classNames(styles.inputzone)}>
                  <div className={classNames(styles.inputzone, "col-6")}>
                    <label htmlFor="name">
                      {t("name")}{" "}
                      <span className={classNames(styles.redtext)}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      name="lastName"
                      id="comptemgi"
                      onChange={(e) => setLastName(e.target.value)}
                      // placeholder="Nom"
                    />
                  </div>
                  <div className={classNames(styles.inputzone, "col-6")}>
                    <label htmlFor="prenom">
                      {t("Prénom")}{" "}
                      <span className={classNames(styles.redtext)}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      name="firstName"
                      id="comptemgi"
                      onChange={(e) => setFirstName(e.target.value)}
                      // placeholder="Prenom"
                    />
                  </div>
                  <div className={classNames(styles.inputzone, "col-12")}>
                    <label htmlFor="mail">
                      {t("mailadd")}{" "}
                      <span className={classNames(styles.redtext)}>*</span>
                    </label>
                    <input
                      // placeholder="example@gmail.com"
                      required
                      type="email"
                      name="email"
                      id="comptemgi"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className={classNames(styles.inputzone, "col-6")}>
                    <label htmlFor="entreprise">
                      {t("Entreprise2")}{" "}
                      <span className={classNames(styles.redtext)}>*</span>
                    </label>
                    <input
                      required
                      // placeholder="Company"
                      type="text"
                      name="company"
                      id="comptemgi"
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </div>
                  <div className={classNames(styles.inputzone, "col-6")}>
                    <label htmlFor="country">
                      {t("Country2")}{" "}
                      <span className={classNames(styles.redtext)}>*</span>
                    </label>

                    <input
                      list="countries"
                      id="country"
                      name="country"
                      type="text"
                      required
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      ref={countryRef}
                    />
                    <datalist id="countries">
                      {countries.map((country) => (
                        <option value={country}>{country}</option>
                      ))}
                    </datalist>
                  </div>
                </div>
              </div>

              <div className={classNames(styles.btnzone)}>
                <div className={classNames(styles.onebtn)}>
                  <div
                    className={classNames(
                      styles.submit,
                      isLoading && styles.disabled
                    )}
                  >
                    <input
                      className={classNames(styles.inputSubmit)}
                      type="submit"
                      value={createaccount2}
                      onClick={(e) => {
                        !isLoading && handleCreateUser(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default KMCreateAccount;

const countries = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of The",
  "Cook Islands",
  "Costa Rica",
  "Cote D'ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and The Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and The South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
