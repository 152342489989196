import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./styles/adduser.module.scss";
import closebtn from "../images/icon/close.svg";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useBackend } from "../backend/BackendProvider";
import { mutate } from "swr";
import { mapAppToUrl } from "./Domain/redirectUrl";
import { useUser } from "@mgi-labs/mgi-id";
import { useLocation } from "react-router-dom";

export const LANGUAGES = ["en", "fr"];

const Adduser = ({
  handleToggleVisibleAdduser,
  isVisibleAdduser,
  setVisibleAddUser,
}: {
  handleToggleVisibleAdduser: (
    event: React.SyntheticEvent<Element, Event> | React.KeyboardEvent<Element>
  ) => void;
  isVisibleAdduser: Boolean;
  setVisibleAddUser: React.Dispatch<boolean>;
}) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const backend = useBackend();
  const user = useUser();

  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [language, setLanguage] = useState<string | undefined>(i18n.language);

  useEffect(() => {
    !language && setLanguage(user?.lang);
  }, [language, user?.lang]);

  const sendInvite = t("send_invite");

  const handleCreateUser = useCallback(
    async (event: SyntheticEvent | KeyboardEvent) => {
      event.preventDefault();

      if (email && lastName && firstName && language)
        try {
          await backend.connexion.createUser(
            email,
            lastName,
            firstName,
            language,
            mapAppToUrl(
              location.hash.includes("register")
                ? "Register"
                : "Connect-connected"
            )
          );

          toast.success(t("Sent_email") + email);
        } catch (e) {
          if (e instanceof Response && e.status === 409) {
            toast.error("Email already used");
          } else {
            toast.error("Server error");
          }
        } finally {
          setVisibleAddUser(false);
          mutate("group");
        }
    },
    [
      backend.connexion,
      email,
      firstName,
      language,
      lastName,
      location.hash,
      setVisibleAddUser,
      t,
    ]
  );

  return (
    <>
      <div
        className={classNames(
          styles.modalbkg,
          !isVisibleAdduser ? styles.menuhide : ""
        )}
      >
        <div
          onClick={handleToggleVisibleAdduser}
          className={classNames(styles.bkg)}
        ></div>
        <div className={classNames(styles.modal)}>
          <img
            onClick={handleToggleVisibleAdduser}
            className={classNames(styles.close)}
            src={closebtn}
            alt="Close"
          />
          <div className={classNames(styles.title)}>
            <p>{t("add_user")}</p>
          </div>
          <div className={classNames(styles.content)}>
            <div className={classNames(styles.inputtext, styles.padding10)}>
              <label htmlFor="mail">{t("email")} * : </label>
              <input
                id="mail"
                type="mail"
                placeholder="exemple@mail.com"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={classNames(styles.inputtext)}>
              <label htmlFor="prenom">{t("first_name")} * : </label>
              <input
                id="prenom"
                type="text"
                placeholder={t("your_first_name")}
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className={classNames(styles.inputtext)}>
              <label htmlFor="nom">{t("last_name")} * : </label>
              <input
                id="nom"
                type="text"
                placeholder={t("your_last_name")}
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className={classNames(styles.inputtext)}>
              <label htmlFor="nom">{t("language")} * : </label>
              <select
                id="lang"
                required
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                {LANGUAGES.map((l) => (
                  <option value={l}>{l}</option>
                ))}
              </select>
            </div>
            {/* <div className={classNames(styles.inputtext)}>
              <label htmlFor="nom">{t("redirect_app")} * : </label>
              <select
                id="lang"
                placeholder={app}
                required
                defaultValue={app}
                onChange={(e) => setApp(e.target.value as AppType)}
                className={classNames(styles.selectApp)}
              >
                {APPS.map(
                  (a) =>
                    a !== "Connect" && (
                      <option value={a}>
                        {a === "Connect-connected" ? "Connect" : a}
                      </option>
                    )
                )}
              </select>
            </div> */}
            <div className={classNames(styles.submit)}>
              <input
                required
                type="submit"
                value={sendInvite}
                onClick={handleCreateUser}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Adduser;
