import React from "react";
import { ThemeType } from "@mgi-labs/mgi-id";
import KMTabCreateAccount from "./KMTabAccounts";
import { UseKMUsers } from "../hooks/UseKmUsers";

const KMAccountsFetch = ({ theme }: { theme: ThemeType }) => {
  const { data: users } = UseKMUsers();

  return users ? <KMTabCreateAccount theme={theme} users={users} /> : null;
};

export default KMAccountsFetch;
