import React from "react";
import classNames from "classnames";

import styles from "./styles/ApproveProduct.module.scss";

import approve_logo_alone from "../images/logo/Logo-Approve2.svg";

import icon_PDF from "../images/icon/_icon_PDF.svg";
import icon_puce from "../images/icon/_icon_puce2.svg";

import icon_intuitif from "../images/icon/_icon_intuitif.svg";

// import icongroup from "../images/icon/icongroup.svg";
import element3D from "../images/png/3Delement.png";
import fusee from "../images/png/fusee.png";
import dassault from "../images/png/dassault.png";
import screenapprove from "../images/png/screenapprove.png";
import screenapprovemobile from "../images/png/screenapprovemobile.png";

import color from "../images/icon/color.svg";
import foil from "../images/icon/foil.svg";
import varnish from "../images/icon/varnish.svg";

import { useTranslation } from "react-i18next";
import { ThemeType } from "./MgiConnectRouter";
import { useAuth } from "@mgi-labs/mgi-id";
import ecran from "../images/screenapproveproduct.png";
import i18n from "../Internationalization/i18n";
import { isharrisbruno } from "./Domain/checkharrisbruno";

const ApproveProduct = ({ theme }: { theme: ThemeType }) => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  return (
    <>
      {/* <div className={classNames(styles.boxwelcom, "col-12")}>
        <div className={classNames(styles.welcom, "col-12")}>
          <p>{t("Approve")}</p>

          <div
            className={classNames(styles.primary_btn)}
            // onClick={() => !auth.authenticated}
          >
            {!auth.authenticated ? (
              <p>{t("Tester")}</p>
            ) : (
              <a href={process.env.REACT_APP_APPROVE_URL}>{t("Tester")}</a>
            )}
          </div>
        </div>
      </div> */}
      <div className={classNames(styles.container)}>
        {/* Vrai landing Approve */}
        <div
          data-theme={"dark"}
          className={classNames(
            styles.padding,
            styles.ht2,
            styles.ht2sm,
            styles.float_left,
            styles.cursorpointer,
            "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          )}
        >
          <div
            className={classNames(
              styles.tile,
              styles.align,
              styles.bluebackground
            )}
          >
            <a
              href={
                process.env[
                  isharrisbruno()
                    ? "REACT_APP_APPROVE_URL_HB"
                    : "REACT_APP_APPROVE_URL"
                ]
              }
              target="_blank"
              rel="noreferrer"
            >
              <div className={classNames(styles.light)}></div>
            </a>
            <div className={classNames(styles.topbardecouvrir)}>
              <img
                className={classNames(styles.blendmodeluminosity)}
                src={approve_logo_alone}
                alt="Logo approve"
              />
              <div className={classNames(styles.bkgtextzone)}>
                <p>MGI Approve</p>
              </div>
            </div>
            <div className={classNames(styles.contentdecouvrir, "col-12")}>
              <p>
                Donnez plus de relief à vos projets,
                <br /> Dès maintenant avec <br />
                MGI Approve
              </p>
              <img src={ecran} alt="screen" />
            </div>
            {/* <img src={approve} alt="Video de approve" /> */}

            <div className={classNames(styles.botbardecouvrir, "col-12")}>
              <div className={classNames(styles.btngroup)}>
                {!auth.authenticated ? (
                  <div
                    className={classNames(
                      styles.btn,
                      styles.positionrelativebtn
                    )}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={"https://connect.osc-fr1.scalingo.io/createAccount"}
                    >
                      <p>Tester Maintenant</p>
                    </a>
                  </div>
                ) : (
                  <div
                    className={classNames(
                      styles.btn,
                      styles.positionrelativebtn
                    )}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://approve.mgi-labs.com/"
                    >
                      <p>Ouvrir Approve</p>
                    </a>
                  </div>
                )}
                <div
                  className={classNames(
                    styles.btn,
                    styles.positionrelativebtn,
                    styles.marginleft
                  )}
                >
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://shop.mgi-labs.com/${i18n.language}/11-packs`}
                  >
                    <p>S'abonner</p>
                  </a>
                </div>
              </div>
              <div className={classNames(styles.btngroup)}>
                <p>À partir de 23,99 € par mois TTC</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.padding,
            styles.ht15,
            styles.float_left,
            styles.ht15sm,

            "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          )}
        >
          <div className={classNames(styles.tile, styles.textcolor)}>
            <div className={classNames(styles.light)}></div>

            <div className={classNames(styles.topbar, "col-12")}>
              <p className={classNames(styles.approvecolor)}>{t("Unique")}</p>
              <div className={classNames(styles.text_icon)}>
                {/* <img
                  className={classNames(styles.icon_3D)}
                  src={icon_3D}
                  alt="3D"
                /> */}
              </div>
            </div>
            <div className={classNames(styles.content, "col-12")}>
              <p>{t("Unique_content")}</p>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.padding,
            styles.ht15,
            styles.float_left,
            styles.ht15sm,

            "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          )}
        >
          <div className={classNames(styles.tile, styles.textcolor)}>
            <div className={classNames(styles.light)}></div>

            <div
              className={classNames(styles.content, styles.notopbar, "col-12")}
            >
              <svg
                className={classNames(styles.svginvertcolor)}
                id="Groupe_778"
                data-name="Groupe 778"
                xmlns="http://www.w3.org/2000/svg"
                width="276"
                height="265.385"
                viewBox="0 0 276 265.385"
              >
                <g id="Groupe_582" transform="translate(1.847 0)">
                  <g
                    id="noun_ar_2300882"
                    className={classNames(styles.Rectangle)}
                    transform="translate(0)"
                  >
                    <g id="Groupe_580">
                      <g id="Tracé_1308">
                        <path
                          id="Tracé_60"
                          data-name="Tracé 60"
                          d="M21.964,31.118a.842.842,0,0,0-1.08-.389l-4.361,2.159V27.749a.82.82,0,0,0-1.641,0v5.139l-4.361-2.2a1.016,1.016,0,0,0-.345-.086.8.8,0,0,0-.734.475.991.991,0,0,0-.043.6.785.785,0,0,0,.432.475l5.484,2.764a.826.826,0,0,0,.777,0l5.484-2.764A.806.806,0,0,0,21.964,31.118ZM21.618,3.136,16.091.372a.825.825,0,0,0-.777,0L9.83,3.179A.8.8,0,1,0,10.564,4.6l4.361-2.159V7.584a.82.82,0,1,0,1.641,0V2.4l4.361,2.159a.839.839,0,0,0,1.08-.345A.906.906,0,0,0,21.618,3.136ZM7.325,5.943a.949.949,0,0,0-.043-.6.836.836,0,0,0-.734-.432,1.261,1.261,0,0,0-.389.086L.675,7.713A.8.8,0,0,0,.2,8.447v6.434a.82.82,0,0,0,1.641,0v-5.1L6.2,11.945a.839.839,0,0,0,1.08-.345.788.788,0,0,0-.345-1.08L2.877,8.49l4.1-2.073A.811.811,0,0,0,7.325,5.943Zm0,23.361a.713.713,0,0,0-.432-.432l-4.059-2.03,4.1-2.073a.723.723,0,0,0,.389-.475.949.949,0,0,0-.043-.6.8.8,0,0,0-1.08-.345l-4.361,2.2V20.409a.82.82,0,0,0-1.641,0v6.434a.735.735,0,0,0,.043.3v.043h0a.613.613,0,0,0,.389.345L6.159,30.34c.043,0,.043.043.086.043a.822.822,0,0,0,1.08-1.08Zm23.923-8.9a.82.82,0,0,0-1.641,0v5.139l-4.361-2.159A1.016,1.016,0,0,0,24.9,23.3a.891.891,0,0,0-.734.432.8.8,0,0,0,.345,1.08l4.059,2.03-4.059,2.03a.855.855,0,0,0-.3,1.08.842.842,0,0,0,1.08.389l5.484-2.764a.8.8,0,0,0,.475-.734ZM31.2,8.188a.042.042,0,0,0-.043-.043.847.847,0,0,0-.389-.389L25.246,4.993a.839.839,0,0,0-1.08.345.788.788,0,0,0,.345,1.08l4.059,2.03-4.059,2.03a.826.826,0,0,0-.345,1.08.842.842,0,0,0,1.08.389l4.361-2.159v5.139a.82.82,0,0,0,.82.82h0a.821.821,0,0,0,.561-.216.76.76,0,0,0,.259-.561V8.49C31.248,8.361,31.2,8.274,31.2,8.188Z"
                          transform="translate(-0.2 -0.275)"
                          fill="#417c9d"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="noun_ar_2300882-2" transform="translate(9.244 11.993)">
                    <g id="Groupe_580-2" transform="translate(0 0)">
                      <g id="Tracé_1308-2">
                        <path
                          id="Tracé_61"
                          data-name="Tracé 61"
                          d="M12.88.436a.869.869,0,0,1-.389.475l-5.009,2.5-.043.043V8.468a.64.64,0,0,1-.043.3c-.043.086-.086.13-.13.216h0a.042.042,0,0,1-.043.043.838.838,0,0,1-1.123,0l-.043-.043h0a.944.944,0,0,1-.13-.216.322.322,0,0,1-.13-.3V3.459L.746.911A.669.669,0,0,1,.4.566C.4.523.357.48.357.393A.6.6,0,0,1,.357,0c0-.043,0-.086.043-.086,0-.043.043-.043.043-.086A.836.836,0,0,1,1.178-.6a1.016,1.016,0,0,1,.345.086L6.662,2.077,11.8-.514c.086-.043.173-.043.259-.086h.086a.548.548,0,0,1,.259.043.869.869,0,0,1,.475.389A.962.962,0,0,1,12.88.436Z"
                          transform="translate(-0.325 0.6)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="_icon_AR" transform="translate(121.236 0)">
                  <g
                    id="Rectangle_985"
                    className={classNames(styles.Rectangle)}
                    transform="translate(0)"
                  >
                    <g id="Groupe_81" data-name="Groupe 81">
                      <path
                        id="Tracé_69"
                        data-name="Tracé 69"
                        d="M21.518,30.7h-1.45L16.3,40.025h1.3l1.063-2.706h4.252l1.063,2.706h1.3Zm-2.464,5.6,1.208-3.044a12.334,12.334,0,0,0,.483-1.4l.048-.193.193.531a4.942,4.942,0,0,0,.387,1.015l1.208,3.044H19.054Z"
                        transform="translate(-8.811 -18.379)"
                        fill="none"
                      />
                      <path
                        id="Tracé_70"
                        data-name="Tracé 70"
                        d="M43.411,36.067A3.154,3.154,0,0,0,45.1,35.2a2.7,2.7,0,0,0,.58-1.739,2.3,2.3,0,0,0-.87-1.933A3.7,3.7,0,0,0,42.4,30.8H38v9.277h1.256V36.211h2.9l2.416,3.865h1.45ZM39.3,35.2v-3.43h3a2.6,2.6,0,0,1,1.594.435,1.46,1.46,0,0,1,.531,1.256,1.636,1.636,0,0,1-.531,1.3,2.459,2.459,0,0,1-1.546.483H39.3Z"
                        transform="translate(-20.026 -18.431)"
                        fill="none"
                      />
                      <path
                        id="Tracé_71"
                        data-name="Tracé 71"
                        d="M34.574,5.2H1.766a.811.811,0,0,0-.676.29.664.664,0,0,0-.242.387.613.613,0,0,0-.048.29V39.022a.613.613,0,0,0,.048.29.74.74,0,0,0,.242.338.954.954,0,0,0,.676.29H34.574a.969.969,0,0,0,.966-.966V6.118A.96.96,0,0,0,34.574,5.2ZM34.09,38.49H2.25V6.6H34.09Z"
                        transform="translate(-0.8 -5.2)"
                        fill="none"
                      />
                    </g>
                    <g id="Groupe_82" data-name="Groupe 82">
                      <path
                        id="Tracé_72"
                        data-name="Tracé 72"
                        d="M34.574,5.2H1.766a.811.811,0,0,0-.676.29.664.664,0,0,0-.242.387.613.613,0,0,0-.048.29V39.022a.613.613,0,0,0,.048.29.74.74,0,0,0,.242.338.954.954,0,0,0,.676.29H34.574a.969.969,0,0,0,.966-.966V6.118A.96.96,0,0,0,34.574,5.2ZM34.09,38.49H2.25V6.6H34.09Z"
                        transform="translate(-0.8 -5.2)"
                        fill="#417c9d"
                      />
                    </g>
                  </g>
                  <g
                    id="Groupe_83"
                    data-name="Groupe 83"
                    transform="translate(7.489 12.321)"
                  >
                    <path
                      id="Tracé_73"
                      data-name="Tracé 73"
                      d="M21.518,30.7h-1.45L16.3,40.025h1.3l1.063-2.706h4.252l1.063,2.706h1.3Zm-2.464,5.6,1.208-3.044a12.334,12.334,0,0,0,.483-1.4l.048-.193.193.531a4.942,4.942,0,0,0,.387,1.015l1.208,3.044H19.054Z"
                      transform="translate(-16.3 -30.7)"
                    />
                    <path
                      id="Tracé_74"
                      data-name="Tracé 74"
                      d="M43.411,36.067A3.154,3.154,0,0,0,45.1,35.2a2.7,2.7,0,0,0,.58-1.739,2.3,2.3,0,0,0-.87-1.933A3.7,3.7,0,0,0,42.4,30.8H38v9.277h1.256V36.211h2.9l2.416,3.865h1.45ZM39.3,35.2v-3.43h3a2.6,2.6,0,0,1,1.594.435,1.46,1.46,0,0,1,.531,1.256,1.636,1.636,0,0,1-.531,1.3,2.459,2.459,0,0,1-1.546.483H39.3Z"
                      transform="translate(-27.515 -30.752)"
                    />
                  </g>
                  <g
                    id="Groupe_84"
                    data-name="Groupe 84"
                    transform="translate(7.489 12.321)"
                  >
                    <path
                      id="Tracé_75"
                      data-name="Tracé 75"
                      d="M21.518,30.7h-1.45L16.3,40.025h1.3l1.063-2.706h4.252l1.063,2.706h1.3Zm-2.464,5.6,1.208-3.044a12.334,12.334,0,0,0,.483-1.4l.048-.193.193.531a4.942,4.942,0,0,0,.387,1.015l1.208,3.044H19.054Z"
                      transform="translate(-16.3 -30.7)"
                      fill="none"
                      stroke="#000"
                      stroke-width="0.5"
                    />
                    <path
                      id="Tracé_76"
                      data-name="Tracé 76"
                      d="M43.411,36.067A3.154,3.154,0,0,0,45.1,35.2a2.7,2.7,0,0,0,.58-1.739,2.3,2.3,0,0,0-.87-1.933A3.7,3.7,0,0,0,42.4,30.8H38v9.277h1.256V36.211h2.9l2.416,3.865h1.45ZM39.3,35.2v-3.43h3a2.6,2.6,0,0,1,1.594.435,1.46,1.46,0,0,1,.531,1.256,1.636,1.636,0,0,1-.531,1.3,2.459,2.459,0,0,1-1.546.483H39.3Z"
                      transform="translate(-27.515 -30.752)"
                      fill="none"
                      stroke="#000"
                      stroke-width="0.5"
                    />
                  </g>
                </g>
                <g id="_icon_times" transform="translate(241.308 0.423)">
                  <g
                    id="Groupe_637"
                    className={classNames(styles.Rectangle)}
                    transform="translate(0 0)"
                  >
                    <g id="Groupe_585">
                      <g
                        id="Rectangle_985-2"
                        className={classNames(styles.Rectangle)}
                        data-name="Rectangle_985"
                      >
                        <g id="Groupe_105" data-name="Groupe 105">
                          <rect
                            id="Rectangle_293"
                            data-name="Rectangle 293"
                            width="2.49"
                            height="1.437"
                            transform="translate(5.938 9.387)"
                            fill="none"
                          />
                          <rect
                            id="Rectangle_294"
                            data-name="Rectangle 294"
                            width="3.879"
                            height="1.389"
                            transform="translate(4.55 13.17)"
                            fill="none"
                          />
                          <rect
                            id="Rectangle_295"
                            data-name="Rectangle 295"
                            width="2.49"
                            height="1.437"
                            transform="translate(5.938 16.953)"
                            fill="none"
                          />
                          <path
                            id="Tracé_112"
                            data-name="Tracé 112"
                            d="M29.432,15.824l3.257,2.3-.862,1.293L27.9,16.638V10.7h1.532Z"
                            transform="translate(-8.744 -1.553)"
                            fill="none"
                          />
                          <path
                            id="Tracé_113"
                            data-name="Tracé 113"
                            d="M19.569-1h0a9.77,9.77,0,1,0,0,19.539h.1A9.77,9.77,0,0,0,19.569-1Zm-.048,18.294h0a8.312,8.312,0,0,1-3.3-.67,8.163,8.163,0,0,1-2.73-1.868,8.635,8.635,0,0,1-2.442-6.034,8.253,8.253,0,0,1,2.538-5.986A8.522,8.522,0,0,1,19.569.293h.1a8.5,8.5,0,0,1-.144,17Z"
                            transform="translate(0.688 4.544)"
                            fill="none"
                          />
                          <path
                            id="Tracé_114"
                            data-name="Tracé 114"
                            d="M22.333-7.73a1.043,1.043,0,0,0-.239-.383.946.946,0,0,0-.67-.287H-11.142a.961.961,0,0,0-.958.958V25.123a.961.961,0,0,0,.958.958H21.423a.946.946,0,0,0,.67-.287,1.043,1.043,0,0,0,.239-.383.607.607,0,0,0,.048-.287V-7.442A.607.607,0,0,0,22.333-7.73ZM20.945,24.645h-31.56V-6.963H20.992l-.048,31.608Z"
                            transform="translate(12.1 8.4)"
                            fill="none"
                          />
                        </g>
                        <g
                          id="Groupe_106"
                          data-name="Groupe 106"
                          transform="translate(0 0.239)"
                        >
                          <path
                            id="Tracé_115"
                            data-name="Tracé 115"
                            d="M22.333-7.23a1.043,1.043,0,0,0-.239-.383.946.946,0,0,0-.67-.287H-11.142a.961.961,0,0,0-.958.958V25.623a.961.961,0,0,0,.958.958H21.423a.946.946,0,0,0,.67-.287,1.043,1.043,0,0,0,.239-.383.607.607,0,0,0,.048-.287V-6.942A.607.607,0,0,0,22.333-7.23ZM20.945,25.145h-31.56V-6.463H20.992l-.048,31.608Z"
                            transform="translate(12.1 7.9)"
                            fill="#417c9d"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="fast" transform="translate(4.545 7.477)">
                    <g id="Groupe_625" transform="translate(1.401 5.822)">
                      <g id="Groupe_624" transform="translate(0 0)">
                        <g id="Rectangle_994">
                          <rect
                            id="Rectangle_296"
                            data-name="Rectangle 296"
                            width="2.49"
                            height="1.437"
                            stroke="#000"
                            stroke-width="0.2"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="Groupe_627" transform="translate(1.401 13.387)">
                      <g id="Groupe_626" transform="translate(0 0)">
                        <g id="Rectangle_995">
                          <rect
                            id="Rectangle_297"
                            data-name="Rectangle 297"
                            width="2.49"
                            height="1.437"
                            stroke="#000"
                            stroke-width="0.2"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="Groupe_629" transform="translate(0 9.604)">
                      <g id="Groupe_628" transform="translate(0 0)">
                        <g id="Rectangle_996">
                          <rect
                            id="Rectangle_298"
                            data-name="Rectangle 298"
                            width="3.879"
                            height="1.389"
                            stroke="#000"
                            stroke-width="0.2"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="Groupe_631" transform="translate(5.944 0)">
                      <g id="Groupe_630" transform="translate(0)">
                        <g id="Tracé_1337">
                          <path
                            id="Tracé_116"
                            data-name="Tracé 116"
                            d="M-1.679.1h0A9.79,9.79,0,0,0-11.4,9.965a9.791,9.791,0,0,0,9.77,9.674h.1A9.8,9.8,0,0,0,8.139,9.822,9.78,9.78,0,0,0-1.679.1Zm0,18.294h0a8.312,8.312,0,0,1-3.3-.67,8.163,8.163,0,0,1-2.73-1.868,8.635,8.635,0,0,1-2.442-6.034A8.253,8.253,0,0,1-7.617,3.835a8.368,8.368,0,0,1,5.986-2.49h.1A8.555,8.555,0,0,1,6.894,9.87,8.554,8.554,0,0,1-1.679,18.394Z"
                            transform="translate(11.401 -0.1)"
                            stroke="#000"
                            stroke-width="0.2"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="Groupe_633" transform="translate(14.638 5.582)">
                      <g id="Groupe_632" transform="translate(0 0)">
                        <g id="Tracé_1338">
                          <path
                            id="Tracé_117"
                            data-name="Tracé 117"
                            d="M-7.259,7.223l-.862,1.341L-12,5.786V-.2h1.485V4.924Z"
                            transform="translate(12 0.2)"
                            stroke="#000"
                            stroke-width="0.2"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="_icon_confidential" transform="translate(0 115.322)">
                  <g
                    id="Groupe_621"
                    className={classNames(styles.Rectangle)}
                    transform="translate(0 0)"
                  >
                    <g
                      id="Rectangle_985-3"
                      className={classNames(styles.Rectangle)}
                      data-name="Rectangle_985"
                    >
                      <g id="Groupe_89" data-name="Groupe 89">
                        <path
                          id="Tracé_85"
                          data-name="Tracé 85"
                          d="M32.632,27a4.521,4.521,0,0,0-3.184,1.11A4.356,4.356,0,0,0,28,31.193a4.048,4.048,0,0,0,1.737,3.329v5.259h5.307V34.571a4.184,4.184,0,0,0,1.737-3.329v-.048A4.394,4.394,0,0,0,32.632,27ZM34.176,33.7l-.29.193v4.728H30.991V33.9L30.7,33.7a2.985,2.985,0,0,1-1.447-2.461,3.239,3.239,0,0,1,1.061-2.219,3.121,3.121,0,0,1,2.316-.82,3.186,3.186,0,0,1,3.04,2.991A3.217,3.217,0,0,1,34.176,33.7Z"
                          transform="translate(-14.828 -16.284)"
                          fill="none"
                        />
                        <path
                          id="Tracé_86"
                          data-name="Tracé 86"
                          d="M34.475,4.8H1.665A.968.968,0,0,0,.7,5.765v32.81a.968.968,0,0,0,.965.965h32.81a.968.968,0,0,0,.965-.965V5.765A.968.968,0,0,0,34.475,4.8Zm-.482,33.244H2.147V6.2H33.992Z"
                          transform="translate(-0.7 -4.8)"
                          fill="none"
                        />
                      </g>
                      <g id="Groupe_90" data-name="Groupe 90">
                        <path
                          id="Tracé_87"
                          data-name="Tracé 87"
                          d="M34.475,4.8H1.665A.968.968,0,0,0,.7,5.765v32.81a.968.968,0,0,0,.965.965h32.81a.968.968,0,0,0,.965-.965V5.765A.968.968,0,0,0,34.475,4.8Zm-.482,33.244H2.147V6.2H33.992Z"
                          transform="translate(-0.7 -4.8)"
                          fill="#407c9c"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="Tracé_1332" transform="translate(12.738 10.032)">
                    <path
                      id="Tracé_88"
                      data-name="Tracé 88"
                      d="M32.214,25.6A5.219,5.219,0,0,0,28.692,26.8,4.705,4.705,0,0,0,27.1,30.228,4.338,4.338,0,0,0,29.03,33.9v5.79h5.838V33.992a4.665,4.665,0,0,0,1.93-3.667v-.048A4.982,4.982,0,0,0,32.214,25.6Zm1.689,7.43-.338.193v5.211H30.381V33.22l-.338-.193a3.247,3.247,0,0,1-1.592-2.7,3.7,3.7,0,0,1,1.158-2.461A3.52,3.52,0,0,1,35.5,30.228,3.328,3.328,0,0,1,33.9,33.027Z"
                      transform="translate(-27.1 -25.592)"
                    />
                  </g>
                </g>
                <g id="_icon_security" transform="translate(120.679 115.315)">
                  <g id="Ellipse_1" transform="translate(20.333 9.829)">
                    <g id="Groupe_101" data-name="Groupe 101">
                      <path
                        id="Tracé_103"
                        data-name="Tracé 103"
                        d="M46.755,26.326a2.1,2.1,0,0,0-.337-.289.25.25,0,0,0-.193-.1c-.145-.048-.241-.145-.385-.145a2.409,2.409,0,0,0-1.349,0,3.32,3.32,0,0,0-.385.145.365.365,0,0,1-.193.1c-.1.1-.241.193-.337.289a2.3,2.3,0,0,0-.675,1.59,2.216,2.216,0,0,0,4.433,0A1.752,1.752,0,0,0,46.755,26.326Zm-1.542,2.843a1.244,1.244,0,0,1-1.253-1.253,1.276,1.276,0,0,1,1.253-1.253,1.244,1.244,0,0,1,1.253,1.253A1.276,1.276,0,0,1,45.213,29.169Z"
                        transform="translate(-42.9 -25.7)"
                        fill="none"
                      />
                    </g>
                    <g id="Groupe_102" data-name="Groupe 102">
                      <path
                        id="Tracé_104"
                        data-name="Tracé 104"
                        d="M46.755,26.326a2.1,2.1,0,0,0-.337-.289.25.25,0,0,0-.193-.1c-.145-.048-.241-.145-.385-.145a2.409,2.409,0,0,0-1.349,0,3.32,3.32,0,0,0-.385.145.365.365,0,0,1-.193.1c-.1.1-.241.193-.337.289a2.3,2.3,0,0,0-.675,1.59,2.216,2.216,0,0,0,4.433,0A1.752,1.752,0,0,0,46.755,26.326Zm-1.542,2.843a1.244,1.244,0,0,1-1.253-1.253,1.276,1.276,0,0,1,1.253-1.253,1.244,1.244,0,0,1,1.253,1.253A1.276,1.276,0,0,1,45.213,29.169Z"
                        transform="translate(-42.9 -25.7)"
                      />
                    </g>
                  </g>
                  <g
                    id="key-silhouette-security-tool-interface-symbol-of-password_icon-icons.com_54503"
                    transform="translate(5.204 5.204)"
                  >
                    <g id="Tracé_1335">
                      <path
                        id="Tracé_105"
                        data-name="Tracé 105"
                        d="M27.46,15.1h-.482a7.845,7.845,0,0,0-7.806,7.854,8.709,8.709,0,0,0,.385,2.457L10.5,34.373v5.011h5.3v-2.6h2.6v-2.6h2.313l3.806-3.806a8.709,8.709,0,0,0,2.457.385A7.84,7.84,0,0,0,27.46,15.1Zm-.482,14.648a8.709,8.709,0,0,1-2.457-.385l-.289-.1-3.951,3.951H17.39v2.6h-2.6v2.6H11.415V34.759L20.57,25.6l-.1-.289a10.51,10.51,0,0,1-.434-2.457,6.855,6.855,0,0,1,2.024-4.866,6.932,6.932,0,0,1,4.722-2.024h.145a6.913,6.913,0,0,1,6.794,6.794A6.789,6.789,0,0,1,26.979,29.748Z"
                        transform="translate(-10.5 -15.1)"
                        stroke="#000"
                        stroke-width="0.3"
                      />
                    </g>
                  </g>
                  <g
                    className={classNames(styles.Rectangle)}
                    id="Rectangle_985-4"
                    data-name="Rectangle_985"
                  >
                    <g id="Groupe_103" data-name="Groupe 103">
                      <path
                        id="Tracé_106"
                        data-name="Tracé 106"
                        d="M46.755,26.326a2.1,2.1,0,0,0-.337-.289.25.25,0,0,0-.193-.1c-.145-.048-.241-.145-.385-.145a2.409,2.409,0,0,0-1.349,0,3.32,3.32,0,0,0-.385.145.365.365,0,0,1-.193.1c-.1.1-.241.193-.337.289a2.3,2.3,0,0,0-.675,1.59,2.216,2.216,0,0,0,4.433,0A1.752,1.752,0,0,0,46.755,26.326Zm-1.542,2.843a1.244,1.244,0,0,1-1.253-1.253,1.276,1.276,0,0,1,1.253-1.253,1.244,1.244,0,0,1,1.253,1.253A1.276,1.276,0,0,1,45.213,29.169Z"
                        transform="translate(-22.567 -15.871)"
                        fill="none"
                      />
                      <path
                        id="Tracé_107"
                        data-name="Tracé 107"
                        d="M22.864,10.5h-.482a7.845,7.845,0,0,0-7.806,7.854,8.709,8.709,0,0,0,.385,2.457L5.9,29.777v5.011h5.3v-2.6h2.6v-2.6h2.313l3.806-3.806a8.709,8.709,0,0,0,2.457.385A7.84,7.84,0,0,0,22.864,10.5Zm-.482,14.648a8.709,8.709,0,0,1-2.457-.385l-.289-.1L15.685,28.62H12.794v2.6h-2.6v2.6H6.819V30.162l9.155-9.155-.1-.289a10.51,10.51,0,0,1-.434-2.457,6.855,6.855,0,0,1,2.024-4.866,6.932,6.932,0,0,1,4.722-2.024h.145a6.913,6.913,0,0,1,6.794,6.794A6.789,6.789,0,0,1,22.382,25.151ZM22.864,10.5h-.482a7.845,7.845,0,0,0-7.806,7.854,8.709,8.709,0,0,0,.385,2.457L5.9,29.777v5.011h5.3v-2.6h2.6v-2.6h2.313l3.806-3.806a8.709,8.709,0,0,0,2.457.385A7.84,7.84,0,0,0,22.864,10.5Zm-.482,14.648a8.709,8.709,0,0,1-2.457-.385l-.289-.1L15.685,28.62H12.794v2.6h-2.6v2.6H6.819V30.162l9.155-9.155-.1-.289a10.51,10.51,0,0,1-.434-2.457,6.855,6.855,0,0,1,2.024-4.866,6.932,6.932,0,0,1,4.722-2.024h.145a6.913,6.913,0,0,1,6.794,6.794A6.789,6.789,0,0,1,22.382,25.151ZM34.38,5.3H1.664A.966.966,0,0,0,.7,6.264V39.028a.966.966,0,0,0,.964.964H34.38a.966.966,0,0,0,.964-.964V6.264A.966.966,0,0,0,34.38,5.3ZM33.9,38.5H2.145V6.7H33.9ZM11.2,32.186h2.6v-2.6h2.313l3.806-3.806a8.709,8.709,0,0,0,2.457.385,7.815,7.815,0,0,0,.482-15.611h-.482a7.845,7.845,0,0,0-7.806,7.854,8.709,8.709,0,0,0,.385,2.457L5.9,29.777v5.011h5.3Zm-.964,1.638H6.867V30.162l9.155-9.155-.1-.289a10.51,10.51,0,0,1-.434-2.457,6.855,6.855,0,0,1,2.024-4.866,6.932,6.932,0,0,1,4.722-2.024h.145a6.866,6.866,0,0,1,0,13.732,8.709,8.709,0,0,1-2.457-.385l-.289-.1-3.951,3.951H12.794v2.6h-2.6v2.65Z"
                        transform="translate(-0.7 -5.3)"
                        fill="none"
                      />
                    </g>
                    <g id="Groupe_104" data-name="Groupe 104">
                      <path
                        id="Tracé_108"
                        data-name="Tracé 108"
                        d="M34.38,5.3H1.664A.966.966,0,0,0,.7,6.264V39.028a.966.966,0,0,0,.964.964H34.38a.966.966,0,0,0,.964-.964V6.264A.966.966,0,0,0,34.38,5.3ZM33.9,38.5H2.145V6.7H33.9Z"
                        transform="translate(-0.7 -5.3)"
                        fill="#417c9d"
                      />
                    </g>
                  </g>
                </g>
                <g id="_icon_dashboard" transform="translate(241.26 115.322)">
                  <g
                    id="Rectangle_985-5"
                    className={classNames(styles.Rectangle)}
                    data-name="Rectangle_985"
                    transform="translate(0 0)"
                  >
                    <g id="Groupe_91" data-name="Groupe 91">
                      <rect
                        id="Rectangle_286"
                        data-name="Rectangle 286"
                        width="2.702"
                        height="12.545"
                        transform="translate(8.202 14.089)"
                        fill="none"
                      />
                      <rect
                        id="Rectangle_287"
                        data-name="Rectangle 287"
                        width="2.702"
                        height="18.383"
                        transform="translate(16.067 8.202)"
                        fill="none"
                      />
                      <rect
                        id="Rectangle_288"
                        data-name="Rectangle 288"
                        width="2.702"
                        height="6.658"
                        transform="translate(23.884 19.975)"
                        fill="none"
                      />
                      <path
                        id="Tracé_89"
                        data-name="Tracé 89"
                        d="M34.775,5.2H1.965A.968.968,0,0,0,1,6.165v32.81a.968.968,0,0,0,.965.965h32.81a.968.968,0,0,0,.965-.965V6.165A.968.968,0,0,0,34.775,5.2Zm-.482,33.292H2.447V6.647H34.292Z"
                        transform="translate(-1 -5.2)"
                        fill="none"
                      />
                    </g>
                    <g id="Groupe_92" data-name="Groupe 92">
                      <path
                        id="Tracé_90"
                        data-name="Tracé 90"
                        d="M34.775,5.2H1.965A.968.968,0,0,0,1,6.165v32.81a.968.968,0,0,0,.965.965h32.81a.968.968,0,0,0,.965-.965V6.165A.968.968,0,0,0,34.775,5.2Zm-.482,33.292H2.447V6.647H34.292Z"
                        transform="translate(-1 -5.2)"
                        fill="#417c9d"
                      />
                    </g>
                  </g>
                  <g id="Tracé_1310" transform="translate(8.202 8.202)">
                    <path
                      id="Tracé_91"
                      data-name="Tracé 91"
                      d="M18,28.086V40.631h2.7V28.086ZM25.865,22.2V40.583h2.7V22.2Zm7.816,11.773v6.658h2.7V33.973Z"
                      transform="translate(-18 -22.2)"
                      stroke="#fff"
                      stroke-width="0.3"
                    />
                  </g>
                </g>
                <g id="_icon_PDF" transform="translate(120.63 230.645)">
                  <g
                    id="Rectangle_985-6"
                    className={classNames(styles.Rectangle)}
                    data-name="Rectangle_985"
                    transform="translate(0)"
                  >
                    <g id="Groupe_85" data-name="Groupe 85">
                      <path
                        id="Tracé_77"
                        data-name="Tracé 77"
                        d="M34.075,5H1.265A.968.968,0,0,0,.3,5.965v32.81a.968.968,0,0,0,.965.965h32.81a.968.968,0,0,0,.965-.965V5.965A.968.968,0,0,0,34.075,5Zm-.482,33.244H1.747V6.447H33.592Zm-9.65-15.585h4.439v-.917H23.942V18.8h4.584v-.868H22.833v7.961h1.061V22.659Zm-4.584,2.7a3.425,3.425,0,0,0,1.351-1.4,4.5,4.5,0,0,0,.482-2.171,3.779,3.779,0,0,0-1.11-2.895,4.458,4.458,0,0,0-3.136-1.013H14.292v7.961h2.991A3.584,3.584,0,0,0,19.359,25.361Zm-3.908-.338V18.751h1.544a3.251,3.251,0,0,1,2.364.772,3.028,3.028,0,0,1,.82,2.268,3.641,3.641,0,0,1-.338,1.689,2.522,2.522,0,0,1-1.013,1.11,3.183,3.183,0,0,1-1.544.386H15.45ZM7.586,22.756H9.9a3.018,3.018,0,0,0,2.026-.676,2.327,2.327,0,0,0,.724-1.785,2.257,2.257,0,0,0-.724-1.785,3.049,3.049,0,0,0-2.075-.627H6.524v7.961H7.586V22.756Zm0-4H9.709c1.254,0,1.833.531,1.833,1.544,0,1.061-.579,1.592-1.833,1.592H7.537V18.751Z"
                        transform="translate(-0.3 -5)"
                        fill="none"
                      />
                    </g>
                    <g id="Groupe_86" data-name="Groupe 86">
                      <path
                        id="Tracé_78"
                        data-name="Tracé 78"
                        d="M34.075,5H1.265A.968.968,0,0,0,.3,5.965v32.81a.968.968,0,0,0,.965.965h32.81a.968.968,0,0,0,.965-.965V5.965A.968.968,0,0,0,34.075,5Zm-.482,33.244H1.747V6.447H33.592Z"
                        transform="translate(-0.3 -5)"
                        fill="#417c9d"
                      />
                    </g>
                  </g>
                  <g
                    id="Groupe_87"
                    data-name="Groupe 87"
                    transform="translate(6.272 12.931)"
                  >
                    <path
                      id="Tracé_79"
                      data-name="Tracé 79"
                      d="M18.7,32.427a3.049,3.049,0,0,0-2.075-.627H13.3v7.961h1.061V36.673h2.316A3.018,3.018,0,0,0,18.7,36a2.327,2.327,0,0,0,.724-1.785A2.128,2.128,0,0,0,18.7,32.427ZM16.533,35.8H14.361V32.668h2.123c1.254,0,1.833.531,1.833,1.544C18.318,35.274,17.739,35.8,16.533,35.8Z"
                      transform="translate(-13.3 -31.8)"
                    />
                    <path
                      id="Tracé_80"
                      data-name="Tracé 80"
                      d="M35.19,32.813A4.458,4.458,0,0,0,32.054,31.8H29.4v7.961h2.991a3.939,3.939,0,0,0,2.026-.482,3.425,3.425,0,0,0,1.351-1.4,4.5,4.5,0,0,0,.482-2.171A3.589,3.589,0,0,0,35.19,32.813ZM34.852,37.4a2.522,2.522,0,0,1-1.013,1.11,3.183,3.183,0,0,1-1.544.386H30.51V32.62h1.544a3.251,3.251,0,0,1,2.364.772,3.028,3.028,0,0,1,.82,2.268A4.609,4.609,0,0,1,34.852,37.4Z"
                      transform="translate(-21.632 -31.8)"
                    />
                    <path
                      id="Tracé_81"
                      data-name="Tracé 81"
                      d="M52.693,32.668V31.8H47v7.961h1.061V36.528H52.5v-.917H48.061V32.668Z"
                      transform="translate(-30.74 -31.8)"
                    />
                  </g>
                  <g
                    id="Groupe_88"
                    data-name="Groupe 88"
                    transform="translate(6.272 12.931)"
                  >
                    <path
                      id="Tracé_82"
                      data-name="Tracé 82"
                      d="M18.7,32.427a3.049,3.049,0,0,0-2.075-.627H13.3v7.961h1.061V36.673h2.316A3.018,3.018,0,0,0,18.7,36a2.327,2.327,0,0,0,.724-1.785A2.128,2.128,0,0,0,18.7,32.427ZM16.533,35.8H14.361V32.668h2.123c1.254,0,1.833.531,1.833,1.544C18.318,35.274,17.739,35.8,16.533,35.8Z"
                      transform="translate(-13.3 -31.8)"
                      fill="none"
                      stroke="#000"
                      stroke-width="0.5"
                    />
                    <path
                      id="Tracé_83"
                      data-name="Tracé 83"
                      d="M35.19,32.813A4.458,4.458,0,0,0,32.054,31.8H29.4v7.961h2.991a3.939,3.939,0,0,0,2.026-.482,3.425,3.425,0,0,0,1.351-1.4,4.5,4.5,0,0,0,.482-2.171A3.589,3.589,0,0,0,35.19,32.813ZM34.852,37.4a2.522,2.522,0,0,1-1.013,1.11,3.183,3.183,0,0,1-1.544.386H30.51V32.62h1.544a3.251,3.251,0,0,1,2.364.772,3.028,3.028,0,0,1,.82,2.268A4.609,4.609,0,0,1,34.852,37.4Z"
                      transform="translate(-21.632 -31.8)"
                      fill="none"
                      stroke="#000"
                      stroke-width="0.5"
                    />
                    <path
                      id="Tracé_84"
                      data-name="Tracé 84"
                      d="M52.693,32.668V31.8H47v7.961h1.061V36.528H52.5v-.917H48.061V32.668Z"
                      transform="translate(-30.74 -31.8)"
                      fill="none"
                      stroke="#000"
                      stroke-width="0.5"
                    />
                  </g>
                </g>
                <g id="_icon_AI" transform="translate(241.26 230.645)">
                  <g
                    id="Rectangle_985-7"
                    className={classNames(styles.Rectangle)}
                    data-name="Rectangle_985"
                    transform="translate(0 0)"
                  >
                    <g id="Groupe_77" data-name="Groupe 77">
                      <path
                        id="Tracé_62"
                        data-name="Tracé 62"
                        d="M27.459,30.2H26.012L22.2,39.512h1.351l1.061-2.7h4.246l1.061,2.7h1.3ZM25,35.8l1.206-3.04a12.316,12.316,0,0,0,.482-1.4l.048-.193.193.531a4.935,4.935,0,0,0,.386,1.013L28.521,35.8Z"
                        transform="translate(-11.633 -17.848)"
                        fill="none"
                      />
                      <rect
                        id="Rectangle_285"
                        data-name="Rectangle 285"
                        width="1.254"
                        height="9.312"
                        transform="translate(21.23 12.352)"
                        fill="none"
                      />
                      <path
                        id="Tracé_63"
                        data-name="Tracé 63"
                        d="M34.075,4.6H1.265A.968.968,0,0,0,.3,5.565v32.81a.968.968,0,0,0,.965.965h32.81a.968.968,0,0,0,.965-.965V5.565A.968.968,0,0,0,34.075,4.6Zm-.482,33.292H1.747V6.047H33.592Z"
                        transform="translate(-0.3 -4.6)"
                        fill="none"
                      />
                    </g>
                    <g id="Groupe_78" data-name="Groupe 78">
                      <path
                        id="Tracé_64"
                        data-name="Tracé 64"
                        d="M34.075,4.6H1.265A.968.968,0,0,0,.3,5.565v32.81a.968.968,0,0,0,.965.965h32.81a.968.968,0,0,0,.965-.965V5.565A.968.968,0,0,0,34.075,4.6Zm-.482,33.292H1.747V6.047H33.592Z"
                        transform="translate(-0.3 -4.6)"
                        fill="#417c9d"
                      />
                    </g>
                  </g>
                  <g
                    id="Groupe_79"
                    data-name="Groupe 79"
                    transform="translate(10.567 12.352)"
                  >
                    <path
                      id="Tracé_65"
                      data-name="Tracé 65"
                      d="M27.459,30.2H26.012L22.2,39.512h1.351l1.061-2.7h4.246l1.061,2.7h1.3ZM25,35.8l1.206-3.04a12.316,12.316,0,0,0,.482-1.4l.048-.193.193.531a4.935,4.935,0,0,0,.386,1.013L28.521,35.8Z"
                      transform="translate(-22.2 -30.2)"
                    />
                    <path
                      id="Tracé_66"
                      data-name="Tracé 66"
                      d="M44.3,30.2v9.312h1.254V30.2Z"
                      transform="translate(-33.637 -30.2)"
                    />
                  </g>
                  <g
                    id="Groupe_80"
                    data-name="Groupe 80"
                    transform="translate(10.567 12.352)"
                  >
                    <path
                      id="Tracé_67"
                      data-name="Tracé 67"
                      d="M27.459,30.2H26.012L22.2,39.512h1.351l1.061-2.7h4.246l1.061,2.7h1.3ZM25,35.8l1.206-3.04a12.316,12.316,0,0,0,.482-1.4l.048-.193.193.531a4.935,4.935,0,0,0,.386,1.013L28.521,35.8Z"
                      transform="translate(-22.2 -30.2)"
                      fill="none"
                      stroke="#000"
                      stroke-width="0.5"
                    />
                    <path
                      id="Tracé_68"
                      data-name="Tracé 68"
                      d="M44.3,30.2v9.312h1.254V30.2Z"
                      transform="translate(-33.637 -30.2)"
                      fill="none"
                      stroke="#000"
                      stroke-width="0.5"
                    />
                  </g>
                </g>
                <g id="_icon_intuitif" transform="translate(0 230.645)">
                  <g id="like" transform="translate(9.351 4.393)">
                    <g id="Tracé_1336" transform="translate(0 0)">
                      <path
                        id="Tracé_92"
                        data-name="Tracé 92"
                        d="M18.638,22.129h0a2.939,2.939,0,0,0,.772-2.075,3.221,3.221,0,0,0-.676-1.737l-.048-.048.048-.048a2.669,2.669,0,0,0-.675-3.136c-.868-.772-2.316-1.061-4.391-.965a16.546,16.546,0,0,0-2.7.338,5.61,5.61,0,0,0-.868.193l-.145.048v-.145a9.769,9.769,0,0,1,.724-3.136c.675-2.316.627-4-.193-5.114A3.575,3.575,0,0,0,7.734,5a1.215,1.215,0,0,0-.965.434,3.517,3.517,0,0,0-.434,2.412v.1c-.1.241-2.461,6.272-4.584,7.816a.047.047,0,0,0-.048.048,5.2,5.2,0,0,0-.868,1.158H.641A2.352,2.352,0,0,0,.5,17.3c-.048,0-.1.193-.1.627H.5c-.1,2.026,0,8.685.193,10.9H.545c.048.531.1.868.145.82L1.8,29.8h.338v-.1a61.432,61.432,0,0,0,7.286.386c.675.048,1.3.1,1.882.1a11.218,11.218,0,0,0,2.557-.241,4.777,4.777,0,0,0,3.377-1.978,3.128,3.128,0,0,0,.386-1.978V25.94l.048-.048a3.159,3.159,0,0,0,1.206-2.654,2.407,2.407,0,0,0-.241-1.013Zm-1.206-.483a.585.585,0,0,0-.1.724,3.2,3.2,0,0,1,.289.965,2.21,2.21,0,0,1-1.11,1.93.6.6,0,0,0-.193.675,1.887,1.887,0,0,1-.145,1.447,3.53,3.53,0,0,1-2.557,1.4,14.286,14.286,0,0,1-2.509.193c-.531,0-1.061-.048-1.592-.1H9.471A51.588,51.588,0,0,1,2.233,28.5H2.185l-.627-.1.048-.145a1.336,1.336,0,0,0,.048-.434V19.234a2.824,2.824,0,0,0-.1-.627v-.1a4.077,4.077,0,0,1,.627-1.3c0,.048.048.1.048.145h0v-.193a3.38,3.38,0,0,1,.386-.482C5.032,14.843,7.4,8.86,7.637,8.184a.612.612,0,0,0,.048-.289,2.989,2.989,0,0,1,.1-1.592v-.1h.1a2.133,2.133,0,0,1,1.64.772c.579.772.579,2.171-.048,3.956-.917,2.605-1.013,4.053-.29,4.632a1.14,1.14,0,0,0,1.11.193,9.235,9.235,0,0,1,1.013-.193h.048c1.785-.386,4.825-.579,5.935.386a1.545,1.545,0,0,1,.193,2.075.592.592,0,0,0,.1.772,2.027,2.027,0,0,1,.676,1.3A2.693,2.693,0,0,1,17.432,21.646Z"
                        transform="translate(-0.255 -5)"
                      />
                      <path
                        id="Tracé_93"
                        data-name="Tracé 93"
                        d="M1.933,30.089c0,.048.048.1.048.145v-.193C1.982,29.993,1.933,30.041,1.933,30.089Zm0,0c0,.048.048.1.048.145v-.193C1.982,29.993,1.933,30.041,1.933,30.089Zm0,0c0,.048.048.1.048.145v-.193C1.982,29.993,1.933,30.041,1.933,30.089Zm0,0c0,.048.048.1.048.145v-.193C1.982,29.993,1.933,30.041,1.933,30.089Zm0,0c0,.048.048.1.048.145v-.193C1.982,29.993,1.933,30.041,1.933,30.089ZM.341,29.8a.366.366,0,0,0-.1.193L.2,30.041a1.554,1.554,0,0,0-.1.724H.341c0-.241.048-.386.048-.482h0l.048-.1c.048-.145.145-.241.193-.386ZM1.885,42.538h0L.631,42.393a1.79,1.79,0,0,1-.1-.676H.293a3.155,3.155,0,0,0,.145.869l.1.1.048-.048.338.048H1.982v-.1C1.933,42.538,1.933,42.538,1.885,42.538Zm.048-12.448c0,.048.048.1.048.145v-.193C1.982,29.993,1.933,30.041,1.933,30.089Zm0,0c0,.048.048.1.048.145v-.193C1.982,29.993,1.933,30.041,1.933,30.089Zm0,0c0,.048.048.1.048.145v-.193C1.982,29.993,1.933,30.041,1.933,30.089Zm0,0c0,.048.048.1.048.145v-.193C1.982,29.993,1.933,30.041,1.933,30.089Z"
                        transform="translate(-0.1 -17.834)"
                      />
                    </g>
                  </g>
                  <g id="Rectangle_993" transform="translate(5.081 16.37)">
                    <g
                      id="Groupe_93"
                      data-name="Groupe 93"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Tracé_94"
                        data-name="Tracé 94"
                        d="M5.7,16.283l.048-.145A1.336,1.336,0,0,0,5.8,15.7V7.068a2.824,2.824,0,0,0-.1-.627v-.1a4.077,4.077,0,0,1,.627-1.3A.507.507,0,0,0,5.893,4.8H.682A.484.484,0,0,0,.2,5.282V17.152a.519.519,0,0,0,.482.482H5.893a.365.365,0,0,0,.193-.048c.048,0,.1-.048.145-.1a.438.438,0,0,0,.145-.338v-.82Zm-4.535.434V5.765H4.687c-.1,2.026,0,8.685.193,10.9l-3.715.048Z"
                        transform="translate(-0.2 -4.8)"
                        fill="none"
                      />
                    </g>
                    <g
                      id="Groupe_94"
                      data-name="Groupe 94"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Tracé_95"
                        data-name="Tracé 95"
                        d="M.2,4.8V17.634H6.376v-1.3l-.627-.1L5.8,16.09a1.336,1.336,0,0,0,.048-.434V7.068a2.824,2.824,0,0,0-.1-.627v-.1a4.077,4.077,0,0,1,.627-1.3c0,.048.048.1.048.145V4.8Zm.965,11.918V5.765H5.411v10.9l-4.246.048Z"
                        transform="translate(-0.2 -4.8)"
                      />
                    </g>
                  </g>
                  <g
                    id="Groupe_635"
                    className={classNames(styles.Rectangle)}
                    transform="translate(0 0)"
                  >
                    <g
                      id="Rectangle_985-8"
                      className={classNames(styles.Rectangle)}
                      data-name="Rectangle_985"
                    >
                      <g id="Groupe_95" data-name="Groupe 95">
                        <path
                          id="Tracé_96"
                          data-name="Tracé 96"
                          d="M33.533,31.284h0a2.939,2.939,0,0,0,.772-2.075,3.221,3.221,0,0,0-.675-1.737l-.048-.048.048-.048a2.669,2.669,0,0,0-.675-3.136c-.868-.772-2.316-1.061-4.391-.965a16.546,16.546,0,0,0-2.7.338,5.61,5.61,0,0,0-.868.193l-.145.048v-.145a9.769,9.769,0,0,1,.724-3.136c.772-2.219.724-3.908-.145-5.018a3.53,3.53,0,0,0-2.75-1.254,1.215,1.215,0,0,0-.965.434,3.517,3.517,0,0,0-.434,2.412v.1c-.1.241-2.461,6.272-4.584,7.816a.047.047,0,0,0-.048.048,5.194,5.194,0,0,0-.868,1.158H11V39.1h6.176V39a61.431,61.431,0,0,0,7.286.386c.675.048,1.3.1,1.882.1a11.218,11.218,0,0,0,2.557-.241,4.777,4.777,0,0,0,3.377-1.978,3.128,3.128,0,0,0,.386-1.978V35.24l.048-.048a3.159,3.159,0,0,0,1.206-2.654,2.408,2.408,0,0,0-.241-1.013ZM11.868,38.039V27.086h3.522c-.1,2.026,0,8.685.193,10.9l-3.715.048ZM32.326,30.8a.585.585,0,0,0-.1.724,3.2,3.2,0,0,1,.289.965,2.21,2.21,0,0,1-1.11,1.93.6.6,0,0,0-.193.675,1.887,1.887,0,0,1-.145,1.447,3.53,3.53,0,0,1-2.557,1.4,14.285,14.285,0,0,1-2.509.193c-.531,0-1.061-.048-1.592-.1h-.048a51.588,51.588,0,0,1-7.237-.386h-.048l-.627-.1.048-.145a1.336,1.336,0,0,0,.048-.434V28.389a2.824,2.824,0,0,0-.1-.627v-.1a4.076,4.076,0,0,1,.627-1.3c0,.048.048.1.048.145v-.193a3.381,3.381,0,0,1,.386-.483C19.926,24,22.29,18.015,22.532,17.34a.612.612,0,0,0,.048-.29,2.989,2.989,0,0,1,.1-1.592v-.1h.1a2.133,2.133,0,0,1,1.64.772c.579.772.579,2.171-.048,3.956-.917,2.605-1.013,4.053-.289,4.632a1.14,1.14,0,0,0,1.11.193,9.234,9.234,0,0,1,1.013-.193h.048c1.785-.386,4.825-.579,5.935.386a1.545,1.545,0,0,1,.193,2.075.592.592,0,0,0,.1.772,2.027,2.027,0,0,1,.675,1.3A3.007,3.007,0,0,1,32.326,30.8Z"
                          transform="translate(-5.789 -9.765)"
                          fill="none"
                        />
                        <path
                          id="Tracé_97"
                          data-name="Tracé 97"
                          d="M34.65,5.141c-.048-.048-.1-.1-.145-.1a.973.973,0,0,0-.531-.145H1.165a.973.973,0,0,0-.531.145c-.048,0-.1.048-.145.1A1.212,1.212,0,0,0,.2,5.817v32.81a.968.968,0,0,0,.965.965h32.81a.968.968,0,0,0,.965-.965V5.817A.953.953,0,0,0,34.65,5.141Zm-1.158,33H1.647V6.3H33.492Z"
                          transform="translate(-0.2 -4.9)"
                          fill="none"
                        />
                      </g>
                      <g id="Groupe_96" data-name="Groupe 96">
                        <path
                          id="Tracé_98"
                          data-name="Tracé 98"
                          d="M34.65,5.141c-.048-.048-.1-.1-.145-.1a.973.973,0,0,0-.531-.145H1.165a.973.973,0,0,0-.531.145c-.048,0-.1.048-.145.1A1.212,1.212,0,0,0,.2,5.817v32.81a.968.968,0,0,0,.965.965h32.81a.968.968,0,0,0,.965-.965V5.817A.953.953,0,0,0,34.65,5.141Zm-1.158,33H1.647V6.3H33.492Z"
                          transform="translate(-0.2 -4.9)"
                          fill="#417c9d"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.padding,
            styles.ht2,
            styles.float_left,
            styles.ht2,

            "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          )}
        >
          <div className={classNames(styles.tile, styles.textcolor)}>
            <div className={classNames(styles.light)}></div>

            <div className={classNames(styles.topbar, "col-12")}>
              <div className={classNames(styles.text_icon)}>
                <svg
                  id="Groupe_582"
                  xmlns="http://www.w3.org/2000/svg"
                  width="26.791"
                  height="29.977"
                  viewBox="0 0 26.791 29.977"
                >
                  <g id="noun_ar_2300882" transform="translate(0)">
                    <g id="Groupe_580">
                      <g id="Tracé_1308">
                        <path
                          id="Tracé_60"
                          data-name="Tracé 60"
                          d="M18.98,26.889a.727.727,0,0,0-.932-.335l-3.763,1.863V23.983a.708.708,0,0,0-1.416,0v4.434l-3.763-1.9a.877.877,0,0,0-.3-.075.689.689,0,0,0-.633.41.855.855,0,0,0-.037.522.677.677,0,0,0,.373.41l4.732,2.385a.713.713,0,0,0,.671,0l4.732-2.385A.7.7,0,0,0,18.98,26.889Zm-.3-24.146L13.912.359a.712.712,0,0,0-.671,0L8.509,2.781a.692.692,0,1,0,.633,1.23l3.763-1.863V6.582a.708.708,0,1,0,1.416,0V2.11l3.763,1.863a.724.724,0,0,0,.932-.3A.782.782,0,0,0,18.682,2.744ZM6.348,5.166a.819.819,0,0,0-.037-.522.721.721,0,0,0-.633-.373,1.088,1.088,0,0,0-.335.075L.61,6.693a.689.689,0,0,0-.41.633v5.552a.708.708,0,0,0,1.416,0v-4.4l3.763,1.863a.724.724,0,0,0,.932-.3.68.68,0,0,0-.3-.932L2.51,7.364,6.05,5.575A.7.7,0,0,0,6.348,5.166Zm0,20.159a.615.615,0,0,0-.373-.373L2.473,23.2l3.54-1.789A.624.624,0,0,0,6.348,21a.819.819,0,0,0-.037-.522.69.69,0,0,0-.932-.3l-3.763,1.9V17.648a.708.708,0,0,0-1.416,0V23.2a.634.634,0,0,0,.037.261V23.5h0a.529.529,0,0,0,.335.3l4.769,2.422c.037,0,.037.037.075.037a.709.709,0,0,0,.932-.932Zm20.643-7.676a.708.708,0,0,0-1.416,0v4.434l-3.763-1.863a.877.877,0,0,0-.3-.075.768.768,0,0,0-.633.373.69.69,0,0,0,.3.932l3.5,1.751-3.5,1.751a.738.738,0,0,0-.261.932.727.727,0,0,0,.932.335l4.732-2.385a.689.689,0,0,0,.41-.633ZM26.954,7.1a.036.036,0,0,0-.037-.037.731.731,0,0,0-.335-.335L21.812,4.346a.724.724,0,0,0-.932.3.68.68,0,0,0,.3.932l3.5,1.751-3.5,1.751a.712.712,0,0,0-.3.932.727.727,0,0,0,.932.335l3.763-1.863v4.434a.707.707,0,0,0,.708.708h0a.708.708,0,0,0,.484-.186.656.656,0,0,0,.224-.484V7.364C26.991,7.252,26.954,7.178,26.954,7.1Z"
                          transform="translate(-0.2 -0.275)"
                          fill="#417c9d"
                        />
                      </g>
                    </g>
                  </g>
                  <g
                    id="noun_ar_2300882-2"
                    className={classNames(styles.invert)}
                    transform="translate(7.976 10.349)"
                  >
                    <g id="Groupe_580-2" transform="translate(0 0)">
                      <g id="Tracé_1308-2">
                        <path
                          id="Tracé_61"
                          data-name="Tracé 61"
                          d="M11.159.294a.75.75,0,0,1-.335.41L6.5,2.865,6.464,2.9V7.225a.553.553,0,0,1-.037.261c-.037.075-.075.112-.112.186h0a.036.036,0,0,1-.037.037.723.723,0,0,1-.969,0l-.037-.037h0a.814.814,0,0,1-.112-.186.277.277,0,0,1-.112-.261V2.9L.688.7a.577.577,0,0,1-.3-.3C.39.369.353.332.353.257a.517.517,0,0,1,0-.335c0-.037,0-.075.037-.075C.39-.19.427-.19.427-.227A.721.721,0,0,1,1.061-.6a.877.877,0,0,1,.3.075L5.793,1.71,10.227-.525c.075-.037.149-.037.224-.075h.075a.473.473,0,0,1,.224.037.75.75,0,0,1,.41.335A.83.83,0,0,1,11.159.294Z"
                          transform="translate(-0.325 0.6)"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div className={classNames(styles.text_icon, styles.groupicon)}>
                <img
                  className={classNames(styles.invert)}
                  src={varnish}
                  alt="varnish"
                />
                <img
                  className={classNames(styles.invert)}
                  src={foil}
                  alt="foil"
                />
                <img
                  className={classNames(styles.invert)}
                  src={color}
                  alt="color"
                />
              </div>
            </div>
            <div
              className={classNames(styles.content, styles.notopbar, "col-12")}
            >
              <img
                className={classNames(styles.img3Delement)}
                src={element3D}
                alt="3D element"
              />
            </div>

            <div
              className={classNames(styles.botbar, styles.discover, "col-12")}
            >
              <div className={classNames(styles.zone2D3D)}>
                <div className={classNames(styles.cursor)}></div>
                <div className={classNames(styles.zone2D)}>2D</div>
                <div className={classNames(styles.zone3D)}>3D</div>
              </div>

              <div className={classNames(styles.buttonautresproj)}>
                <p>Voir d'autres projets</p>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.padding,
            styles.ht2,
            styles.float_left,
            styles.ht2sm,
            "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          )}
        >
          <div className={classNames(styles.tile, styles.textcolor)}>
            <div className={classNames(styles.topbar, "col-12")}>
              <p className={classNames(styles.approvecolor)}>{t("Strong")}</p>
              <div className={classNames(styles.text_icon)}>
                <img
                  className={classNames(styles.icon_PDF)}
                  src={icon_PDF}
                  alt=">"
                />
              </div>
            </div>
            <div className={classNames(styles.content, "col-12")}>
              <p>{t("Strong_content")}</p>
              <div className={classNames(styles.list, "col-12")}>
                <ul>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("Vernis")}</p>
                  </li>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("Dorure")}</p>
                  </li>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("Impression")}</p>
                  </li>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("Traits")}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.padding,
            styles.ht15,
            styles.float_left,
            styles.ht15sm,

            "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          )}
        >
          <div className={classNames(styles.tile, styles.textcolor)}>
            <div className={classNames(styles.light)}></div>

            <div className={classNames(styles.topbar, "col-12")}>
              <p className={classNames(styles.approvecolor)}>{t("Intuitif")}</p>
              <div className={classNames(styles.text_icon)}>
                <img
                  className={classNames(styles.icon_intuitif)}
                  src={icon_intuitif}
                  alt="Top"
                />
              </div>
            </div>
            <div className={classNames(styles.content, "col-12")}>
              <p>{t("Intuitif_content")}</p>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.padding,
            styles.ht15,
            styles.float_left,
            styles.ht15sm,

            "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          )}
        >
          <div
            className={classNames(
              styles.tile,
              styles.textcolor,
              styles.imgbackgroundblue
            )}
          >
            <div className={classNames(styles.light)}></div>

            <div
              className={classNames(styles.content, styles.notopbar, "col-12")}
            >
              <img
                className={classNames(styles.fullscreenimg)}
                src={screenapprove}
                alt="Screen approve"
              />
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.padding,
            styles.ht2,
            styles.float_left,
            styles.ht2sm,

            "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          )}
        >
          <div
            className={classNames(
              styles.tile,
              styles.textcolor,
              styles.imgbackgroundwhite
            )}
          >
            <div className={classNames(styles.light)}></div>

            <div
              className={classNames(styles.content, styles.notopbar, "col-12")}
            >
              <img
                className={classNames(styles.fullscreenimg)}
                src={screenapprovemobile}
                alt="Screen approve mobile"
              />
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.padding,
            styles.ht2,
            styles.float_left,
            styles.ht2sm,
            "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          )}
        >
          <div className={classNames(styles.tile, styles.textcolor)}>
            <div className={classNames(styles.topbar, "col-12")}>
              <p className={classNames(styles.approvecolor)}>
                {t("Accessible")}
              </p>
              <div className={classNames(styles.text_icon)}>
                <img
                  className={classNames(styles.icon_PDF)}
                  src={icon_PDF}
                  alt=">"
                />
              </div>
            </div>
            <div className={classNames(styles.content, "col-12")}>
              <p>{t("Accessible_content")}</p>
              <div className={classNames(styles.list, "col-12")}>
                <ul>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("fabricationcosts")}</p>
                  </li>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("equipmentimmo")}</p>
                  </li>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("expeditioncosts")}</p>
                  </li>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("aprobationdelai")}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.padding,
            styles.ht2,
            styles.float_left,
            styles.ht2sm,
            "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          )}
        >
          <div className={classNames(styles.tile, styles.textcolor)}>
            <div className={classNames(styles.topbar, "col-12")}>
              <p className={classNames(styles.approvecolor)}>
                {t("Marketing_digital")}
              </p>
              <div className={classNames(styles.text_icon)}>
                <img
                  className={classNames(styles.icon_PDF)}
                  src={icon_PDF}
                  alt=">"
                />
              </div>
            </div>
            <div className={classNames(styles.content, "col-12")}>
              <p>{t("Marketing_content")}</p>
              <div className={classNames(styles.list, "col-12")}>
                <ul>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("Créer")}</p>
                  </li>

                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("Mesurer")}</p>
                  </li>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("Cibler")}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.padding,
            styles.ht2,
            styles.float_left,
            styles.ht2sm,

            "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          )}
        >
          <div
            className={classNames(
              styles.tile,
              styles.textcolor,
              styles.imgbackgroundwhite
            )}
          >
            <div className={classNames(styles.light)}></div>

            <div
              className={classNames(styles.content, styles.notopbar, "col-12")}
            >
              <img
                className={classNames(styles.fullscreenimg)}
                src={fusee}
                alt="Fusée"
              />
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.padding,
            styles.ht15,
            styles.float_left,
            styles.ht15sm,

            "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          )}
        >
          <div
            className={classNames(
              styles.tile,
              styles.textcolor,
              styles.imgbackgroundwhite
            )}
          >
            <div className={classNames(styles.light)}></div>

            <div
              className={classNames(styles.content, styles.notopbar, "col-12")}
            >
              <img
                className={classNames(styles.fullscreenimg)}
                src={dassault}
                alt="Dassault"
              />
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.padding,
            styles.ht15,
            styles.float_left,
            styles.ht15sm,

            "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          )}
        >
          <div className={classNames(styles.tile, styles.textcolor)}>
            <div className={classNames(styles.light)}></div>

            <div className={classNames(styles.topbar, "col-12")}>
              <p className={classNames(styles.approvecolor)}>{t("Sécurisé")}</p>
              <div className={classNames(styles.text_icon)}>
                <img
                  className={classNames(styles.icon_intuitif)}
                  src={icon_intuitif}
                  alt="Top"
                />
              </div>
            </div>
            <div className={classNames(styles.content, "col-12")}>
              <p>{t("Sécurisé_content")}</p>
            </div>
          </div>
        </div>

        {/* FIN Vrai landing Approve */}
      </div>
    </>
  );
};
export function chartcolors(value: number) {
  if (value > 0) {
    if (value < 50) {
      return "rgb(252, 62, 114)";
    } else if (value > 50 && value < 70) {
      return "rgb(252, 167, 62)";
    } else {
      return "rgb(13, 222, 126)";
    }
  }
}

export default ApproveProduct;
