import { Auth, UserProfile } from "@mgi-labs/mgi-id";
import { Navigate } from "react-router-dom";
import { hasInvitationKmAccess } from "./Domain/hasKmAccess";

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  isKmPage: boolean;
  loginPath: string;
  outlet: JSX.Element;
  user: UserProfile | undefined;
  auth: Auth;
};

export default function ProtectedRoute({
  isAuthenticated,
  loginPath,
  isKmPage,
  outlet,
  user,
  auth,
}: ProtectedRouteProps) {
  if (isAuthenticated) {
    if (isKmPage) {
      if (hasInvitationKmAccess(user?.producer_team_uuid)) {
        return outlet;
      } else return <Navigate to={{ pathname: loginPath }} />;
    } else return outlet;
  } else {
    auth.login();

    return <Navigate to={{ pathname: loginPath }} />;
  }
}
