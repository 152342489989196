import { useEffect, useCallback } from "react";

const useOutsideClick = (
  ref: React.RefObject<any>,
  callback: (event: Event) => void
) => {
  const handleAddMessage = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    },
    [callback, ref]
  );

  useEffect(() => {
    document.addEventListener("click", handleAddMessage);
    //document.onclick(handleAddMessage(event));

    return () => {
      document.removeEventListener("click", handleAddMessage);
    };
  });
};

export default useOutsideClick;
