import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles/newlog.module.scss";
import { ThemeType } from "@mgi-labs/mgi-id";
import {
  Column,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { KMUserDto } from "../hooks/UseKmUsers";
import { useBackend } from "../backend/BackendProvider";
import { mutate } from "swr";
import { AccountStats } from "../types";

type KmAccountTableType = KMUserDto & AccountStats;

const KmTabAccounts = ({
  theme,
  users,
}: {
  theme: ThemeType;
  users: KMUserDto[];
}) => {
  const { t, i18n } = useTranslation();
  const backend = useBackend();
  const handleDisableUser = useCallback(
    async (id: string) => {
      try {
        await backend.connexion.disableUser(id);
      } finally {
        mutate("kmUsers");
      }
    },
    [backend.connexion]
  );

  const [stats, setStats] = useState<AccountStats[]>([]);
  useEffect(() => {
    async function fetchStats() {
      const stats = await backend.connexion.getKMStats(
        users.map((u) => u.group)
      );
      setStats(stats);
    }
    fetchStats();
  }, [backend.connexion, users]);

  const newData = useMemo(() => {
    const array: KmAccountTableType[] = [];
    users.forEach((u) => {
      const userStats = stats.find((s) => s.uuid === u.group);
      if (userStats) {
        const finalUser = { ...u, ...userStats };
        array.push(finalUser);
      }
    });
    return array;
  }, [stats, users]);

  const headerProject = useMemo(
    () => (
      <div data-title={t("nbprojcreate")} className={classNames(styles.nbzone)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="14"
          viewBox="0 0 18 14"
        >
          <path
            id="Tracé_1475"
            data-name="Tracé 1475"
            d="M3,15H5V13H3Zm0,4H5V17H3Zm0-8H5V9H3Zm4,4H21V13H7Zm0,4H21V17H7ZM7,9v2H21V9ZM3,7H5V5H3ZM7,5V7H21V5Z"
            transform="translate(-3 -5)"
            fill="#fff"
          />
        </svg>
      </div>
    ),
    [t]
  );

  const headerCatalog = useMemo(
    () => (
      <div data-title={t("nbcatcreate")} className={classNames(styles.nbzone)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="books"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            id="Tracé_2"
            data-name="Tracé 2"
            d="M19.955,2H6.279A2.286,2.286,0,0,0,4,4.279V22.514a2.286,2.286,0,0,0,2.279,2.279H19.955a2.286,2.286,0,0,0,2.279-2.279V4.279A2.286,2.286,0,0,0,19.955,2ZM9.7,4.279h2.279v5.7l-1.14-.855L9.7,9.978ZM19.955,22.514H6.279V4.279h1.14V14.536l3.419-2.564,3.419,2.564V4.279h5.7Z"
            transform="translate(-1.117 -1.397)"
            fill="#fff"
          />
        </svg>
      </div>
    ),
    [t]
  );

  const columns: Column<KmAccountTableType>[] = useMemo(
    () => [
      {
        Header: t<string>("KMAccountsTable.createdAt"),
        accessor: "createdAt",
        style: classNames(styles.borderright),
      },
      {
        Header: t<string>("KMAccountsTable.company"),
        accessor: "company",
        style: classNames(styles.borderright),
      },
      {
        Header: t<string>("KMAccountsTable.name"),
        accessor: "fullName",
        style: classNames(styles.borderright),
      },
      {
        Header: t<string>("KMAccountsTable.email"),
        accessor: "email",
        style: classNames(styles.borderright),
      },
      {
        Header: headerProject,
        accessor: "projects",
        style: classNames(styles.borderright),
      },
      {
        Header: t<string>("KMAccountsTable.lastProject"),
        accessor: "lastProjet",
        style: classNames(styles.borderright),
      },
      {
        Header: headerCatalog,
        accessor: "catalog",
        style: classNames(styles.borderright),
      },
      {
        Header: t<string>("KMAccountsTable.lastCatalog"),
        accessor: "lastCatalog",
        style: classNames(styles.borderright),
      },
      {
        Header: t<string>("KMAccountsTable.lastLogin"),
        accessor: (user: KMUserDto) => user.session.lastLogin,
        style: classNames(styles.borderright),
      },
    ],
    [headerCatalog, headerProject, t]
  );

  const { getTableProps, getTableBodyProps, prepareRow, headerGroups, rows } =
    useTable(
      {
        columns,
        data: newData,
      },
      useGlobalFilter,
      useFilters,
      useSortBy,
      useRowSelect
    );

  return (
    <div data-theme={theme} className={classNames(styles.container)}>
      <div className={classNames(styles.table_container)}>
        <div className={classNames(styles.title)}>
          <div>
            <p>{t("Tabkm")}</p>
          </div>
          <div className={classNames(styles.search)}></div>
        </div>
        <div className={classNames(styles.filter_container)}></div>
        <div className={classNames(styles.table)}>
          <table
            className={classNames(styles.Allproject_project_section)}
            {...getTableProps()}
          >
            <thead
              className={classNames(
                styles.Allproject_projects_section_sort,
                "col-12"
              )}
            >
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className={classNames(styles.Allproject_padding)}
                >
                  {headerGroup.headers.map((column, i) => (
                    <th
                      className={classNames(
                        styles.Allproject_projects_sort,
                        i === 0
                          ? styles.col3
                          : i === 3
                          ? styles.col5
                          : i === 4 || i === 6
                          ? styles.col1
                          : styles.col4,
                        i === 4 ? styles.overflowvisible : "",
                        i === 6 ? styles.overflowvisible : "",
                        i === 1 ? styles.overflowvisible : ""
                      )}
                      style={{ display: "flex", position: "relative" }}
                    >
                      <p>{column.render("Header")}</p>

                      <div className={classNames(styles.borderright)}></div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className={classNames(
                styles.Allproject_projects_content,
                "col-12 row"
              )}
            >
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <React.Fragment key={row.original.id}>
                    <tr
                      {...row.getRowProps()}
                      className={classNames(
                        "col-12",
                        !row.original.enabled && styles.desactivated
                      )}
                    >
                      <td>
                        <div className={classNames(styles.client, styles.col3)}>
                          <div className={classNames(styles.borderright)}></div>
                          <div className={classNames(styles.client_name)}>
                            <p>
                              {new Date(row.cells[0].value).toLocaleDateString(
                                i18n.language,
                                {
                                  formatMatcher: "best fit",
                                  year: "2-digit",
                                  month: "numeric",
                                  day: "numeric",
                                }
                              )}
                            </p>
                          </div>
                        </div>
                        <div className={classNames(styles.client, styles.col4)}>
                          <div className={classNames(styles.borderright)}></div>
                          <div className={classNames(styles.client_name)}>
                            <p>{row.cells[1].value}</p>
                          </div>
                        </div>
                        <div className={classNames(styles.client, styles.col4)}>
                          <div className={classNames(styles.borderright)}></div>
                          <div className={classNames(styles.nom)}>
                            <p>{row.cells[2].value}</p>
                          </div>
                        </div>
                        <div className={classNames(styles.log, styles.col5)}>
                          <div className={classNames(styles.borderright)}></div>
                          <div className={classNames(styles.message)}>
                            <p>{row.cells[3].value}</p>
                          </div>
                        </div>
                        <div className={classNames(styles.log, styles.col1)}>
                          <div className={classNames(styles.borderright)}></div>
                          <div className={classNames(styles.message)}>
                            <p>{row.cells[4].value}</p>
                          </div>
                        </div>
                        <div className={classNames(styles.log, styles.col4)}>
                          <div className={classNames(styles.borderright)}></div>
                          <div className={classNames(styles.message)}>
                            <p>
                              {row.cells[5].value
                                ? new Date(
                                    row.cells[5].value
                                  ).toLocaleDateString(i18n.language, {
                                    formatMatcher: "best fit",
                                    year: "2-digit",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    second: "numeric",
                                  })
                                : "-"}
                            </p>
                          </div>
                        </div>
                        <div className={classNames(styles.log, styles.col1)}>
                          <div className={classNames(styles.borderright)}></div>
                          <div className={classNames(styles.message)}>
                            <p>{row.cells[6].value}</p>
                          </div>
                        </div>
                        <div className={classNames(styles.log, styles.col4)}>
                          <div className={classNames(styles.borderright)}></div>
                          <div className={classNames(styles.message)}>
                            <p>
                              {row.cells[7].value
                                ? new Date(
                                    row.cells[7].value
                                  ).toLocaleDateString(i18n.language, {
                                    formatMatcher: "best fit",
                                    year: "2-digit",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    second: "numeric",
                                  })
                                : "-"}
                            </p>
                          </div>
                        </div>
                        <div className={classNames(styles.log, styles.col4)}>
                          <div className={classNames(styles.borderright)}></div>
                          <div
                            className={classNames(
                              styles.message,
                              styles.colorjaune
                            )}
                          >
                            <p>
                              {row.cells[8].value === 0
                                ? t("KMAccountsTable.never")
                                : new Date(
                                    row.cells[8].value
                                  ).toLocaleDateString(i18n.language, {
                                    formatMatcher: "best fit",
                                    year: "2-digit",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    second: "numeric",
                                  })}
                            </p>
                          </div>
                        </div>
                      </td>
                      <div
                        data-title={
                          row.original.enabled
                            ? t("desactivateuser")
                            : t("activateuser")
                        }
                        className={classNames(styles.desactivate)}
                        onClick={() => handleDisableUser(row.original.id)}
                      >
                        {row.original.enabled ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="39.75"
                            height="39.75"
                            viewBox="0 0 39.75 39.75"
                          >
                            <path
                              id="deletuser"
                              d="M40.65,44.95,35.7,40H8V35.3a5.5,5.5,0,0,1,.95-3.25A6.1,6.1,0,0,1,11.4,30a38.786,38.786,0,0,1,5.775-2.1,24.682,24.682,0,0,1,5.575-.85L3.05,7.35,5.2,5.2,42.8,42.8ZM11,37H32.7l-6.95-6.95A6.861,6.861,0,0,0,24.9,30H24a26.573,26.573,0,0,0-5.55.575A25.4,25.4,0,0,0,12.6,32.7,3.008,3.008,0,0,0,11,35.3Zm25.55-7a6.028,6.028,0,0,1,2.5,2.05A5.5,5.5,0,0,1,40,35.3v.4l-7.15-7.15q.9.3,1.825.675T36.55,30ZM27.5,23.2l-2.4-2.4a4.278,4.278,0,0,0,2.45-1.55,4.379,4.379,0,0,0,.95-2.8,4.526,4.526,0,0,0-7.3-3.55,4.278,4.278,0,0,0-1.55,2.45l-2.4-2.4A6.877,6.877,0,0,1,20.025,10,7.833,7.833,0,0,1,24,8.95a7.427,7.427,0,0,1,7.5,7.5,7.833,7.833,0,0,1-1.05,3.975A6.877,6.877,0,0,1,27.5,23.2ZM32.7,37h0ZM22.4,18.05Z"
                              transform="translate(-3.05 -5.2)"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            viewBox="0 0 36 36"
                          >
                            <path
                              id="unarchive"
                              d="M9,42a2.878,2.878,0,0,1-2.1-.9A2.878,2.878,0,0,1,6,39V12.85a4.694,4.694,0,0,1,.15-1.275A2.491,2.491,0,0,1,6.7,10.6L9.5,6.8a2.128,2.128,0,0,1,.925-.625A3.878,3.878,0,0,1,11.65,6h24.7a3.647,3.647,0,0,1,1.2.175,2.155,2.155,0,0,1,.9.625l2.85,3.8a2.491,2.491,0,0,1,.55.975A4.694,4.694,0,0,1,42,12.85V39a3.076,3.076,0,0,1-3,3Zm.85-30.7H38.1L36.3,9H11.65ZM9,14.3V39H39V14.3ZM22.5,34.5h3V24.45l4.3,4.3,2-2L24,18.95l-7.8,7.8,2,2,4.3-4.3ZM9,39H9Z"
                              transform="translate(-6 -6)"
                            />
                          </svg>
                        )}
                      </div>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export function eventsColors(value: string | undefined) {
  if (value === "ERROR") {
    return "#EFAA53";
  } else if (value === "WARNING") {
    return "#EFAA53";
  } else if (value === "EMERGENCY STOP") {
    return "#E8615B";
  } else if (value === "STOP") {
    return "#E8615B";
  } else {
    return "#7C8FF8";
  }
}
export default KmTabAccounts;
