import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import styles from "./styles/monprofil.module.scss";
import { useTranslation } from "react-i18next";
import { useAuth, useUser } from "@mgi-labs/mgi-id";
import { ThemeType } from "./MgiConnectRouter";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useBackend } from "../backend/BackendProvider";
import { formCheckError } from "./Domain/createAccountDef";
import copy from "../images/icon/copy.svg";
import { isharrisbruno } from "./Domain/checkharrisbruno";

const Monprofil = ({
  theme,
  handleUpdateUserTheme,
}: {
  theme: ThemeType;
  handleUpdateUserTheme: (theme: ThemeType) => Promise<void>;
}) => {
  const { t } = useTranslation();

  const profile = useUser();
  const initials =
    typeof profile === "object"
      ? `${profile?.firstName?.charAt(0)}${profile?.lastName?.charAt(0)}`
      : undefined;

  const { auth } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash.includes("#changedPwd")) toast.success(t("changed_pwd"));
    navigate("/profil");
  }, [navigate, location.hash, t]);

  const [isClickable, setIsClickable] = useState<boolean>(true);
  const handleResetPassword = useCallback(
    (event: SyntheticEvent | KeyboardEvent) => {
      event.preventDefault();
      setIsClickable(false);
      if (isharrisbruno()) {
        auth.login({
          action: "UPDATE_PASSWORD",
          redirectUri: window.location.href + "#changedPwd",
        });
      } else {
        window
          .open(
            `https://${
              isharrisbruno()
                ? "id.harris-bruno-connect.com"
                : process.env.REACT_APP_IS_STAGING
                ? "id-staging.mgi-labs.com"
                : "id.mgi-labs.com"
            }/auth/realms/mgi-connect/protocol/openid-connect/auth?client_id=${
              process.env.REACT_APP_KEYCLOAK_CLIENT_ID
            }&redirect_uri=${
              isharrisbruno()
                ? process.env.REACT_APP_CONNECT_URL_HB
                : process.env.REACT_APP_CONNECT_URL
            }&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`,
            "_blank"
          )
          ?.focus();
      }
    },
    [auth]
  );

  const backend = useBackend();

  const [firstname, setFirstname] = useState<string | undefined>(
    profile?.firstName
  );
  const [lastname, setLastname] = useState<string | undefined>(
    profile?.lastName
  );
  const [company, setCompany] = useState<string | undefined>(
    profile?.defaultTeam?.realName
  );
  const [country, setCountry] = useState<string | undefined>(
    profile?.userInfos.country
  );
  const [phone, setPhone] = useState<string | undefined>(
    profile?.userInfos.phone
  );
  const [mobile, setMobile] = useState<string | undefined>(
    profile?.userInfos.mobile
  );

  const [address, setAddress] = useState<string | undefined>(
    //@ts-ignore
    profile?.userInfos.address
    //profile.client_address ? profile.client_address : profile?.userInfos.address
  );
  const [zipcode, setZipcode] = useState<string | undefined>(
    profile?.userInfos.zipcode
  );

  const handleCopyLink = useCallback(() => {
    toast.success(t<string>("copied"));
    navigator.clipboard.writeText(profile?.defaultTeam?.id ?? "");
  }, [profile?.defaultTeam?.id, t]);

  useEffect(() => {
    setFirstname(profile?.firstName);
    setLastname(profile?.lastName);
    setCountry(profile?.userInfos.country);
    setCompany(profile?.defaultTeam?.realName);
    setPhone(profile?.userInfos.phone);
    setMobile(profile?.userInfos.mobile);
    //@ts-ignore
    setAddress(profile?.userInfos.address);
    setZipcode(profile?.userInfos.zipcode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    //@ts-ignore
    profile?.company,
    profile?.defaultTeam?.realName,
    profile?.firstName,
    profile?.lastName,
    profile?.userInfos.address,
    profile?.userInfos.country,
    profile?.userInfos.mobile,
    profile?.userInfos.phone,
    profile?.userInfos.zipcode,
  ]);

  const lastNameRef = useRef<HTMLInputElement>(null!);
  const firstNameRef = useRef<HTMLInputElement>(null!);
  const companyRef = useRef<HTMLInputElement>(null!);
  const addressRef = useRef<HTMLInputElement>(null!);
  const countryRef = useRef<HTMLInputElement>(null!);
  const zipcodeRef = useRef<HTMLInputElement>(null!);
  const phoneRef = useRef<HTMLInputElement>(null!);
  const mobileRef = useRef<HTMLInputElement>(null!);

  const handleUpdateProfile = useCallback(
    async (event: SyntheticEvent | KeyboardEvent) => {
      event.preventDefault();

      const lastNameValue = lastname ?? profile?.firstName;
      const firstNameValue = firstname ?? profile?.lastName;
      const companyValue = company ?? profile?.company;
      const zipcodeValue = zipcode ?? profile?.userInfos.zipcode;
      const addressValue = address ?? profile?.userInfos.address;
      const countryValue = country ?? profile?.userInfos.country;
      const phoneValue = phone ?? profile?.userInfos.phone;
      const mobileValue = mobile ?? profile?.userInfos.mobile;

      formCheckError(event, "lastName", lastNameValue, lastNameRef, t);
      formCheckError(event, "firstName", firstNameValue, firstNameRef, t);
      formCheckError(event, "company", companyValue, companyRef, t);
      formCheckError(event, "zipcode", zipcodeValue, zipcodeRef, t);
      formCheckError(event, "address", addressValue, addressRef, t);
      formCheckError(event, "countryProfile", countryValue, countryRef, t);
      if (!phone && !mobile) {
        formCheckError(event, "phone", phoneValue, phoneRef, t);
        formCheckError(event, "mobile", mobileValue, mobileRef, t);
      }

      if (
        lastNameValue &&
        firstNameValue &&
        companyValue &&
        zipcodeValue &&
        addressValue &&
        countryValue &&
        (phoneValue || mobileValue)
      ) {
        try {
          await backend.connexion.updateUserInfos({
            firstname: firstname ?? profile?.firstName,
            lastname: lastname ?? profile?.lastName,
            company: company ?? profile?.company,
            country: country ?? profile?.userInfos.country,
            phone: phone ?? profile?.userInfos.phone,
            mobile: mobile ?? profile?.userInfos.mobile,
            address: address ?? profile?.userInfos.address,
            zipcode: zipcode ?? profile?.userInfos.zipcode,
          });
          toast.success(t("succesfuly_updated_infos"));
        } catch (e) {
          toast.error(t("error_updating"));
        } finally {
          auth.refreshToken();
        }
      }
    },
    [
      address,
      auth,
      backend.connexion,
      company,
      country,
      firstname,
      lastname,
      mobile,
      phone,
      profile?.company,
      profile?.firstName,
      profile?.lastName,
      profile?.userInfos.address,
      profile?.userInfos.country,
      profile?.userInfos.mobile,
      profile?.userInfos.phone,
      profile?.userInfos.zipcode,
      t,
      zipcode,
    ]
  );

  const [changed, setChanged] = useState<boolean>(false);

  return profile ? (
    <div className={classNames(styles.container)}>
      <div className={classNames(styles.titleprinc)}>
        {/* <h2>{t("Personal_Information")}</h2> */}
      </div>
      <div className={classNames("col-12 row")}>
        <div
          className={classNames(
            styles.firstcontent,
            "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 row"
          )}
        >
          <div className={classNames("col-12 col-sm-12 col-md-12 col-lg-12")}>
            <div className={classNames(styles.title, styles.paddingtitle)}>
              <div>
                <div className={classNames(styles.compte)}>
                  <p className={classNames(styles.letter)}>{initials}</p>
                </div>
              </div>
              <p className={classNames(styles.name)}>
                {firstname} {lastname}
              </p>
            </div>
            <div className={classNames(styles.bluetitle, styles.paddingtitle)}>
              <p>{t("account_inf")}</p>
            </div>
          </div>
          <div
            className={classNames(
              styles.onecontent,
              "col-12 col-sm-12 col-md-6 col-lg-6 row"
            )}
          >
            <div className={classNames(styles.oneform)}>
              <label htmlFor="nom">
                {t("Name")}&nbsp;
                <span className={classNames(styles.redtext)}>*</span>
              </label>
              <input
                id="nom"
                type="text"
                placeholder="Votre Nom"
                ref={lastNameRef}
                defaultValue={lastname}
                value={lastname}
                onChange={(e) => {
                  setLastname(e.target.value);
                  setChanged(true);
                }}
              />
            </div>

            <div className={classNames(styles.oneform)}>
              <label htmlFor="nom">
                {t("Email")}&nbsp;
                <span className={classNames(styles.redtext)}>*</span>
              </label>
              <input
                id="nom"
                type="mail"
                placeholder="exemple@mail.com"
                defaultValue={profile?.email}
                value={profile?.email}
                disabled
              />
            </div>

            <div className={classNames(styles.oneform)}>
              <label htmlFor="nom">
                {t("Address")}&nbsp;
                <span className={classNames(styles.redtext)}>*</span>
              </label>
              <input
                id="nom"
                type="text"
                ref={addressRef}
                placeholder={t("Address")}
                defaultValue={address}
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  setChanged(true);
                }}
              />
            </div>

            <div className={classNames(styles.oneform)}>
              <label htmlFor="cp">
                {t("CP")}&nbsp;
                <span className={classNames(styles.redtext)}>*</span>
              </label>
              <input
                id="cp"
                type="text"
                ref={zipcodeRef}
                placeholder={t("CP")}
                defaultValue={zipcode}
                value={zipcode}
                onChange={(e) => {
                  setZipcode(e.target.value);
                  setChanged(true);
                }}
              />
            </div>

            <div className={classNames(styles.oneform)}>
              <label htmlFor="nom">
                {t("Country")}&nbsp;
                <span className={classNames(styles.redtext)}>*</span>
              </label>
              <input
                id="nom"
                type="text"
                ref={countryRef}
                defaultValue={country}
                value={country}
                placeholder={t("Country")}
                onChange={(e) => {
                  setCountry(e.target.value);
                  setChanged(true);
                }}
              />
            </div>
          </div>
          <div
            className={classNames(
              styles.onecontent,
              "col-12 col-sm-12 col-md-6 col-lg-6 row"
            )}
          >
            <div className={classNames(styles.oneform)}>
              <label htmlFor="prenom">
                {t("First_name")}&nbsp;
                <span className={classNames(styles.redtext)}>*</span>
              </label>
              <input
                id="prenom"
                type="text"
                placeholder="Votre Prénom"
                ref={firstNameRef}
                defaultValue={firstname}
                value={firstname}
                onChange={(e) => {
                  setFirstname(e.target.value);
                  setChanged(true);
                }}
              />
            </div>

            <div className={classNames(styles.oneform)}>
              <label htmlFor="nom">
                {t("Company")}&nbsp;
                <span className={classNames(styles.redtext)}>*</span>
              </label>
              <input
                id="nom"
                type="text"
                ref={companyRef}
                placeholder={t("Company")}
                defaultValue={company}
                value={company}
                disabled
                className={classNames(styles.disabledInput)}
              />
            </div>
            <div className={classNames(styles.oneform)}>
              <label htmlFor="nom">
                {t("company_code")}&nbsp;
                <span className={classNames(styles.redtext)}>*</span>
              </label>
              <div className={classNames(styles.companyCodeDiv)}>
                <input
                  id="nom"
                  type="text"
                  ref={companyRef}
                  placeholder={t("company_code")}
                  defaultValue={profile?.defaultTeam?.id}
                  value={profile?.defaultTeam?.id}
                  disabled
                  className={classNames(styles.companyCodeFont)}
                />
                <span
                  className={classNames(styles.icon)}
                  onClick={handleCopyLink}
                >
                  <img src={copy} alt="copy"></img>
                </span>
              </div>
            </div>

            <div className={classNames(styles.oneform)}>
              <label htmlFor="nom">
                {t("telephone")}&nbsp;
                <span className={classNames(styles.redtext)}>*</span>
              </label>
              <input
                id="tel"
                type="tel"
                ref={phoneRef}
                placeholder="+33 X XX XX XX XX"
                defaultValue={phone}
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setChanged(true);
                }}
              />
            </div>

            <div className={classNames(styles.oneform)}>
              <label htmlFor="nom">
                {t("telephonemobile")}&nbsp;
                <span className={classNames(styles.redtext)}>*</span>
              </label>
              <input
                id="tel"
                type="tel"
                placeholder="+33 X XX XX XX XX"
                defaultValue={mobile}
                value={mobile}
                ref={mobileRef}
                onChange={(e) => {
                  setMobile(e.target.value);
                  setChanged(true);
                }}
              />
            </div>

            <div className={classNames(styles.bluetitle, styles.space)}>
              <p> </p>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.onecontent,
            styles.theme,

            "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 row"
          )}
        >
          {/* <div className={classNames("col-12 col-sm-12 col-md-12 col-lg-12")}>
            <div className={classNames(styles.title)}>
              <div className={classNames(styles.bluetitle)}>
                <p>Theme</p>
              </div>
            </div>
          </div> */}
          <div className={classNames("col-12 row")}>
            <div
              className={classNames(
                styles.firstcontent,
                "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row"
              )}
            >
              <div
                className={classNames(
                  styles.onecontent,
                  "col-12 col-sm-12 col-md-12 col-lg-12 row"
                )}
              >
                <div className={classNames(styles.oneform)}>
                  <label className={classNames("col-12 ")}>{t("Forgot")}</label>
                  <div
                    className={classNames(
                      isClickable
                        ? styles.littlething
                        : styles.notClickableLittlething,
                      styles.button_email,
                      styles.passwordsize,

                      "col-10"
                    )}
                    onClick={(e) => isClickable && handleResetPassword(e)}
                  >
                    {t("Send_email")}
                  </div>
                </div>

                <div className={classNames(styles.oneform)}>
                  <label className={classNames("col-12 ")}>
                    {t("changeinfos")}
                  </label>
                  <div
                    className={classNames(
                      isClickable
                        ? styles.littlething
                        : styles.notClickableLittlething,
                      styles.button_email,
                      styles.passwordsize,
                      !changed && styles.disabled,

                      "col-10"
                    )}
                    onClick={(e) => changed && handleUpdateProfile(e)}
                  >
                    {t("saveprofile")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(styles.mandatoryFields, styles.inputzone)}>
        <span className={classNames(styles.redtext)}>* </span> {t("mandatory")}
      </div>
    </div>
  ) : null;
};

export default Monprofil;
