import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./styles/validateAccount.module.scss";
import { TFunction, useTranslation } from "react-i18next";
import { useBackend } from "../backend/BackendProvider";

import { useLocation, useParams } from "react-router-dom";
import { mapAppToUrl } from "./Domain/redirectUrl";

const ValidateAccount = () => {
  const { uuid } = useParams<{
    uuid: string | undefined;
  }>();

  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const validate = urlParams.get("validate");

  const { t } = useTranslation();

  const backend = useBackend();

  const [userMail, setUserMail] = useState<string>();
  const [actionDone, setActionDone] = useState<
    "None" | "Validated" | "Rejected"
  >("None");

  const getMail = useCallback(async () => {
    if (uuid) {
      const user = await backend.connexion.getUserMail(uuid);
      if (user.value === null) {
        setActionDone("Rejected");
      } else {
        setUserMail(user.value.email);
        if (user.value.enabled) {
          setActionDone("Validated");
        }
      }
    }
  }, [backend.connexion, uuid]);

  useEffect(() => {
    getMail();
  }, [getMail]);

  const handleValidateUser = useCallback(async () => {
    if (uuid && validate !== null) {
      try {
        await backend.connexion.validateNewUser(
          uuid,
          validate === "true",
          mapAppToUrl("Connect-connected")
        );
      } finally {
      }
    }
  }, [backend.connexion, uuid, validate]);

  useEffect(() => {
    handleValidateUser();
  }, [handleValidateUser]);

  return (
    <div className={classNames("col-12", styles.zonemodal)}>
      <div className={classNames("col-12", styles.container)}>
        <div className={classNames("col-10 row", styles.bat_modal_margin2)}>
          <div className={classNames(styles.bat_modals_title, "col-12")}>
            <h5 className={classNames(styles.bat_modals_titlecontent)}>
              {titleText(validate, actionDone, t)}
            </h5>
          </div>

          <div className={classNames(styles.bat_modals_import, "col-12")}>
            {textMessage(validate, actionDone, t, userMail)}
          </div>
        </div>
      </div>
    </div>
  );
};

function titleText(
  validate: string | null,
  actionDone: "None" | "Validated" | "Rejected",
  t: TFunction<"translation">
) {
  if (actionDone !== "None") {
    if (actionDone === "Rejected") {
      return t("already_rejected_title");
    } else {
      return t("already_validated_title");
    }
  } else if (validate === null) {
    return t("error");
  } else {
    if (validate === "true") {
      return t("validated");
    } else {
      return t("rejected");
    }
  }
}

function textMessage(
  validate: string | null,
  actionDone: "None" | "Validated" | "Rejected",
  t: TFunction<"translation">,
  userMail: string | undefined
) {
  if (actionDone !== "None") {
    if (actionDone === "Rejected") {
      return t("already_rejected");
    } else {
      return t("already_validated", { mail: userMail });
    }
  } else if (validate === null) {
    return t("error_validating");
  } else {
    if (validate === "true") {
      return `${t("user_has_been_successfuly", { mail: userMail })} ${t(
        "validated"
      )}`;
    } else {
      return `${t("user_has_been_successfuly", { mail: userMail })} ${t(
        "rejected"
      )}`;
    }
  }
}

export default ValidateAccount;
