import { useAuth } from "@mgi-labs/mgi-id";
import React, { createContext, useContext, useEffect, useState } from "react";
import Backend from "./Backend";
import { SubscriptionLimitation } from "./limitation";

export type ConnectProps = {
  limitation: SubscriptionLimitation;
  connexion: Backend;
};

const BackendContext = createContext<ConnectProps>(null!);

export const BackendProvider: React.FunctionComponent<{
  children: React.ReactNode;
  value: Backend;
}> = ({ children, value }) => {
  const limits = useLimitations(value);
  return (
    <BackendContext.Provider value={{ limitation: limits, connexion: value }}>
      {children}
    </BackendContext.Provider>
  );
};

export function useBackend(): ConnectProps {
  return useContext(BackendContext);
}

export function useLimitations(connect: Backend): SubscriptionLimitation {
  const [limitation, setLimitation] = useState<SubscriptionLimitation>({
    user: 0,
    book: 0,
    projects: 0,
    prestaId: 0,
    endDate: new Date(),
    startDate: new Date(),
    isBeta: false,
  });
  const { auth } = useAuth();
  useEffect(() => {
    async function getLimitations() {
      const limitations = await connect.getLimitation();
      setLimitation(limitations);
    }
    auth.authenticated && getLimitations();
  }, [auth.authenticated, connect]);
  return limitation;
}

export function useLimits() {
  return useContext(BackendContext);
}
