import React, { useCallback, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./styles/AddEquipmentModal.module.scss";
import { useTranslation } from "react-i18next";
import plus from "../images/icon/plus.svg";
import Informationmodal from "./Informationmodal";
import { useBackend } from "../backend/BackendProvider";
import {
  EquipmentModelType,
  equipmentListType,
} from "./Domain/machineInfo.interface";
import { KeyedMutator } from "swr";
import { Machine } from "./Domain/machine.interface";
import { toast } from "react-toastify";
import { isharrisbruno } from "./Domain/checkharrisbruno";

const Addequipmentdesktop = ({
  // theme,
  setIsVisibleEquipment,
  mutate,
}: {
  // theme: "dark" | "light";
  setIsVisibleEquipment: React.Dispatch<boolean>;
  mutate: KeyedMutator<Machine[]>;
}) => {
  const { t } = useTranslation();

  const [selectedType, setSelectedType] =
    useState<EquipmentModelType>("JETvarnish");

  const [numberMachine, setNumberMachine] = useState<string>();
  const [jetCode, setJetCode] = useState<string>();
  const [controlCode, setCodeControl] = useState<string>();
  const [equipmentName, setEquipmentName] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [errorStatus, setErrorStatus] = useState<string>();
  const backend = useBackend();

  const [isVisibleOpenInfos, setIsVisibleOpenInfos] = useState<
    "jetCode" | "controlCode" | "serialnumber"
  >();

  const [step, setStep] = useState<number>(1);

  const [isConnected, setIsConnected] = useState<boolean | undefined>();

  const equipmentNameRef = useRef<HTMLInputElement>(null!);
  const equipmentNumberRef = useRef<HTMLInputElement>(null!);
  const jetCodeRef = useRef<HTMLInputElement>(null!);
  const controlCodeRef = useRef<HTMLInputElement>(null!);

  const addNewEquipmentCallback = useCallback(
    async (e: any) => {
      var isError = false;
      e?.preventDefault();
      let response;
      if (jetCode && controlCode && equipmentName && numberMachine) {
        setIsLoading(true);
        try {
          response = await backend.connexion.linkMachineV1ToTeam(
            jetCode,
            controlCode,
            selectedType,
            parseInt(numberMachine),
            equipmentName
          );
        } catch (e) {
          isError = true;
          setErrorStatus((e as Error).message);
        } finally {
          setIsConnected(response?.isConnected);
          setStep(2);
          setIsLoading(false);
          if (!isError) {
            toast.success(t("success_add_equipment"));
          }
          mutate();
        }
      }
    },
    [
      backend.connexion,
      controlCode,
      equipmentName,
      jetCode,
      mutate,
      numberMachine,
      selectedType,
      t,
    ]
  );

  return (
    <>
      <div className={classNames(styles.absolutemodal)}>
        <div
          onClick={() => setIsVisibleEquipment(false)}
          className={classNames(styles.bkgmodal)}
        ></div>
        <div className={classNames(styles.modal, styles.zindex10)}>
          <div className={classNames(styles.closebtn)}>
            <svg
              onClick={() => setIsVisibleEquipment(false)}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g id="close" transform="translate(-815 -260)">
                <path
                  id="Tracé_61"
                  data-name="Tracé 61"
                  d="M838.513,267.236l-1.865-1.865-7.392,7.392-7.392-7.392L820,267.236l7.392,7.392L820,282.02l1.865,1.865,7.392-7.392,7.392,7.392,1.865-1.865-7.392-7.392Z"
                  transform="translate(-2.257 -2.257)"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
          <div className={classNames(styles.rightzone)}>
            <form action="#">
              <div className={classNames(styles.form)}>
                <div className={classNames(styles.titleform)}>
                  <div
                    className={classNames(
                      styles.logozoneconnect,
                      styles.textmaj
                    )}
                  >
                    <svg
                      id="adf_scanner_black_24dp"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g id="Groupe_783" data-name="Groupe 783">
                        <rect
                          id="Rectangle_1422"
                          data-name="Rectangle 1422"
                          width="24"
                          height="24"
                          fill="none"
                        />
                      </g>
                      <g id="Groupe_784" data-name="Groupe 784">
                        <path
                          className={classNames(styles.fill)}
                          id="Tracé_1467"
                          data-name="Tracé 1467"
                          d="M19,12H18V4H6v8H5a3,3,0,0,0-3,3v5H22V15A3,3,0,0,0,19,12Zm-3,0H8V6h8Zm2,5a1,1,0,1,1,1-1A1,1,0,0,1,18,17Z"
                          fill="#fff"
                        />
                      </g>
                    </svg>

                    {t("newequipment")}
                  </div>
                  {errorStatus && (
                    <div className={classNames(styles.error)}>
                      <p>{t(errorStatus)}</p>
                    </div>
                  )}

                  <div className={classNames(styles.separation)}></div>
                </div>
                {step === 1 ? (
                  <>
                    <div className={classNames(styles.inputzone, "col-12")}>
                      <div
                        className={classNames(
                          styles.detailcontent,
                          styles.paddingnone,
                          "col-12"
                        )}
                      >
                        <p>{t("equipmenttype")}</p>
                        <label
                          className={classNames(styles.select, "col-12")}
                          htmlFor="slct"
                        >
                          <select
                            name="machinetype"
                            id="machine-select"
                            defaultValue={"JETvarnish"}
                            onChange={(e) =>
                              setSelectedType(
                                e.target.value as EquipmentModelType
                              )
                            }
                          >
                            {equipmentListType(
                              isharrisbruno() ? "HB" : "MGI"
                            ).map((e, i) => (
                              <option key={i} value={e}>
                                {e}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>
                    <div className={classNames(styles.inputzone)}>
                      <div className={classNames(styles.activemachine)}>
                        <div className={classNames(styles.inputzone, "col-12")}>
                          <label htmlFor="name">
                            {t("name")}
                            <span className={classNames(styles.redtext)}>
                              *
                            </span>
                          </label>
                          <input
                            required
                            ref={equipmentNameRef}
                            type="text"
                            name="equipmentname"
                            id="equipmentname"
                            onChange={(e) => setEquipmentName(e.target.value)}
                          />
                        </div>
                        <div className={classNames(styles.inputzone, "col-12")}>
                          <label htmlFor="number">
                            {t("equimentnumber")}
                            <span className={classNames(styles.redtext)}>
                              *
                            </span>
                            <div className={classNames(styles.informations)}>
                              <svg
                                onClick={() =>
                                  setIsVisibleOpenInfos("serialnumber")
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                              >
                                <path
                                  id="infos"
                                  d="M22.65,34h3V22h-3ZM24,18.3a1.643,1.643,0,0,0,1.175-.45,1.515,1.515,0,0,0,.475-1.15,1.681,1.681,0,0,0-.475-1.2,1.631,1.631,0,0,0-2.35,0,1.681,1.681,0,0,0-.475,1.2,1.515,1.515,0,0,0,.475,1.15A1.643,1.643,0,0,0,24,18.3ZM24,44a19.352,19.352,0,0,1-7.75-1.575A20.15,20.15,0,0,1,5.575,31.75a19.978,19.978,0,0,1,0-15.55,19.988,19.988,0,0,1,4.3-6.35A20.5,20.5,0,0,1,16.25,5.575a19.978,19.978,0,0,1,15.55,0A19.969,19.969,0,0,1,42.425,16.2a19.978,19.978,0,0,1,0,15.55,20.5,20.5,0,0,1-4.275,6.375,19.988,19.988,0,0,1-6.35,4.3A19.475,19.475,0,0,1,24,44Zm.05-3a16.3,16.3,0,0,0,12-4.975A16.483,16.483,0,0,0,41,23.95a16.342,16.342,0,0,0-4.95-12A16.4,16.4,0,0,0,24,7a16.424,16.424,0,0,0-12.025,4.95A16.359,16.359,0,0,0,7,24a16.383,16.383,0,0,0,4.975,12.025A16.441,16.441,0,0,0,24.05,41ZM24,24Z"
                                  transform="translate(-4 -4)"
                                />
                              </svg>
                            </div>
                          </label>
                          <input
                            required
                            ref={equipmentNumberRef}
                            type="text"
                            name="equipmentnumber"
                            id="equipmentnumber"
                            onChange={(e) => setNumberMachine(e.target.value)}
                          />
                        </div>
                        <div className={classNames(styles.inputzone, "col-12")}>
                          <label htmlFor="mail">
                            {t("jetcode")}
                            <span className={classNames(styles.redtext)}>
                              *
                            </span>
                            <div className={classNames(styles.informations)}>
                              <svg
                                onClick={() => setIsVisibleOpenInfos("jetCode")}
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                              >
                                <path
                                  id="infos"
                                  d="M22.65,34h3V22h-3ZM24,18.3a1.643,1.643,0,0,0,1.175-.45,1.515,1.515,0,0,0,.475-1.15,1.681,1.681,0,0,0-.475-1.2,1.631,1.631,0,0,0-2.35,0,1.681,1.681,0,0,0-.475,1.2,1.515,1.515,0,0,0,.475,1.15A1.643,1.643,0,0,0,24,18.3ZM24,44a19.352,19.352,0,0,1-7.75-1.575A20.15,20.15,0,0,1,5.575,31.75a19.978,19.978,0,0,1,0-15.55,19.988,19.988,0,0,1,4.3-6.35A20.5,20.5,0,0,1,16.25,5.575a19.978,19.978,0,0,1,15.55,0A19.969,19.969,0,0,1,42.425,16.2a19.978,19.978,0,0,1,0,15.55,20.5,20.5,0,0,1-4.275,6.375,19.988,19.988,0,0,1-6.35,4.3A19.475,19.475,0,0,1,24,44Zm.05-3a16.3,16.3,0,0,0,12-4.975A16.483,16.483,0,0,0,41,23.95a16.342,16.342,0,0,0-4.95-12A16.4,16.4,0,0,0,24,7a16.424,16.424,0,0,0-12.025,4.95A16.359,16.359,0,0,0,7,24a16.383,16.383,0,0,0,4.975,12.025A16.441,16.441,0,0,0,24.05,41ZM24,24Z"
                                  transform="translate(-4 -4)"
                                />
                              </svg>
                            </div>
                          </label>
                          <input
                            required
                            ref={jetCodeRef}
                            type="text"
                            name="jetcode"
                            id="jetcode"
                            onChange={(e) => setJetCode(e.target.value)}
                          />
                        </div>
                        <div className={classNames(styles.inputzone, "col-12")}>
                          <label htmlFor="mail">
                            {t("controlcode")}{" "}
                            <span className={classNames(styles.redtext)}>
                              *
                            </span>
                            <div className={classNames(styles.informations)}>
                              <svg
                                onClick={() =>
                                  setIsVisibleOpenInfos("controlCode")
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                              >
                                <path
                                  id="infos"
                                  d="M22.65,34h3V22h-3ZM24,18.3a1.643,1.643,0,0,0,1.175-.45,1.515,1.515,0,0,0,.475-1.15,1.681,1.681,0,0,0-.475-1.2,1.631,1.631,0,0,0-2.35,0,1.681,1.681,0,0,0-.475,1.2,1.515,1.515,0,0,0,.475,1.15A1.643,1.643,0,0,0,24,18.3ZM24,44a19.352,19.352,0,0,1-7.75-1.575A20.15,20.15,0,0,1,5.575,31.75a19.978,19.978,0,0,1,0-15.55,19.988,19.988,0,0,1,4.3-6.35A20.5,20.5,0,0,1,16.25,5.575a19.978,19.978,0,0,1,15.55,0A19.969,19.969,0,0,1,42.425,16.2a19.978,19.978,0,0,1,0,15.55,20.5,20.5,0,0,1-4.275,6.375,19.988,19.988,0,0,1-6.35,4.3A19.475,19.475,0,0,1,24,44Zm.05-3a16.3,16.3,0,0,0,12-4.975A16.483,16.483,0,0,0,41,23.95a16.342,16.342,0,0,0-4.95-12A16.4,16.4,0,0,0,24,7a16.424,16.424,0,0,0-12.025,4.95A16.359,16.359,0,0,0,7,24a16.383,16.383,0,0,0,4.975,12.025A16.441,16.441,0,0,0,24.05,41ZM24,24Z"
                                  transform="translate(-4 -4)"
                                />
                              </svg>
                            </div>
                          </label>
                          <input
                            required
                            type="text"
                            ref={controlCodeRef}
                            name="controlcode"
                            id="controlcode"
                            onChange={(e) => setCodeControl(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classNames(styles.inputzone, "col-12")}>
                      <div
                        className={classNames(
                          styles.detailcontent,
                          styles.paddingnone,
                          "col-12"
                        )}
                      >
                        <p>
                          {isConnected
                            ? t("equipment_successfuly_connected")
                            : t("not_connected_equipment")}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className={classNames(styles.btnzone)}>
                <div className={classNames(styles.onebtn)}>
                  <div
                    className={classNames(
                      styles.submit,
                      (!(
                        jetCode &&
                        controlCode &&
                        equipmentName &&
                        numberMachine
                      ) ||
                        isLoading) &&
                        styles.disabled
                    )}
                    onClick={(e) =>
                      step === 1
                        ? addNewEquipmentCallback(e)
                        : setIsVisibleEquipment(false)
                    }
                  >
                    {step === 1 ? (
                      <div className={classNames(styles.buttonnolink)}>
                        <img src={plus} alt="add equipment" />
                        <p>{t("addequipment")}</p>
                      </div>
                    ) : (
                      <div className={classNames(styles.buttonnolink)}>
                        <p>{t("finish")}</p>
                      </div>
                    )}
                  </div>
                  {/*
                  Si modification
                   <div
                    className={classNames(
                      styles.submit,
                      isLoading && styles.disabled
                    )}
                    onClick={addNewEquipmentCallback}
                  >
                    <div className={classNames(styles.buttonnolink)}>
                      <p>{t("save")}</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {isVisibleOpenInfos && (
        <Informationmodal
          // theme={theme}
          isVisibleOpenInfos={isVisibleOpenInfos}
          setIsVisibleOpenInfos={setIsVisibleOpenInfos}
        />
      )}
    </>
  );
};

export default Addequipmentdesktop;
