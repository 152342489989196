import { useEffect, useState } from "react";
import { useBackend } from "../../backend/BackendProvider";
import { addYears } from "date-fns";
import differenceInDays from "date-fns/differenceInDays";

// Static values for initialization
export const USERS_VALUES = ["3", "4", "5", "6", "7", "8", "9", "10", "+"];
export const BOOKS_VALUES = ["3", "4", "5", "6", "7", "8", "9", "10", "+"];
export const PROJECTS_VALUES = ["1000", "1500", "2000", "2500", "3000", "+"];
export const UNIT_PRICE = { projects: 500, book: 1, user: 1 };
export const KEYS = { user: "user", projects: "projects", book: "book" };
export const MIN_PRO_PACK_PARAMS = { user: "3", projects: "1000", book: "3" };

export type GetProductDto = { id: number; reference: string; price: string };
export function GetAllProducts(): GetProductDto[] {
  const backend = useBackend();
  const [product, setProducts] = useState<GetProductDto[]>([]);
  useEffect(() => {
    async function fetchData() {
      const result = await backend.connexion.getAllProducts();
      result && setProducts(result.products);
    }
    fetchData();
  }, [backend, setProducts]);
  return product;
}

export function getProrataTemporis(
  subscription: Date,
  price: number
): { newPrice: number; days: number } {
  const days = differenceInDays(addYears(subscription, 1), new Date()) - 30;
  return { newPrice: Math.floor((price * days) / 365), days: days };
}

export interface OrderCustomerDto {
  userId: number;
  products: OrderProduct[];
  total: number;
  currency: string;
  endDate: Date;
}

export interface OrderRenewDto {
  userId: number;
  endDate: Date;
  type: string;
  currency: string;
}

export interface OrderProduct {
  id: number;
  quantity: number;
  price: number;
  reference: string;
  name: string;
}

export function mapParamToId(param: "user" | "book" | "projects") {
  switch (param) {
    case "user":
      return 54;
    case "book":
      return 55;
    case "projects":
      return 56;
  }
}

export function mapParamToReference(param: "user" | "book" | "projects") {
  switch (param) {
    case "user":
      return "VLS-EXUT-001";
    case "book":
      return "VLS-EXCA-001";
    case "projects":
      return "VLS-EXPR-001";
    default:
      return "";
  }
}

export function mapProductIdToName(id: number) {
  switch (id) {
    case 54:
      return "Pack.ext_ut";
    case 55:
      return "Pack.ext_cat";
    case 56:
      return "Pack.ext_pro";
    default:
      return "";
  }
}
