import useSWR, { SWRResponse } from "swr";
import { useBackend } from "../backend/BackendProvider";

export type KMUserDto = {
  id: string;
  email: string;
  enabled: boolean;
  company: string;
  fullName: string;
  createdAt: number;
  session: { lastLogin: number; number: number };
  group: string;
};

export function UseKMUsers(): SWRResponse<KMUserDto[], Error> {
  const backend = useBackend();
  return useSWR("kmUsers", async () => {
    return backend.connexion.getAllKmUsers();
  });
}
