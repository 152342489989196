//Function to manage km access to user invitation
// TODO : Make this more clean in future

const DFAULT_TEAM_ACCESS = [
  "8572cac8-7e5e-4bd3-9c74-427b34b54da3",
  "f7678027-4d9e-40b4-b721-161b6a8e50d3",
  "0afdf25b-a7bb-42d7-bb4f-12b8ceb90ef7",
];

export function hasInvitationKmAccess(uuid: string | undefined): boolean {
  return uuid ? DFAULT_TEAM_ACCESS.includes(uuid) : false;
}
