import useSWR from "swr";
import { SWRResponse } from "swr/dist/types";
import { useMachineBackend } from "../backend/MachineBackendProvider";
import { Machine } from "../components/Domain/machine.interface";

export function useAllMachines(): SWRResponse<Machine[], Error> {
  const backend = useMachineBackend();
  const key = ["all_equipments"];
  return useSWR(key, async (_) => {
    return backend.getMachines();
  });
}
