import { useAuth } from "@mgi-labs/mgi-id";
import React from "react";
import { I18nextProvider } from "react-i18next";
import Mgiconnect from "./components/MgiConnectRouter";
import i18n from "./Internationalization/i18n";
import { BackendProvider } from "./backend/BackendProvider";
import Backend from "./backend/Backend";
import { MachineBackendProvider } from "./backend/MachineBackendProvider";
import MachineBackend from "./backend/machineBackend";
function App() {
  const backendUrl = process.env["REACT_APP_BACKEND_URL"] ?? "";
  const machineBackendUrl = process.env["REACT_APP_MACHINE_BACKEND_URL"] ?? "";
  const { initialized, auth } = useAuth();
  if (!initialized) {
    return null;
  }

  return (
    <>
      <BackendProvider value={new Backend(backendUrl, auth)}>
        <MachineBackendProvider
          value={new MachineBackend(machineBackendUrl, auth)}
        >
          <I18nextProvider i18n={i18n}>
            <Mgiconnect />
          </I18nextProvider>
        </MachineBackendProvider>
      </BackendProvider>
    </>
  );
}
export default App;
