import {
  MACHINE_MODEL_TYPES,
  MachineModelParser,
} from "@mgi-labs/lib-liste-machine";

export type EquipmentModelType = (typeof Equipment_MODEL_TYPES)[number];

export const Equipment_MODEL_TYPES = MACHINE_MODEL_TYPES.filter(
  (t) =>
    MachineModelParser(t).kernelVersion === 1 &&
    MachineModelParser(t).type !== "ZRX"
);

export function equipmentListType(realm: string) {
  if (realm === "HB") {
    return MACHINE_MODEL_TYPES.filter(
      (t) => MachineModelParser(t).type === "ZRX"
    );
  }

  return Equipment_MODEL_TYPES;
}
