import useSWR, { SWRResponse } from "swr";
import { useBackend } from "../backend/BackendProvider";
import { AccountStats } from "../types";

export function UseApproveStats(): SWRResponse<
  Omit<AccountStats, "uuid">,
  Error
> {
  const backend = useBackend();
  return useSWR("approveStats", async () => {
    return backend.connexion.getApproveStats();
  });
}
