import classNames from "classnames";
import styles from "./styles/Extensions.module.scss";
import { useTranslation } from "react-i18next";
import Dashboard from "../images/logo/Logo-Dashboard.svg";
import Approve from "../images/logo/Logo-Approve.svg";
import Jetforce from "../images/logo/Logo-Jetforce.svg";
import {
  GetAllProducts,
  getProrataTemporis,
  USERS_VALUES,
  BOOKS_VALUES,
  PROJECTS_VALUES,
  UNIT_PRICE,
  KEYS,
  MIN_PRO_PACK_PARAMS,
  OrderCustomerDto,
  mapParamToId,
  mapParamToReference,
  mapProductIdToName,
} from "./Domain/prestashop";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useBackend } from "../backend/BackendProvider";
import { format } from "date-fns";

const Extensions = () => {
  const { t, i18n } = useTranslation();

  const products = GetAllProducts();

  const [selectedParams, setSelectedParams] = useState<{
    user: string;
    projects: string;
    book: string;
  }>(MIN_PRO_PACK_PARAMS);

  const [selectedPack, setSelectedPack] = useState<"pro" | "corpo">("pro");

  const backend = useBackend();

  // Get specific products
  const userExtension = products.find((p) => p.reference === "VLS-EXUT-001");
  const bookExtension = products.find((p) => p.reference === "VLS-EXCA-001");
  const projectExtension = products.find((p) => p.reference === "VLS-EXPR-001");
  const proPack = products.find((p) => p.reference === "VLS-PKP-001");
  const starterPack = products.find((p) => p.reference === "VLS-PKS-001");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Put products in object to map on it
  const extension = {
    user: {
      ...userExtension,
      price: parseInt(userExtension?.price ?? "0"),
    },
    book: {
      ...bookExtension,
      price: parseInt(bookExtension?.price ?? "0"),
    },
    projects: {
      ...projectExtension,
      price: parseInt(projectExtension?.price ?? "0"),
    },
  };

  // Returns difference bewteen selected params and value of current pack
  const paramsDiff = useMemo(() => {
    const displayUser = parseInt(selectedParams.user) - backend.limitation.user;
    const displayProjects =
      parseInt(selectedParams.projects) - backend.limitation.projects;
    const displayBook = parseInt(selectedParams.book) - backend.limitation.book;

    return {
      user: {
        price:
          backend.limitation.packType === "starter"
            ? parseInt(selectedParams.user) - parseInt(MIN_PRO_PACK_PARAMS.user)
            : displayUser,
        display: displayUser,
      },
      projects: {
        price:
          backend.limitation.packType === "starter"
            ? parseInt(selectedParams.projects) -
              parseInt(MIN_PRO_PACK_PARAMS.projects)
            : displayProjects,
        display: displayProjects,
      },
      book: {
        price:
          backend.limitation.packType === "starter"
            ? parseInt(selectedParams.book) - parseInt(MIN_PRO_PACK_PARAMS.book)
            : displayBook,
        display: displayBook,
      },
    };
  }, [
    backend.limitation.book,
    backend.limitation.packType,
    backend.limitation.projects,
    backend.limitation.user,
    selectedParams.book,
    selectedParams.projects,
    selectedParams.user,
  ]);

  // Init params from pack value
  useEffect(() => {
    if (backend.limitation.user !== 0) {
      if (backend.limitation.packType === "starter") {
        setSelectedParams(MIN_PRO_PACK_PARAMS);
      } else {
        setSelectedParams({
          user:
            backend.limitation.user > 10
              ? "10+"
              : backend.limitation.user.toString(),
          book:
            backend.limitation.book > 10
              ? "10+"
              : backend.limitation.book.toString(),
          projects:
            backend.limitation.projects > 3000
              ? "3000+"
              : backend.limitation.projects.toString(),
        });
      }
    }
  }, [
    backend.limitation.book,
    backend.limitation.packType,
    backend.limitation.projects,
    backend.limitation.user,
  ]);

  // Callback to handle change of params
  const handleChangeParams = useCallback(
    (type: "user" | "projects" | "book", value: string) => {
      const newSelectedParams = { ...selectedParams, [type]: value };
      if (value.includes("+")) {
        setSelectedPack("corpo");
      }
      if (Object.values(newSelectedParams).every((v) => !v.includes("+"))) {
        setSelectedPack("pro");
      }
      setSelectedParams(newSelectedParams);
    },
    [selectedParams]
  );

  // Prorata temporis price per product
  const paramsPrice = useMemo(() => {
    return {
      user: getProrataTemporis(
        new Date(backend.limitation.startDate),
        (extension.user.price * paramsDiff.user.price) / UNIT_PRICE.user
      ),
      book: getProrataTemporis(
        new Date(backend.limitation.startDate),
        (extension.book.price * paramsDiff.book.price) / UNIT_PRICE.book
      ),
      projects: getProrataTemporis(
        new Date(backend.limitation.startDate),
        (extension.projects.price * paramsDiff.projects.price) /
          UNIT_PRICE.projects
      ),
      proPack: getProrataTemporis(
        new Date(backend.limitation.startDate),
        parseInt(
          backend.limitation.packType === "starter"
            ? (
                parseInt(proPack?.price ?? "0") -
                parseInt(starterPack?.price ?? "0")
              ).toString()
            : "0"
        )
      ),
    };
  }, [
    backend.limitation.packType,
    backend.limitation.startDate,
    extension.book.price,
    extension.projects.price,
    extension.user.price,
    paramsDiff.book.price,
    paramsDiff.projects.price,
    paramsDiff.user.price,
    proPack?.price,
    starterPack?.price,
  ]);

  // Total price to display
  const priceSum = Object.values(paramsPrice).reduce(
    (partialSum, a) => partialSum + a.newPrice,
    0
  );

  // Callback to handle command
  const handleSendCommand = useCallback(async () => {
    const products: any = [];
    {
      priceSum > 0 &&
        Object.keys(selectedParams).forEach((s) => {
          const key = s as "user" | "projects" | "book";
          paramsDiff[key].price > 0 &&
            products.push({
              id: mapParamToId(key),
              quantity: paramsDiff[key].price / UNIT_PRICE[key],
              price: parseFloat(
                (
                  paramsPrice[key].newPrice /
                  (paramsDiff[key].price / UNIT_PRICE[key])
                ).toFixed(2)
              ),
              reference: mapParamToReference(key),
              name: t(mapProductIdToName(mapParamToId(key))),
            });
        });
      if (backend.limitation.packType === "starter") {
        products.push({
          id: 41,
          quantity: 1,
          price: parseFloat(paramsPrice.proPack.newPrice.toFixed(2)),
          reference: "VLS-PKP-001",
          name: t("pro"),
        });
      }
      const order: OrderCustomerDto = {
        userId: backend.limitation.prestaId,
        products: products,
        total: parseFloat(priceSum.toFixed(2)),
        currency: i18n.language.toUpperCase().includes("FR") ? "€" : "$",
        endDate: backend.limitation.endDate,
      };
      // keep if we need to go back to old version
      //const url = await backend.connexion.sendCommand(order);
      //window.open(url.url, "_blank")?.focus();
      try {
        setIsLoading(true);
        const pdf = await backend.connexion.createOrder(order);
        const href = URL.createObjectURL(pdf);
        const link = document.createElement("a");
        link.href = href;

        link.download = `LUR_${format(new Date(), "yyyy-MM-dd")}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    backend.connexion,
    backend.limitation.endDate,
    backend.limitation.packType,
    backend.limitation.prestaId,
    i18n.language,
    paramsDiff,
    paramsPrice,
    priceSum,
    selectedParams,
    t,
  ]);

  return (
    <>
      <div className={classNames(styles.Container)}>
        <div className={classNames(styles.chooseextension)}>
          <div className={classNames(styles.packselection)}>
            <div
              className={classNames(
                styles.onepack,
                backend.limitation.packType === "starter" && styles.owned
              )}
            >
              <div
                className={classNames(
                  styles.scrollview,
                  backend.limitation.packType !== "starter"
                    ? styles.divfornotrenouvelabo
                    : styles.divfornotrenouvelabo
                )}
              >
                <p className={classNames(styles.title)}>Pack Starter</p>
                <div className={classNames(styles.logopack)}>
                  <img src={Approve} alt="Approve" />
                  <img src={Dashboard} alt="Dashboard" />
                  <img src={Jetforce} alt="Jetforce" />
                </div>
                <p className={classNames(styles.price)}>
                  1950,00 € {t("HT")}
                  <br />{" "}
                  <span className={classNames(styles.priceabo)}>
                    {t("Year")}
                  </span>
                </p>
                <div className={classNames(styles.oneoptiondetail)}>
                  <p className={classNames(styles.notimportant)}>
                    {t("nbuser")} :
                  </p>
                  <p>2 {t("user")}</p>
                </div>
                <div className={classNames(styles.oneoptiondetail)}>
                  <p className={classNames(styles.notimportant)}>
                    {t("nbprojects")} :
                  </p>
                  <p>100 {t("projets")}</p>
                </div>
                <div className={classNames(styles.oneoptiondetail)}>
                  <p className={classNames(styles.notimportant)}>
                    {t("nbbook")} :
                  </p>
                  <p>1 {t("cataloguesvirtuels")}</p>
                </div>
              </div>
              {backend.limitation.packType === "starter" && (
                <>
                  <a className={classNames(styles.btnrenouvelerabo)} href={"/"}>
                    {t("renouvelerabo")}
                  </a>
                  <div
                    className={classNames(
                      styles.abonnementactif,
                      styles.notimportant
                    )}
                  >
                    {t("Abonnementactif")}
                  </div>
                </>
              )}
            </div>
            <div
              className={classNames(
                styles.onepack,
                selectedPack === "pro" && styles.selected,
                backend.limitation.packType !== "starter" && styles.owned
              )}
            >
              <div
                className={classNames(
                  styles.scrollview,
                  backend.limitation.packType !== "starter"
                    ? styles.divfornotrenouvelabo
                    : styles.divfornotrenouvelabo
                )}
              >
                <p className={classNames(styles.title)}>Pack Pro</p>
                <div className={classNames(styles.logopack)}>
                  <img src={Approve} alt="Approve" />
                  <img src={Dashboard} alt="Dashboard" />
                  <img src={Jetforce} alt="Jetforce" />
                </div>
                <p className={classNames(styles.price)}>
                  4280,00 € {t("HT")}
                  <br />{" "}
                  <span className={classNames(styles.priceabo)}>
                    {t("Year")}
                  </span>
                </p>
                <div className={classNames(styles.oneoptiondetail)}>
                  <p className={classNames(styles.notimportant)}>
                    {t("nbuser")} :
                  </p>
                  <p>3 - 10 {t("user")}</p>
                </div>
                <div className={classNames(styles.oneoptiondetail)}>
                  <p className={classNames(styles.notimportant)}>
                    {t("nbprojects")} :
                  </p>
                  <p>1k - 3k {t("projets")}</p>
                </div>
                <div className={classNames(styles.oneoptiondetail)}>
                  <p className={classNames(styles.notimportant)}>
                    {t("nbbook")} :
                  </p>
                  <p>3 - 10 {t("cataloguesvirtuels")}</p>
                </div>
              </div>
              {backend.limitation.packType !== "starter" && (
                <a className={classNames(styles.btnrenouvelerabo)} href={"/"}>
                  {t("renouvelerabo")}
                </a>
              )}
              {backend.limitation.packType !== "starter" && (
                <div
                  className={classNames(
                    styles.abonnementactif,
                    styles.notimportant
                  )}
                >
                  {t("Abonnementactif")}
                </div>
              )}
            </div>
            <div
              className={classNames(
                styles.onepack,
                selectedPack === "corpo" && styles.selected
              )}
            >
              <p className={classNames(styles.title)}>Pack Corporate</p>
              <div className={classNames(styles.logopack)}>
                <img src={Approve} alt="Approve" />
                <img src={Dashboard} alt="Dashboard" />
                <img src={Jetforce} alt="Jetforce" />
              </div>
              <p className={classNames(styles.price, styles.textcenter)}>
                {t("Offrealademande")}
              </p>
              <a
                href={
                  i18n.language === "fr"
                    ? "https://shop.mgi-labs.com/fr/nous-contacter"
                    : "https://shop.mgi-labs.com/en/contact-us"
                }
                target="_blank"
                rel="noreferrer"
                className={classNames(styles.contactUs)}
              >
                <div className={classNames(styles.contacteznous)}>
                  <p>{t("contactus")}</p>
                </div>
              </a>

              <div className={classNames(styles.textecontacteznous)}>
                <p>{t("Offrealademandetext")}</p>
              </div>
            </div>
          </div>
          <div className={classNames(styles.optionselection)}>
            <div className={classNames(styles.oneoptionselection)}>
              <p>{t("cbdecomptebesoin")}</p>
              <div className={classNames(styles.choosenumberzone)}>
                {USERS_VALUES.map((userValue) => {
                  return (
                    <div
                      className={classNames(
                        styles.onenumber,
                        backend.limitation.user === parseInt(userValue) &&
                          styles.owned,
                        selectedParams?.user === userValue && styles.selected,
                        parseInt(userValue) < backend.limitation.user &&
                          styles.disabled
                      )}
                      onClick={() =>
                        (parseInt(userValue) >= backend.limitation.user ||
                          userValue === "+") &&
                        handleChangeParams("user", userValue)
                      }
                    >
                      <p>{userValue}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classNames(styles.oneoptionselection)}>
              <p>{t("cbdeprojetbesoin")}</p>
              <div className={classNames(styles.choosenumberzone)}>
                {PROJECTS_VALUES.map((projectValue) => (
                  <div
                    className={classNames(
                      styles.onenumber,
                      backend.limitation.projects === parseInt(projectValue) &&
                        styles.owned,
                      selectedParams?.projects === projectValue &&
                        styles.selected,
                      parseInt(projectValue) < backend.limitation.projects &&
                        styles.disabled
                    )}
                    onClick={() =>
                      (parseInt(projectValue) >= backend.limitation.projects ||
                        projectValue === "+") &&
                      handleChangeParams("projects", projectValue)
                    }
                  >
                    <p>{projectValue}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className={classNames(styles.oneoptionselection)}>
              <p>{t("cbdecatabesoin")}</p>
              <div className={classNames(styles.choosenumberzone)}>
                {BOOKS_VALUES.map((bookValue) => (
                  <div
                    className={classNames(
                      styles.onenumber,
                      backend.limitation.book === parseInt(bookValue) &&
                        styles.owned,
                      selectedParams?.book === bookValue && styles.selected,
                      parseInt(bookValue) < backend.limitation.book &&
                        styles.disabled
                    )}
                    onClick={() =>
                      (parseInt(bookValue) >= backend.limitation.book ||
                        bookValue === "+") &&
                      handleChangeParams("book", bookValue)
                    }
                  >
                    <p>{bookValue}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.pricedetail)}>
          <div className={classNames(styles.pricedetailzone)}>
            <div className={classNames(styles.titleabo)}>
              <p>{t("newabo")}</p>
            </div>
            <div className={classNames(styles.packzone)}>
              <p className={classNames(styles.packzonetitle)}>Pack Pro</p>
              {Object.keys(KEYS).map((k) => {
                const key = k as "user" | "projects" | "book";
                return (
                  <div className={classNames(styles.oneoptiondetail)}>
                    <p className={classNames(styles.notimportant)}>
                      {t(`nb${key}`)} :
                    </p>
                    <p>
                      {paramsDiff[key].display === 0 ? (
                        backend.limitation[key] + " " + t(key)
                      ) : selectedParams[key].includes("+") ? (
                        "-"
                      ) : (
                        <>
                          {backend.limitation[key]}
                          <span className={classNames(styles.importanttext)}>
                            {" + " + paramsDiff[key].display} {t(key)}
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className={classNames(styles.periodezone)}>
              <p className={classNames(styles.notimportant)}>
                {t("periode")} :
              </p>
              <div className={classNames(styles.periodecompo)}>
                <p>{t("debut")}</p>
                <p>
                  {new Date(backend.limitation.startDate).toLocaleDateString(
                    i18n.language,
                    {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                    }
                  )}
                </p>
              </div>
              <div
                className={classNames(
                  styles.periodecompo,
                  styles.importanttext
                )}
              >
                <p>{t("Changement")} (Extension)</p>
                <p>
                  {new Date().toLocaleDateString(i18n.language, {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                  })}
                </p>
              </div>
              <div className={classNames(styles.periodecompo)}>
                <p>{t("End")}</p>
                <p>
                  {new Date(backend.limitation.endDate).toLocaleDateString(
                    i18n.language,
                    {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                    }
                  )}
                </p>
              </div>
            </div>
            {selectedPack === "pro" ? (
              <div className={classNames(styles.detailzone)}>
                <p className={classNames(styles.notimportant)}>
                  {t("Details")} :
                </p>
                {Object.keys(selectedParams).map((p) => {
                  const key = p as "user" | "projects" | "book";
                  return (
                    parseInt(selectedParams[key]) > backend.limitation[key] &&
                    paramsDiff[key].price > 0 && (
                      <div className={classNames(styles.onedetail)}>
                        <div className={classNames(styles.pricetext)}>
                          <p>{t(`ajout${key}`)}</p>
                          <p>
                            {paramsPrice[key].newPrice.toFixed(2)} € {t("HT")}
                          </p>
                        </div>
                      </div>
                    )
                  );
                })}
                {backend.limitation.packType === "starter" && (
                  <div className={classNames(styles.onedetail)}>
                    <div className={classNames(styles.pricetext)}>
                      <p>{t(`ajoutPro`)}</p>
                      <p>
                        {paramsPrice?.proPack.newPrice.toFixed(2)} € {t("HT")}
                      </p>
                    </div>
                  </div>
                )}

                {
                  <div
                    className={classNames(
                      styles.btnzone,
                      priceSum === 0 && styles.disabled
                    )}
                  >
                    <div
                      className={classNames(
                        styles.btncontent,
                        isLoading && styles.loadingDiv
                      )}
                      onClick={() => !isLoading && handleSendCommand()}
                    >
                      {isLoading ? (
                        <span className={classNames(styles.loader)}></span>
                      ) : (
                        <p>{t("Commander")}</p>
                      )}
                    </div>
                    <p className={classNames(styles.commandertext)}>
                      {t("Commandertext")}
                    </p>
                  </div>
                }
              </div>
            ) : (
              <div className={classNames(styles.detailzone)}>
                <a
                  href={
                    i18n.language === "fr"
                      ? "https://shop.mgi-labs.com/fr/nous-contacter"
                      : "https://shop.mgi-labs.com/en/contact-us"
                  }
                  target="_blank"
                  rel="noreferrer"
                  className={classNames(styles.contactUs)}
                >
                  <div className={classNames(styles.btnzone)}>
                    <p></p>
                    <p>{t("contactus")}</p>
                    <p></p>
                  </div>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Extensions;
