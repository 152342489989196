import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UseGroup } from "../hooks/UseGroup";
import { UserRepresentation } from "../types";
import alertimg from "../images/icon/alertimg.svg";
import styles from "./styles/members.module.scss";
import WorkteamUser from "./WorkteamUser";
import { useTranslation } from "react-i18next";
import { useBackend } from "../backend/BackendProvider";
import { useUser } from "@mgi-labs/mgi-id";
import Adduser from "./adduser";
import { toast } from "react-toastify";
import copy from "../images/icon/copy.svg";
import { useNavigate } from "react-router-dom";

const Members = () => {
  const { t } = useTranslation();
  const { data: groupUsers, error, mutate: mutateGroup } = UseGroup();

  // Needed later states
  const [isVisibleAddUser, setIsVisibleAddUser] = useState(false);
  const handleToggleAddUser = useCallback(() => {
    setIsVisibleAddUser((isVisibleAddUser) => !isVisibleAddUser);
  }, []);

  async function handleDeleteUser(id: string[]) {
    if (window.confirm(t("confirm_delete"))) {
      try {
        await backend.connexion.deleteUser(id);
      } finally {
        mutateGroup();
      }
    }
  }

  const profile = useUser();

  const backend = useBackend();
  const navigate = useNavigate();

  const handleCopyLink = useCallback(() => {
    toast.success(t<string>("copied"));
    navigator.clipboard.writeText(profile?.defaultTeam?.id ?? "");
  }, [profile?.defaultTeam?.id, t]);

  const [selectedUser, setSelectedUser] = useState<string | undefined>(
    groupUsers?.[0].id
  );

  useEffect(() => {
    !selectedUser && setSelectedUser(groupUsers?.[0].id);
  }, [groupUsers, selectedUser]);

  const selectedUserInfos = useMemo(
    () => groupUsers?.find((g) => g.id === selectedUser),
    [groupUsers, selectedUser]
  );

  useEffect(() => {
    if (
      !(
        backend.limitation.packType === "no" ||
        backend.limitation.packType === "beta"
      )
    ) {
      navigate("/");
    }
  }, [backend.limitation.packType, navigate]);

  return (
    <>
      {isVisibleAddUser && (
        <Adduser
          isVisibleAdduser={isVisibleAddUser}
          handleToggleVisibleAdduser={handleToggleAddUser}
          setVisibleAddUser={setIsVisibleAddUser}
        />
      )}

      <div className={classNames(styles.Container)}>
        {error ? (
          <div className={classNames(styles.faildata, "col-3")}>
            <div>
              <img src={alertimg} alt="Alert" />
              <p>Failed to load data</p>
            </div>
          </div>
        ) : (
          <div
            className={classNames(
              styles.tablecontainer,
              styles.ht3,
              "col-3 row"
            )}
          >
            <div
              className={classNames(
                styles.Tbl_Gestion_User,

                "col-12"
              )}
            >
              <div
                className={classNames(
                  styles.Allproject_projects_section_sort,
                  "row"
                )}
              >
                <div className={classNames(styles.Allproject_padding)}>
                  <div
                    className={classNames(
                      styles.Allproject_projects_sort,
                      styles.titlewidth
                    )}
                  >
                    {t("Membres")}
                  </div>
                </div>
              </div>
              <div
                id="scrollableTBody"
                className={classNames(
                  styles.Allproject_projects_content,
                  groupUsers &&
                    backend.limitation.user > groupUsers?.length &&
                    styles.Allproject_projects_content_no_button,
                  "row"
                )}
              >
                {Array.isArray(groupUsers)
                  ? groupUsers.map((user: UserRepresentation, i: number) => (
                      <WorkteamUser
                        user={user}
                        key={i}
                        handleDeleteUser={handleDeleteUser}
                        setSelectedUser={setSelectedUser}
                        selectedUser={selectedUser}
                        isMemberPage
                      />
                    ))
                  : null}
              </div>
              {Array.isArray(groupUsers) && (
                <div
                  className={classNames(styles.adduserbtn)}
                  onClick={() => setIsVisibleAddUser(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29.089"
                    height="29.089"
                    viewBox="0 0 29.089 29.089"
                  >
                    <path
                      id="Tracé_94"
                      data-name="Tracé 94"
                      d="M17.724,9.147H14.865v5.718H9.147v2.859h5.718v5.718h2.859V17.724h5.718V14.865H17.724ZM16.295,2A14.295,14.295,0,1,0,30.589,16.295,14.3,14.3,0,0,0,16.295,2Zm0,25.73A11.436,11.436,0,1,1,27.73,16.295,11.451,11.451,0,0,1,16.295,27.73Z"
                      transform="translate(-1.75 -1.75)"
                      fill="#fff"
                    />
                  </svg>
                  <p>{t("Add_user")}</p>
                </div>
              )}
            </div>
          </div>
        )}
        {profile ? (
          <div className={classNames(styles.container, "col-9 row")}>
            <div className={classNames(styles.titleprinc)}></div>
            <div className={classNames("col-12 row")}>
              <div
                className={classNames(
                  styles.firstcontent,
                  "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row"
                )}
              >
                <div
                  className={classNames(
                    styles.onecontent,
                    "col-12 col-sm-12 col-md-6 col-lg-6 row"
                  )}
                >
                  <div className={classNames(styles.oneform)}>
                    <label htmlFor="nom">{t("Name")}&nbsp;</label>
                    <input
                      disabled
                      id="nom"
                      type="text"
                      placeholder="Votre Nom"
                      value={selectedUserInfos?.lastName ?? ""}
                      className={classNames(styles.disabledInput)}
                    />
                  </div>

                  <div className={classNames(styles.oneform)}>
                    <label htmlFor="nom">{t("Email")}&nbsp;</label>
                    <input
                      disabled
                      id="nom"
                      type="mail"
                      placeholder="exemple@mail.com"
                      value={selectedUserInfos?.email ?? ""}
                      className={classNames(styles.disabledInput)}
                    />
                  </div>

                  <div className={classNames(styles.oneform)}>
                    <label htmlFor="nom">{t("Address")}&nbsp;</label>
                    <input
                      disabled
                      id="nom"
                      type="text"
                      placeholder={t("Address")}
                      value={
                        selectedUserInfos?.attributes?.[
                          "client_address"
                        ]?.[0] ?? ""
                      }
                      className={classNames(styles.disabledInput)}
                    />
                  </div>

                  <div className={classNames(styles.oneform)}>
                    <label htmlFor="cp">{t("CP")}&nbsp;</label>
                    <input
                      disabled
                      id="cp"
                      type="text"
                      placeholder={t("CP")}
                      value={
                        selectedUserInfos?.attributes?.["zipcode"]?.[0] ?? ""
                      }
                      className={classNames(styles.disabledInput)}
                    />
                  </div>

                  <div className={classNames(styles.oneform)}>
                    <label htmlFor="nom">{t("Country")}&nbsp;</label>
                    <input
                      disabled
                      id="nom"
                      type="text"
                      value={
                        selectedUserInfos?.attributes?.["country"]?.[0] ?? ""
                      }
                      placeholder={t("Country")}
                      className={classNames(styles.disabledInput)}
                    />
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.onecontent,
                    "col-12 col-sm-12 col-md-6 col-lg-6 row"
                  )}
                >
                  <div className={classNames(styles.oneform)}>
                    <label htmlFor="prenom">{t("First_name")}&nbsp;</label>
                    <input
                      disabled
                      id="prenom"
                      type="text"
                      placeholder="Votre Prénom"
                      value={selectedUserInfos?.firstName ?? "" ?? ""}
                      className={classNames(styles.disabledInput)}
                    />
                  </div>

                  <div className={classNames(styles.oneform)}>
                    <label htmlFor="nom">{t("Company")}&nbsp;</label>
                    <input
                      disabled
                      id="nom"
                      type="text"
                      placeholder={t("Company")}
                      value={
                        selectedUserInfos?.attributes?.["company"]?.[0] ?? ""
                      }
                      className={classNames(styles.disabledInput)}
                    />
                  </div>
                  <div className={classNames(styles.oneform)}>
                    <label htmlFor="nom">{t("company_code")}&nbsp;</label>
                    <div className={classNames(styles.companyCodeDiv)}>
                      <input
                        disabled
                        id="nom"
                        type="text"
                        placeholder={t("company_code")}
                        value={profile?.defaultTeam?.id ?? ""}
                        className={classNames(styles.companyCodeFont)}
                      />
                      <span
                        className={classNames(styles.icon)}
                        onClick={handleCopyLink}
                      >
                        <img src={copy} alt="copy"></img>
                      </span>
                    </div>
                  </div>

                  <div className={classNames(styles.oneform)}>
                    <label htmlFor="nom">{t("telephone")}&nbsp;</label>
                    <input
                      disabled
                      id="tel"
                      type="tel"
                      placeholder="+33 X XX XX XX XX"
                      value={
                        selectedUserInfos?.attributes?.["phone"]?.[0] ?? ""
                      }
                      className={classNames(styles.disabledInput)}
                    />
                  </div>

                  <div className={classNames(styles.oneform)}>
                    <label htmlFor="nom">{t("telephonemobile")}&nbsp;</label>
                    <input
                      disabled
                      id="tel"
                      type="tel"
                      placeholder="+33 X XX XX XX XX"
                      value={
                        selectedUserInfos?.attributes?.["mobile"]?.[0] ?? ""
                      }
                      className={classNames(styles.disabledInput)}
                    />
                  </div>

                  <div className={classNames(styles.bluetitle, styles.space)}>
                    <p> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Members;
