import React from "react";
import classNames from "classnames";

import styles from "./styles/ApproveProduct.module.scss";
import approve from "../images/approve.gif";

import approve_logo_alone from "../images/logo/Approve_logo_alone.svg";

import launch from "../images/icon/launch.svg";
import cloud from "../images/icon/cloud.svg";
import icon_3D from "../images/icon/_icon_3D.svg";
import icon_PDF from "../images/icon/_icon_PDF.svg";
import icon_puce from "../images/icon/_icon_puce.svg";
import icon_AR from "../images/icon/_icon_AI.svg";
import icon_times from "../images/icon/_icon_times.svg";
import icon_security from "../images/icon/_icon_security.svg";
import icon_protec from "../images/icon/_icon_protec.svg";
import icon_dashboard from "../images/icon/_icon_dashboard.svg";
import icon_intuitif from "../images/icon/_icon_intuitif.svg";
import icon_AI from "../images/icon/_icon_AI.svg";
import icon_confidential from "../images/icon/_icon_confidential.svg";
import { useTranslation } from "react-i18next";
import { ThemeType } from "./MgiConnectRouter";
import { isharrisbruno } from "./Domain/checkharrisbruno";

const DashboardProduct = ({ theme }: { theme: ThemeType }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={classNames(styles.boxwelcom, "col-12")}>
        <div className={classNames(styles.welcom, "col-12")}>
          <p>{t("Dashboard")}</p>

          <div className={classNames(styles.primary_btn)}>
            <p>{t("Tester")}</p>
          </div>
        </div>
      </div>
      <div className={classNames(styles.container)}>
        <div
          className={classNames(
            styles.padding,
            styles.ht2,
            styles.ht2sm,
            styles.float_left,
            styles.cursorpointer,
            "col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
          )}
        >
          <div className={classNames(styles.tile, styles.align)}>
            <a
              href="https://new-approve-landing-page-staging.osc-fr1.scalingo.io/"
              target="_blank"
              rel="noreferrer"
            >
              <div className={classNames(styles.light)}></div>
            </a>
            <div className={classNames(styles.topbar, "col-12")}>
              <img src={approve_logo_alone} alt="Logo approve" />
              <div className={classNames(styles.extopen, styles.DojaCat)}>
                <img src={launch} alt="Launch" />
              </div>
            </div>

            <img src={approve} alt="Video de approve" />

            <div
              className={classNames(styles.botbar, styles.discover, "col-12")}
            >
              <p>{t("Discover")}</p>

              <img
                className={classNames(styles.icon_3D)}
                src={icon_3D}
                alt="3D"
              />
            </div>
          </div>
        </div>

        {/* <div
        className={classNames(
          styles.padding,
          styles.ht1,
          styles.float_left,
          styles.ht0sm,

          "col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3"
        )}
      >
        <div className={classNames(styles.tile, styles.textcolor)}>
          <div className={classNames(styles.light)}>
            <Link to="/Workteam">
              <div className={classNames(styles.btn)}>
                <p>{t("AddUsers")}</p>
              </div>
            </Link>
          </div>

          <div className={classNames(styles.topbar, "col-12")}>
            <p>{t("Organisation")}</p>
          </div>
          <div className={classNames(styles.content, "col-12")}>
            <p>{t("Organisation_content")}</p>
          </div>
        </div>
      </div> */}
        <div
          className={classNames(
            styles.padding,
            styles.ht1,
            styles.float_left,
            styles.ht1sm,

            "col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3"
          )}
        >
          <div className={classNames(styles.tile, styles.textcolor)}>
            <div className={classNames(styles.light)}></div>

            <div className={classNames(styles.topbar, "col-12")}>
              <p className={classNames(styles.dashboardcolor)}>{t("Unique")}</p>
              <div className={classNames(styles.text_icon)}>
                <img
                  className={classNames(styles.icon_3D)}
                  src={icon_3D}
                  alt="3D"
                />
              </div>
            </div>
            <div className={classNames(styles.content, "col-12")}>
              <p>{t("Unique_content")}</p>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.padding,
            styles.ht1,
            styles.ht1sm,
            styles.float_left,

            "col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
          )}
        >
          <div className={classNames(styles.tile, styles.textcolor)}>
            <div
              className={classNames(
                styles.content,
                styles.center,
                styles.iconzone,
                "col-12"
              )}
            >
              <div className={classNames(styles.content_icon, "col-12")}>
                <ul>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.style_icon)}
                        src={icon_3D}
                        alt="3D"
                      />
                    </div>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.style_icon)}
                        src={icon_AR}
                        alt="AR"
                      />
                    </div>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.style_icon)}
                        src={icon_times}
                        alt="times"
                      />
                    </div>
                  </li>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.style_icon)}
                        src={icon_confidential}
                        alt="Secret"
                      />
                    </div>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.style_icon)}
                        src={icon_security}
                        alt="secur"
                      />
                    </div>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.style_icon)}
                        src={icon_dashboard}
                        alt="Dash"
                      />
                    </div>
                  </li>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.style_icon)}
                        src={icon_intuitif}
                        alt="TOP"
                      />
                    </div>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.style_icon)}
                        src={icon_PDF}
                        alt="PDF"
                      />
                    </div>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.style_icon)}
                        src={icon_AI}
                        alt="AI"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.padding,
            styles.ht1,
            styles.float_left,
            styles.ht1sm,

            "col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3"
          )}
        >
          <div className={classNames(styles.tile, styles.textcolor)}>
            <div className={classNames(styles.light)}></div>

            <div className={classNames(styles.topbar, "col-12")}>
              <p className={classNames(styles.dashboardcolor)}>
                {t("Intuitif")}
              </p>
              <div className={classNames(styles.text_icon)}>
                <img
                  className={classNames(styles.icon_intuitif)}
                  src={icon_intuitif}
                  alt="Top"
                />
              </div>
            </div>
            <div className={classNames(styles.content, "col-12")}>
              <p>{t("Intuitif_content")}</p>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.padding,
            styles.ht1,
            styles.float_left,
            styles.ht1sm,

            "col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3"
          )}
        >
          <div className={classNames(styles.tile, styles.textcolor)}>
            <div className={classNames(styles.light)}></div>

            <div className={classNames(styles.topbar, "col-12")}>
              <p className={classNames(styles.dashboardcolor)}>
                {t("Intuitif")}
              </p>
              <div className={classNames(styles.text_icon)}>
                <img
                  className={classNames(styles.icon_intuitif)}
                  src={icon_intuitif}
                  alt="Top"
                />
              </div>
            </div>
            <div className={classNames(styles.content, "col-12")}>
              <p>{t("Intuitif_content")}</p>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.padding,
            styles.ht2,
            styles.ht2sm,
            styles.float_left,
            styles.cursorpointer,
            "col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
          )}
        >
          <div className={classNames(styles.tile, styles.align)}>
            <a
              href="https://new-approve-landing-page-staging.osc-fr1.scalingo.io/"
              target="_blank"
              rel="noreferrer"
            >
              <div className={classNames(styles.light)}></div>
            </a>
            <div className={classNames(styles.topbar, "col-12")}>
              <img src={approve_logo_alone} alt="Logo approve" />
              <div className={classNames(styles.extopen, styles.DojaCat)}>
                <img src={launch} alt="Launch" />
              </div>
            </div>

            <img src={approve} alt="Video de approve" />

            <div className={classNames(styles.botbar, "col-12")}>
              <p>
                {t("New")}&nbsp;
                <span>Approve</span>&nbsp;
                {t("Click")}
              </p>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.padding,
            styles.ht2,
            styles.float_left,
            styles.ht2sm,
            "col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3"
          )}
        >
          <div className={classNames(styles.tile, styles.textcolor)}>
            <div className={classNames(styles.light)}>
              <a
                href="https://new-approve-landing-page-staging.osc-fr1.scalingo.io/#Abonnements"
                target="_blank"
                rel="noreferrer"
              >
                {/* <div className={classNames(styles.btn)}>
                <p>{t("More_informations")}</p>
              </div> */}
              </a>
            </div>
            <div className={classNames(styles.topbar, "col-12")}>
              <p className={classNames(styles.dashboardcolor)}>{t("Strong")}</p>
              <div className={classNames(styles.text_icon)}>
                <img
                  className={classNames(styles.icon_PDF)}
                  src={icon_PDF}
                  alt=">"
                />
              </div>
            </div>
            <div className={classNames(styles.content, "col-12")}>
              <p>{t("Strong_content")}</p>
              <div className={classNames(styles.list, "col-12")}>
                <ul>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("Vernis")}</p>
                  </li>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("Dorure")}</p>
                  </li>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("Impression")}</p>
                  </li>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("Traits")}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.padding,
            styles.ht2,
            styles.float_left,
            styles.ht2sm,

            "col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3"
          )}
        >
          <div className={classNames(styles.tile, styles.textcolor)}>
            <div className={classNames(styles.light)}></div>

            <div className={classNames(styles.topbar, "col-12")}>
              <p className={classNames(styles.dashboardcolor)}>
                {t("Marketing_digital")}
              </p>
              <div className={classNames(styles.text_icon)}>
                <img
                  className={classNames(styles.icon_dashboard)}
                  src={icon_dashboard}
                  alt="Dashboard"
                />
              </div>
            </div>
            <div className={classNames(styles.content, "col-12")}>
              <p>{t("Marketing_content")}</p>
              <div className={classNames(styles.list, "col-12")}>
                <ul>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("Créer")}</p>
                  </li>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("Mesurer")}</p>
                  </li>
                  <li>
                    <div className={classNames(styles.text_icon)}>
                      <img
                        className={classNames(styles.icon_puce)}
                        src={icon_puce}
                        alt=">"
                      />
                    </div>
                    <p>{t("Cibler")}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.padding,
            styles.ht2,
            styles.float_left,
            styles.ht2sm,

            "col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3"
          )}
        >
          <div className={classNames(styles.tile, styles.textcolor)}>
            <div className={classNames(styles.light)}></div>

            <div className={classNames(styles.topbar, "col-12")}>
              <p className={classNames(styles.dashboardcolor)}>
                {t("Sécurisé")}
              </p>
              <div className={classNames(styles.text_icon)}>
                <img
                  className={classNames(styles.icon_protec)}
                  src={icon_protec}
                  alt="3D"
                />
              </div>
            </div>
            <div className={classNames(styles.content, "col-12")}>
              <p>{t("Sécurisé_content")}</p>
              <div className={classNames(styles.line_icon, "col-12")}>
                <div className={classNames(styles.line_box, "col-4")}>
                  <div className={classNames(styles.line_intern_box, "col-12")}>
                    <img
                      className={classNames(styles.icon_protec)}
                      src={icon_protec}
                      alt="Shield"
                    />
                    <p>{t("Données_Protégées")}</p>
                  </div>
                </div>
                <div className={classNames(styles.line_box, "col-4")}>
                  <div className={classNames(styles.line_intern_box, "col-12")}>
                    <img
                      className={classNames(styles.icon_confidential)}
                      src={icon_confidential}
                      alt="Secret"
                    />
                    <p>{t("Données_Confidentielles")}</p>
                  </div>
                </div>
                <div className={classNames(styles.line_box, "col-4")}>
                  <div className={classNames(styles.line_intern_box, "col-12")}>
                    <img
                      className={classNames(styles.icon_security)}
                      src={icon_security}
                      alt="Secur"
                    />
                    <p>{t("Données_Cryptées")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.padding,
            styles.float_left,
            styles.ht1,
            styles.cursorpointer,

            "col-3 col-sm-6 col-md-6 col-lg-3 col-xl-3"
          )}
        >
          <a
            className={classNames(styles.no_blue)}
            href={
              process.env[
                isharrisbruno()
                  ? "REACT_APP_APPROVE_URL_HB"
                  : "REACT_APP_APPROVE_URL"
              ]
            }
            target="_blank"
            rel="noreferrer"
          >
            <div className={classNames(styles.tile)}>
              <div className={classNames(styles.light)}></div>
              <div className={classNames(styles.topbar, "col-12")}>
                <img src={approve_logo_alone} alt="Logo approve" />
                <div className={classNames(styles.text_icon)}>
                  <img
                    className={classNames(styles.cloud, styles.DojaCat)}
                    src={cloud}
                    alt="Cloud"
                  />
                </div>
              </div>
              <div className={classNames(styles.app_metrics)}>
                <div className={classNames(styles.onecontentzoneoee, "row")}>
                  <div className={classNames(styles.dashtrs, "row")}>
                    <div
                      className={classNames(
                        styles.numberzone,
                        styles.doublegraph
                      )}
                    >
                      <div className={classNames(styles.linedashzone)}>
                        <div className={classNames(styles.titleline)}>
                          <p>{t("number_projects")}</p>
                          <p>{t("month")}</p>
                        </div>
                        <div className={classNames(styles.linedash)}>
                          <div
                            style={{ width: "85%" }}
                            className={classNames(
                              styles.value,
                              styles.bluevalue
                            )}
                          ></div>
                          <div
                            style={{ width: "15%" }}
                            className={classNames(styles.background)}
                          ></div>
                        </div>
                        <p className={classNames(styles.linefont)}>
                          456 {t("projects_used")} 500
                        </p>
                      </div>
                      <div className={classNames(styles.linedashzone)}>
                        <div className={classNames(styles.titleline)}>
                          <p>{t("Storage_space")}</p>
                          <p>{t("Year")}</p>
                        </div>
                        <div className={classNames(styles.linedash)}>
                          <div
                            style={{ width: "71%" }}
                            className={classNames(
                              styles.value,
                              styles.bluevalue
                            )}
                          ></div>
                          <div
                            style={{ width: "28%" }}
                            className={classNames(styles.background)}
                          ></div>
                        </div>
                        <p className={classNames(styles.linefont)}>
                          4284 {t("projects_stored")} 6000
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div
          className={classNames(
            styles.padding,
            styles.float_left,
            styles.ht1,
            styles.cursorpointer,

            "col-3 col-sm-6 col-md-6 col-lg-3 col-xl-3"
          )}
        >
          <a
            className={classNames(styles.no_blue)}
            href="https://vb-backoffice-staging.osc-fr1.scalingo.io/contact"
            target="_blank"
            rel="noreferrer"
          >
            <div className={classNames(styles.tile)}>
              <div className={classNames(styles.light)}></div>
              <div className={classNames(styles.topbar, "col-12")}>
                <img src={approve_logo_alone} alt="Logo approve" />
                {/* <img src={catalog_logo_alone} alt="Logo approve" /> */}
                <div className={classNames(styles.text_icon, styles.DojaCat)}>
                  <img
                    className={classNames(styles.cloud)}
                    src={cloud}
                    alt="Cloud"
                  />
                </div>
              </div>
              <div className={classNames(styles.app_metrics)}>
                <div className={classNames(styles.onecontentzoneoee, "row")}>
                  <div className={classNames(styles.dashtrs, "row")}>
                    <div
                      className={classNames(
                        styles.numberzone,
                        styles.doublegraph
                      )}
                    >
                      <div className={classNames(styles.linedashzone)}>
                        <div className={classNames(styles.titleline)}>
                          <p>{t("Number")}</p>
                          <p>{t("month")}</p>
                        </div>
                        <div className={classNames(styles.linedash)}>
                          <div
                            style={{ width: "75%" }}
                            className={classNames(
                              styles.value,
                              styles.bluevalue
                              // styles.greenvalue
                            )}
                          ></div>
                          <div
                            style={{ width: "25%" }}
                            className={classNames(styles.background)}
                          ></div>
                        </div>
                        <p className={classNames(styles.linefont)}>
                          6 {t("books_used")} 8
                        </p>
                      </div>
                      <div className={classNames(styles.linedashzone)}>
                        <div className={classNames(styles.titleline)}>
                          <p>{t("campaigns")}</p>
                          <p>{t("month")}</p>
                        </div>
                        <div className={classNames(styles.linedash)}>
                          <div
                            style={{ width: "55%" }}
                            className={classNames(
                              styles.value,
                              styles.bluevalue
                              // styles.greenvalue
                            )}
                          ></div>
                          <div
                            style={{ width: "45%" }}
                            className={classNames(styles.background)}
                          ></div>
                        </div>
                        <p className={classNames(styles.linefont)}>
                          16 {t("campaigns_sent")} 30
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        {/* <TileDashboard oee={OEE_ALPHAJET} machineName="ALPHAJET" theme={theme} />
      <TileDashboard
        oee={OEE_JV3D_EVO}
        machineName="JetVarnish3D EVO"
        theme={theme}
      /> */}
        {/* Dashboard jetvarnish WEB et ONE si necessaire
      <TileDashboard oee={OEE_JV3D_WEB} machineName="JetVarnish3D WEB" />
      <TileDashboard oee={OEE_JV3D_ONE} machineName="JetVarnish3D ONE" /> */}

        <div
          className={classNames(
            styles.padding,
            styles.float_left,
            styles.heightbottom,
            "col-12"
          )}
        ></div>
      </div>
    </>
  );
};
export function chartcolors(value: number) {
  if (value > 0) {
    if (value < 50) {
      return "rgb(252, 62, 114)";
    } else if (value > 50 && value < 70) {
      return "rgb(252, 167, 62)";
    } else {
      return "rgb(13, 222, 126)";
    }
  }
}

export default DashboardProduct;
