import classNames from "classnames";
import styles from "./styles/Applications.module.scss";
import approve_logo from "../images/logo/Logo-Approve.svg";
import appshop from "../images/logo/ressources/appshop.svg";
import foilshop from "../images/logo/ressources/foilshop.svg";
import guidelines from "../images/logo/ressources/guidelines.svg";
import hbguidelines from "../images/logo/ressources/hbguidelines.svg";
import alphajetdoc from "../images/logo/ressources/alphajetdoc.svg";
import docapprove from "../images/logo/ressources/docapprove.svg";
import docdashboard from "../images/logo/ressources/docdashboard.svg";
import docpredict from "../images/logo/ressources/docpredict.svg";
import hbdocapprove from "../images/logo/ressources/hbdocapprove.svg";
import hbdocdashboard from "../images/logo/ressources/hbdocdashboard.svg";
import hbdocpredict from "../images/logo/ressources/hbdocpredict.svg";
import dashboard_logo from "../images/logo/Logo-Dashboard.svg";
import jetforce_logo from "../images/logo/Logo-Jetforce.svg";
import mgiroi from "../images/logo/tools/ROI.svg";
import mgiestimate from "../images/logo/tools/Estimate.svg";
import mgiregister from "../images/logo/tools/Logo-Register.svg";
import mgipredict from "../images/logo/tools/Logo-Predict.svg";
import mgitaggenerator from "../images/logo/tools/taggenerator.svg";
import { useTranslation } from "react-i18next";
import { useAuth, useUser } from "@mgi-labs/mgi-id";
import OneApp from "./OneApp";
import {
  AppsInfos,
  APPS_TYPE,
  getAvailableApps,
  getResourceApps,
  getUserApps,
} from "./Domain/AvailableApps";
import InternalApp from "./InternalApp";
import RessourcesApp from "./RessourcesApp";
import { isharrisbruno } from "./Domain/checkharrisbruno";

const Applications = () => {
  const { auth } = useAuth();

  const { t, i18n } = useTranslation();
  const user = useUser();
  const availableAppsForUser = getAvailableApps(user?.availableApps ?? []);
  const availableApps = availableAppsForUser.map((av) =>
    mapAppToObject(
      av,
      auth.authenticated,
      i18n.language.toUpperCase().includes("FR") ? "FR" : "EN"
    )
  );
  const resourcesApp = getResourceApps(user?.availableApps ?? []).map((av) =>
    mapAppToObject(
      av,
      auth.authenticated,
      i18n.language.toUpperCase().includes("FR") ? "FR" : "EN"
    )
  );

  const userApps = getUserApps(user?.availableApps ?? []);

  return (
    <>
      <div className={classNames(styles.container)}>
        {availableApps.length > 0 && (
          <div className={classNames(styles.boxwelcom, "col-12")}>
            <div className={classNames(styles.welcom, "col-12")}>
              <p>{t("App")}</p>
              <p>
                {userApps.length} {t("enabled")}
              </p>
            </div>
          </div>
        )}
        {userApps.map((a) => {
          const appObject = mapAppToObject(
            a,
            auth.authenticated,
            i18n.language.toUpperCase().includes("FR") ? "FR" : "EN"
          );
          return (
            <>
              <OneApp app={appObject} />
            </>
          );
        })}
        {availableApps.length > 0 && (
          <>
            <div className={classNames(styles.boxwelcom, "col-12")}>
              <div
                className={classNames(
                  styles.welcom,
                  styles.margintop,
                  "col-12"
                )}
              >
                <p>{t("Tools")}</p>

                <p>
                  {availableApps.length} {t("toolenabled")}
                </p>
              </div>
            </div>
            {availableApps.map((a, i) => {
              return <InternalApp key={i} app={a} />;
            })}
          </>
        )}
        {resourcesApp.length > 0 && (
          <>
            <div className={classNames(styles.boxwelcom, "col-12")}>
              <div
                className={classNames(
                  styles.welcom,
                  styles.margintop,
                  "col-12"
                )}
              >
                <p>Docs & Ressources</p>
                <p>
                  {resourcesApp.length} {t("ressourcesenabled")}
                </p>
              </div>
            </div>
            {resourcesApp.map((a, i) => {
              return <RessourcesApp key={i} app={a} />;
            })}
          </>
        )}
      </div>
    </>
  );
};

function mapAppToObject(
  app: APPS_TYPE,
  authenticated: boolean | undefined,
  language: string
): AppsInfos {
  // const { t } = useTranslation();
  switch (app) {
    case "approve":
      return {
        name: "Approve",
        logo: approve_logo,
        color: styles.approvecolor,
        link: authenticated
          ? process.env[
              isharrisbruno()
                ? "REACT_APP_APPROVE_URL_HB"
                : "REACT_APP_APPROVE_URL"
            ] ?? ""
          : "product-approve",
      };
    case "dashboard":
      return {
        name: "Dashboard",
        logo: dashboard_logo,
        color: styles.dashboardcolor,
        link: authenticated
          ? process.env[
              isharrisbruno()
                ? "REACT_APP_DASHBOARD_URL_HB"
                : "REACT_APP_DASHBOARD_URL"
            ] ?? ""
          : "product-dashboard",
      };
    case "jetforce":
      return {
        name: "Jet force",
        logo: jetforce_logo,
        color: styles.jetforcecolor,
        link: authenticated
          ? process.env["REACT_APP_JETFORCE_URL"] ?? ""
          : "product-jetforce",
      };
    case "roi":
      return {
        name: "Roi",
        logo: mgiroi,
        color: styles.jetforcecolor,
        link: process.env["REACT_APP_ROI_URL"] ?? "",
      };
    case "tag-generator":
      return {
        name: "Ink licence",
        logo: mgitaggenerator,
        color: styles.jetforcecolor,
        link: process.env["REACT_APP_INK_LICENCE_URL"] ?? "",
      };

    case "estimate":
      return {
        name: "Cost Calculator",
        logo: mgiestimate,
        color: styles.estimatecolor,
        link: process.env["REACT_APP_ESTIMATE_URL"] ?? "",
      };
    case "register":
      return {
        name: "Register",
        logo: mgiregister,
        color: styles.jetforcecolor,
        link: process.env["REACT_APP_REGISTER_URL"] ?? "",
      };
    case "predict":
      return {
        name: "Predict",
        logo: mgipredict,
        color: styles.jetforcecolor,
        link:
          process.env[
            isharrisbruno()
              ? "REACT_APP_PREDICT_URL_HB"
              : "REACT_APP_PREDICT_URL"
          ] ?? "",
      };
    case "appshop":
      return {
        name: "Applications Shop",
        logo: appshop,
        color: styles.jetforcecolor,
        link: process.env["REACT_APP_SHOP_APP_URL"] ?? "",
      };
    case "foilshop":
      return {
        name: "Foil Shop",
        logo: foilshop,
        color: styles.jetforcecolor,
        link: process.env["REACT_APP_SHOP_FOIL_URL"] ?? "",
      };
    case "alphajet-viewer":
      return {
        name: "PDF viewer",
        logo: alphajetdoc,
        color: styles.jetforcecolor,
        link: process.env["REACT_APP_ALPHAJET_VIEWER_URL"] ?? "",
      };
    case "docapprove":
      return {
        name: "Documentation",
        logo: isharrisbruno() ? hbdocapprove : docapprove,
        color: styles.jetforcecolor,
        link: isharrisbruno()
          ? `/User_Manual_Approve_${language.toUpperCase()}.pdf` ?? ""
          : `/User_Manual_MGI_Approve_${language.toUpperCase()}.pdf` ?? "",
      };
    case "docdashboard":
      return {
        name: "Documentation",
        logo: isharrisbruno() ? hbdocdashboard : docdashboard,
        color: styles.jetforcecolor,
        link: isharrisbruno()
          ? `/User_Manual_Dashboard_${language.toUpperCase()}.pdf` ?? ""
          : `/User_Manual_MGI_Dashboard_${language.toUpperCase()}.pdf` ?? "",
      };
    case "docpredict":
      return {
        name: "Documentation",
        logo: isharrisbruno() ? hbdocpredict : docpredict,
        color: styles.jetforcecolor,
        link: isharrisbruno()
          ? `/User_Manual_Predict_${language.toUpperCase()}.pdf` ?? ""
          : `/User_Manual_MGI_Predict_${language.toUpperCase()}.pdf` ?? "",
      };
    case "guidelines":
      return {
        name: "Guidelines",
        logo: isharrisbruno() ? hbguidelines : guidelines,
        color: styles.jetforcecolor,
        link: isharrisbruno()
          ? "https://guidelines.harris-bruno-connect.com/"
          : process.env["REACT_APP_GUIDELINES_URL"] ?? "",
      };
    default: {
      return {
        name: "App",
        logo: jetforce_logo,
        color: styles.jetforcecolor,
        link: "product-jetforce",
      };
    }
  }
}

export function chartcolors(value: number) {
  if (value > 0) {
    if (value < 50) {
      return "rgb(252, 62, 114)";
    } else if (value > 50 && value < 70) {
      return "rgb(252, 167, 62)";
    } else {
      return "rgb(13, 222, 126)";
    }
  }
}

export default Applications;
