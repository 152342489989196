import React from "react";
import classNames from "classnames";
import styles from "./styles/Informationmodal.module.scss";
import codeJetFrImg from "../images/screeen/FR-CodeJet.jpeg";
import codeJetFrImghb from "../images/screeen/FR-CodeJethb.png";
import codeJetEnImg from "../images/screeen/EN-CodeJet.png";
import codeJetEnImghb from "../images/screeen/EN-CodeJethb.png";
import serialnumberEN from "../images/screeen/EN-SerialNumber.jpg";
import serialnumberFR from "../images/screeen/FR-SerialNumber.jpg";
import serialnumberFRhb from "../images/screeen/FR-SerialNumberhb.png";
import serialnumberENhb from "../images/screeen/EN-SerialNumberhb.png";
import controlCodeFrImg from "../images/screeen/FR-CodeControl.jpeg";
import controlCodeFrImghb from "../images/screeen/FR-CodeControlhb.png";
import controlCodeEnImg from "../images/screeen/EN-CodeControl.png";
import controlCodeEnImghb from "../images/screeen/EN-CodeControlhb.png";
import { useTranslation } from "react-i18next";
import { i18n } from "i18next";
import { isharrisbruno } from "./Domain/checkharrisbruno";

const Informationmodal = ({
  setIsVisibleOpenInfos,
  isVisibleOpenInfos,
}: // theme,
{
  isVisibleOpenInfos: "jetCode" | "controlCode" | "serialnumber" | undefined;
  setIsVisibleOpenInfos: React.Dispatch<
    "jetCode" | "controlCode" | "serialnumber" | undefined
  >;
  // theme: "dark" | "light";
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={classNames(styles.informationmodal)}>
      <div
        className={classNames(styles.bkgmodal)}
        onClick={() => setIsVisibleOpenInfos(undefined)}
      ></div>
      <div className={classNames(styles.aboutscreenmodal)}>
        <div className={classNames(styles.closebtn)}>
          <svg
            onClick={() => setIsVisibleOpenInfos(undefined)}
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
          >
            <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
          </svg>
        </div>
        <p className={classNames(styles.textabout)}>
          {t("infostext")}
          <br />
          {t("infostext2")}
        </p>
        <img src={mapInfoToImg(isVisibleOpenInfos, i18n)} alt="About screen" />
      </div>
    </div>
  );
};

export default Informationmodal;

function mapInfoToImg(
  info: "jetCode" | "controlCode" | "serialnumber" | undefined,
  i18n: i18n
) {
  switch (info) {
    case "jetCode":
      return !isharrisbruno()
        ? i18n.language === "fr"
          ? codeJetFrImg
          : codeJetEnImg
        : i18n.language === "fr"
        ? codeJetFrImghb
        : codeJetEnImghb;
    case "controlCode":
      return !isharrisbruno()
        ? i18n.language === "fr"
          ? controlCodeFrImg
          : controlCodeEnImg
        : i18n.language === "fr"
        ? controlCodeFrImghb
        : controlCodeEnImghb;
    case "serialnumber":
      return !isharrisbruno()
        ? i18n.language === "fr"
          ? serialnumberFR
          : serialnumberEN
        : i18n.language === "fr"
        ? serialnumberFRhb
        : serialnumberENhb;
  }
}
