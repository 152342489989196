import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import styles from "./styles/support.module.scss";
import { useTranslation } from "react-i18next";
import { useUser } from "@mgi-labs/mgi-id";
import logoapprove from "../images/logo/Approve_logo2.svg";
import { useBackend } from "../backend/BackendProvider";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { isharrisbruno } from "./Domain/checkharrisbruno";

//TODO: Add apps for support here

const Support = () => {
  const { t } = useTranslation();

  const profile = useUser();
  const backend = useBackend();

  const to = useParams();

  const [email, setEmail] = useState<string | undefined>(profile?.email);
  const [firstName, setFirstName] = useState<string | undefined>(
    profile?.firstName
  );
  const [lastName, setLastName] = useState<string | undefined>(
    profile?.lastName
  );
  const [company, setCompany] = useState<string | undefined>(profile?.company);

  const [isCommercial, setIsCommercial] = useState<boolean>(
    to.to === "commercial"
  );
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const sendSupport = useCallback(
    async (e: SyntheticEvent | KeyboardEvent) => {
      if (
        email &&
        textAreaRef.current?.value !== undefined &&
        textAreaRef.current.value !== ""
      ) {
        e.preventDefault();
        try {
          await backend.connexion.sendSupport(
            firstName ?? "",
            lastName ?? "",
            email,
            isCommercial ? "Commercial" : "Approve",
            textAreaRef.current.value,
            isharrisbruno() ? "HB" : "MGI"
          );
          toast.success(t("support_success"));
        } catch {
          toast.error(t("support_error"));
        } finally {
          textAreaRef.current.value = "";
        }
      }
    },
    [backend.connexion, email, firstName, isCommercial, lastName, t]
  );

  useEffect(() => {
    if (profile) {
      setEmail(profile?.email ?? "");
      setFirstName(profile?.firstName ?? "");
      setLastName(profile?.lastName ?? "");
      setCompany(profile?.company ?? "");
    }
  }, [profile, profile?.email, profile?.firstName, profile?.lastName]);

  const soumettre = t("Soumettre");

  return (
    <div className={classNames(styles.container)}>
      <div
        className={classNames("col-12 col-sm-12 col-md-12", styles.wrapperzone)}
      >
        <div className={classNames(styles.wrapper)}>
          <input
            checked={!isCommercial}
            type="radio"
            name="select"
            id="supportbtn"
            className={classNames(styles.supportbtn)}
            onChange={() => setIsCommercial(!isCommercial)}
          />
          <input
            checked={isCommercial}
            type="radio"
            name="select"
            id="commercialbtn"
            className={classNames(styles.commercialbtn)}
            onChange={() => setIsCommercial(!isCommercial)}
          />
          <label
            htmlFor="supportbtn"
            className={classNames(styles.option, styles.option1)}
          >
            <div className={classNames(styles.dot)}></div>
            <span>{t("assistanceutilisateurs")}</span>
          </label>
          <label
            htmlFor="commercialbtn"
            className={classNames(styles.option, styles.option2)}
          >
            <div className={classNames(styles.dot)}></div>
            <span>{t("suport_commercial_title")}</span>
          </label>
        </div>
      </div>
      <div className={classNames(styles.titleprinc)}>
        <h2>{t(isCommercial ? "suport_commercial_title" : "suport_title")}</h2>
      </div>
      <div className={classNames("col-12 row")}>
        <form onSubmit={sendSupport}>
          <div
            className={classNames(
              styles.firstcontent,
              "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row"
            )}
          >
            <div
              className={classNames(
                styles.onecontentzone,
                "col-12 col-sm-12 col-md-6 col-lg-6 row"
              )}
            >
              <div
                className={classNames("col-12 col-sm-12 col-md-12 col-lg-12")}
              >
                <div
                  className={classNames(styles.bluetitle, styles.paddingtitle)}
                >
                  <p>{t("indentifiervous")}</p>
                </div>
              </div>
              <div
                className={classNames(
                  styles.onecontent,
                  "col-12 col-sm-12 col-md-12 col-lg-12 row"
                )}
              >
                <div
                  className={classNames(
                    styles.oneform,
                    "col-12 col-sm-12 col-md-6 col-lg-6 row"
                  )}
                >
                  <label htmlFor="nom">{t("Email")}</label>
                  <input
                    id="nom"
                    type="text"
                    placeholder="exemple@mail.com"
                    value={email}
                    onChange={(e) => !profile && setEmail(e.target.value)}
                    required
                  />
                </div>

                <div
                  className={classNames(
                    styles.oneform,
                    "col-12 col-sm-12 col-md-6 col-lg-6 row"
                  )}
                >
                  <label htmlFor="nom">{t("Entreprise")}</label>
                  <input
                    id="nom"
                    type="text"
                    placeholder="Company"
                    value={company}
                    onChange={(e) => !profile && setCompany(e.target.value)}
                  />
                </div>
                <div
                  className={classNames(
                    styles.oneform,
                    "col-12 col-sm-12 col-md-6 col-lg-6 row"
                  )}
                >
                  <label htmlFor="nom">{t("lastName")}</label>
                  <input
                    id="nom"
                    type="mail"
                    placeholder="Name"
                    value={lastName}
                    onChange={(e) => !profile && setLastName(e.target.value)}
                  />
                </div>
                <div
                  className={classNames(
                    styles.oneform,
                    "col-12 col-sm-12 col-md-6 col-lg-6 row"
                  )}
                >
                  <label htmlFor="prenom">{t("First_name")}</label>
                  <input
                    id="nom"
                    type="mail"
                    placeholder="Prénom"
                    value={firstName}
                    onChange={(e) => !profile && setFirstName(e.target.value)}
                  />
                </div>
              </div>
              {!isCommercial && APPS.length > 0 && (
                <>
                  <div
                    className={classNames(
                      "col-12 col-sm-12 col-md-12 col-lg-12"
                    )}
                  >
                    <div
                      className={classNames(
                        styles.bluetitle,
                        styles.paddingtitle
                      )}
                    >
                      <p>{t("problemerencontre")}</p>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      styles.onecontent,
                      "col-12 col-sm-12 col-md-12 col-lg-12 row"
                    )}
                  >
                    <ul
                      className={classNames(
                        styles.listapps,
                        "col-12 col-sm-12 col-md-12 col-lg-12 row"
                      )}
                    >
                      {APPS.map((app) => (
                        <li
                          className={classNames(
                            styles.selected,
                            "col-12 col-sm-12 col-md-6 col-lg-6 row"
                          )}
                        >
                          <div>
                            <img src={mapAppToLogo(app)} alt="Logo Approve" />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>

            <div
              className={classNames(
                styles.contenttextarea,
                "col-12 col-sm-12 col-md-6 col-lg-6 row"
              )}
            >
              <label htmlFor="textarea">
                {t(isCommercial ? "decrivezprob_commercial" : "decrivezprob")}
              </label>
              <textarea
                name="textarea"
                id="textarea"
                required
                ref={textAreaRef}
              ></textarea>
              <input
                type="submit"
                value={soumettre}
                className={classNames(styles.soumettre)}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const APPS: string[] = [];

function mapAppToLogo(app: string) {
  switch (app) {
    case "approve":
      return logoapprove;
  }
}

export default Support;
