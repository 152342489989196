import React, { useState } from "react";
import classNames from "classnames";
import styles from "./styles/homepage.module.scss";
import approve_logo_alone from "../images/logo/Logo-Approve.svg";
import dashboard_logo_alone from "../images/logo/Logo-Dashboard.svg";
import jetforce_logo_alone from "../images/logo/Logo-Jetforce.svg";
// import mgi_logo_alone from "../images/logo/Logo-Mgi.svg";
import connect_logo_alone from "../images/logo/Logo-Connect.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuth, useUser } from "@mgi-labs/mgi-id";
import { ThemeType } from "./MgiConnectRouter";
import { getUserApps } from "./Domain/AvailableApps";
import { useBackend } from "../backend/BackendProvider";
import Addequipmentdesktop from "./Addequipmentdesktop";
import { useAllMachines } from "../hooks/useAllMachines";
import { isharrisbruno } from "./Domain/checkharrisbruno";

const Homepage = ({ theme }: { theme: ThemeType }) => {
  const { t, i18n } = useTranslation();
  const { mutate } = useAllMachines();

  const [isVisibleEquipment, setIsVisibleEquipment] = useState<boolean>(false);

  // const currentUser = useUser();

  const { auth } = useAuth();
  const user = useUser();
  const userApps = getUserApps(user?.availableApps ?? []);

  const backend = useBackend();

  const realPack =
    backend.limitation.packType !== "no" &&
    backend.limitation.packType !== "beta";
  // TODO: Use this code later, for now everyone can access for demo purposes
  // const availableApps =
  //   typeof currentUser === "object" ? currentUser.availableApps : undefined;

  // TODO: Remove this code and use code above after beta is over
  // const availableApps = ["approve", "catalog", "roi", "dashboard", "jetforce"];

  return (
    <>
      <div className={classNames(styles.container)}>
        {/* non connecté */}

        <div
          data-theme={"dark"}
          className={classNames(
            styles.padding,
            styles.ht2,
            styles.ht2sm,
            styles.float_left,
            // styles.cursorpointer,
            "col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
          )}
        >
          <div
            className={classNames(
              styles.tile,
              styles.align,
              styles.blueimgbackground
            )}
          >
            <div className={classNames(styles.light)}></div>
            <div className={classNames(styles.topbardecouvrir, "col-12")}>
              <img src={approve_logo_alone} alt="Logo approve" />

              <p className={classNames(styles.logotext)}>APPROVE</p>
            </div>
            <div className={classNames(styles.contentdecouvrir, "col-12")}>
              {/* <p>Donnez plus de relief à vos projets, avec MGI Approve</p> */}
              {/* <img src={ecran} alt="screen" /> */}
            </div>
            {/* <img src={approve} alt="Video de approve" /> */}

            <div className={classNames(styles.botbardecouvrir, "col-12")}>
              <div
                className={classNames(
                  styles.btn,
                  styles.positionrelativebtn,
                  isharrisbruno()
                    ? !userApps.includes("approve")
                      ? styles.harrisbrunoright
                      : ""
                    : ""
                )}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    isharrisbruno()
                      ? "https://www.harris-bruno.com/"
                      : i18n.language === "en"
                      ? "https://mgi-fr.com/en/mgi-connect-us/#approve"
                      : "https://mgi-fr.com/mgi-connect/#approve"
                  }
                >
                  {!userApps.includes("approve") ? (
                    <p>{t("Decouvrir")}</p>
                  ) : (
                    <p>Informations</p>
                  )}
                </a>
              </div>
              <div className={classNames(styles.btngroup)}>
                {userApps.includes("approve") && realPack ? (
                  isharrisbruno() ? (
                    ""
                  ) : (
                    //A remettre plus tard lorsuqu'on voudra refaire apparaitre les extentions
                    // <div
                    //   className={classNames(
                    //     styles.btn,
                    //     styles.positionrelativebtn
                    //   )}
                    // >
                    //   <NavLink rel="noreferrer" to="/extensions">
                    //     <p>{t("Extensions")}</p>
                    //   </NavLink>
                    // </div>
                    <div></div>
                  )
                ) : isharrisbruno() ? (
                  ""
                ) : (
                  <div
                    className={classNames(
                      styles.btn,
                      styles.positionrelativebtn
                    )}
                  >
                    <a
                      rel="noreferrer"
                      href={`https://shop.mgi-labs.com/${i18n.language}/11-packs`}
                    >
                      <p>{t("Abonner")}</p>
                    </a>
                  </div>
                )}
                {!auth.authenticated ? (
                  <></>
                ) : (
                  <div
                    className={classNames(
                      styles.btn,
                      styles.positionrelativebtn,
                      styles.marginleft
                    )}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        process.env[
                          isharrisbruno()
                            ? "REACT_APP_APPROVE_URL_HB"
                            : "REACT_APP_APPROVE_URL"
                        ]
                      }
                    >
                      <p>
                        {t(userApps.includes("approve") ? "Ouvrir" : "Tester")}
                      </p>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {isharrisbruno() ? (
          ""
        ) : (
          <div
            className={classNames(
              styles.padding,
              styles.ht2,
              styles.ht2sm,
              styles.float_left,

              "col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
            )}
          >
            <div
              className={classNames(
                styles.tile,
                styles.align,
                styles.greybackground
              )}
            >
              <a
                href={`https://shop.mgi-labs.com/${i18n.language}/11-packs`}
                target="_blank"
                rel="noreferrer"
              >
                <div className={classNames(styles.light)}></div>
              </a>

              <div
                className={classNames(
                  styles.topbardecouvrir,

                  "col-12"
                )}
              >
                <img src={connect_logo_alone} alt="Logo Connect" />
                <p
                  className={classNames(
                    styles.logotext,
                    styles.textaligncenter
                  )}
                >
                  Approve - Dahsboard - Jetforce
                </p>
                <p className={classNames(styles.logotext)}>PACK CONNECT</p>
              </div>
              <div
                className={classNames(styles.contentdecouvrirpack, "col-12")}
              >
                <div>
                  <div className={classNames(styles.contentdecouvrirpacklogo)}>
                    <div className={classNames(styles.onelogo)}>
                      <img src={approve_logo_alone} alt="Logo approve" />
                      <p>Approve</p>
                    </div>
                    <div>x</div>
                    <div className={classNames(styles.onelogo)}>
                      <img src={dashboard_logo_alone} alt="Logo Dashboard" />
                      <p>Dashboard</p>
                    </div>
                    <div>x</div>
                    <div className={classNames(styles.onelogo)}>
                      <img src={jetforce_logo_alone} alt="Logo Jetforce" />
                      <p>JetForce</p>
                    </div>
                  </div>
                  <div className={classNames(styles.textzone)}>
                    <p>
                      {t("packhometext")} <br /> {t("packhometext2")}
                    </p>
                  </div>
                </div>
              </div>

              <div className={classNames(styles.botbardecouvrir, "col-12")}>
                <div
                  className={classNames(styles.btn, styles.positionrelativebtn)}
                >
                  {!userApps.includes("approve") &&
                  !userApps.includes("dashboard") ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://mgi-fr.com/mgi-connect/`}
                    >
                      <p>{t("Decouvrir")}</p>
                    </a>
                  ) : (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://mgi-fr.com/mgi-connect/`}
                    >
                      <p>Informations</p>
                    </a>
                  )}
                </div>
                <div className={classNames(styles.btngroup)}>
                  {userApps.includes("approve") &&
                  userApps.includes("dashboard") &&
                  realPack ? (
                    //A remettre plus tard lorsuqu'on voudra refaire apparaitre les extentions
                    // <div
                    //   className={classNames(
                    //     styles.btn,
                    //     styles.positionrelativebtn
                    //   )}
                    // >
                    //   <NavLink
                    //     target="_blank"
                    //     rel="noreferrer"
                    //     to={"/extensions"}
                    //   >
                    //     <p>{t("Extensions")}</p>
                    //   </NavLink>
                    // </div>
                    <div></div>
                  ) : (
                    <div
                      className={classNames(
                        styles.btn,
                        styles.positionrelativebtn
                      )}
                    >
                      <a
                        rel="noreferrer"
                        href={`https://shop.mgi-labs.com/${i18n.language}/11-packs`}
                      >
                        <p>{t("Abonner")}</p>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          data-theme={"dark"}
          className={classNames(
            styles.padding,
            isharrisbruno() ? styles.ht2 : styles.ht1,
            isharrisbruno() ? styles.ht2sm : styles.ht1sm,
            styles.float_left,
            isharrisbruno()
              ? "col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
              : "col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
          )}
        >
          <div
            className={classNames(
              styles.tile,
              styles.align,
              styles.orangeimgbackground
            )}
          >
            <div className={classNames(styles.light)}></div>

            <div className={classNames(styles.topbardecouvrir, "col-12")}>
              <img src={dashboard_logo_alone} alt="Logo approve" />

              <p className={classNames(styles.logotext)}>DASHBOARD</p>
            </div>
            <div className={classNames(styles.contentdecouvrir, "col-12")}>
              {/* <p>Donnez plus de relief à vos projets, avec MGI Dashboard</p>
            <img src={ecran} alt="screen" /> */}
            </div>
            {/* <img src={approve} alt="Video de approve" /> */}

            <div className={classNames(styles.botbardecouvrir, "col-12")}>
              {!userApps.includes("dashboard") && (
                <>
                  <div></div>
                  <div
                    className={classNames(
                      styles.btn,
                      styles.positionrelativebtn,
                      isharrisbruno() ? styles.harrisbrunoright : ""
                    )}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        isharrisbruno()
                          ? "https://www.harris-bruno.com/"
                          : i18n.language === "en"
                          ? "https://mgi-fr.com/en/mgi-connect-us/#dashboard"
                          : "https://mgi-fr.com/mgi-connect/#dashboard"
                      }
                    >
                      <p>{t("Decouvrir")}</p>
                    </a>
                  </div>
                </>
              )}
              {realPack ? (
                isharrisbruno() ? (
                  userApps.includes("dashboard") ? (
                    <div
                      className={classNames(
                        styles.btn,
                        styles.positionrelativebtn
                      )}
                    >
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={"https://www.harris-bruno.com/"}
                      >
                        <p>Informations</p>
                      </a>
                    </div>
                  ) : (
                    ""
                  )
                ) : userApps.includes("dashboard") ? (
                  //A remettre plus tard lorsuqu'on voudra refaire apparaitre les extentions
                  // <div
                  //   className={classNames(
                  //     styles.btn,
                  //     styles.positionrelativebtn
                  //   )}
                  // >
                  //   <NavLink
                  //     target="_blank"
                  //     rel="noreferrer"
                  //     to={"/extensions"}
                  //   >
                  //     <p>{t("Extensions")}</p>
                  //   </NavLink>
                  // </div>
                  <div></div>
                ) : (
                  ""
                )
              ) : isharrisbruno() ? (
                ""
              ) : (
                <div
                  className={classNames(styles.btn, styles.positionrelativebtn)}
                >
                  <a
                    rel="noreferrer"
                    href={`https://shop.mgi-labs.com/${i18n.language}/11-packs`}
                  >
                    <p>{t("Abonner")}</p>
                  </a>
                </div>
              )}

              {auth.authenticated && (
                <div className={classNames(styles.btngroup)}>
                  <div
                    className={classNames(
                      styles.btn,
                      styles.positionrelativebtn
                    )}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        process.env[
                          isharrisbruno()
                            ? "REACT_APP_DASHBOARD_URL_HB"
                            : "REACT_APP_DASHBOARD_URL"
                        ]
                      }
                    >
                      <p>
                        {t(
                          userApps.includes("dashboard") ? "Ouvrir" : "Tester"
                        )}
                      </p>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {isharrisbruno() ? (
          ""
        ) : (
          <div
            data-theme={"dark"}
            className={classNames(
              styles.padding,
              styles.ht1,
              styles.ht1sm,
              styles.float_left,

              "col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
            )}
          >
            <div
              className={classNames(
                styles.tile,
                styles.align,
                styles.greenimgbackground
              )}
            >
              <a
                href={process.env.REACT_APP_JETFORCE_URL}
                target="_blank"
                rel="noreferrer"
              >
                <div className={classNames(styles.light)}></div>
              </a>
              <div className={classNames(styles.topbardecouvrir, "col-12")}>
                <img src={jetforce_logo_alone} alt="Logo Jetforce" />

                <p className={classNames(styles.logotext)}>JETFORCE</p>
              </div>
              <div className={classNames(styles.contentdecouvrir, "col-12")}>
                {/* <p>
              Donnez plus de relief <br /> à vos projets, avec MGI Approve
            </p>
            <img src={ecran} alt="screen" /> */}
              </div>
              {/* <img src={approve} alt="Video de approve" /> */}

              <div className={classNames(styles.botbardecouvrir, "col-12")}>
                {auth.authenticated ? (
                  <div></div>
                ) : (
                  <>
                    <div></div>
                    <div
                      className={classNames(
                        styles.btn,
                        styles.positionrelativebtn
                      )}
                    >
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://thejetforce.org/`}
                      >
                        <p>{t("Decouvrir")}</p>
                      </a>
                    </div>
                  </>
                )}

                {auth.authenticated && (
                  <div className={classNames(styles.btngroup)}>
                    <div
                      className={classNames(
                        styles.btn,
                        styles.positionrelativebtn
                      )}
                    >
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://thejetforce.org/`}
                      >
                        <p>{t("Ouvrir")}</p>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {/* SECTION CONNECT */}

        {/* <div
        className={classNames(
          styles.float_left,

          "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        )}
      >
        <div className={classNames(styles.sectiontitlezone, "col-12")}>
          <p></p>
        </div>
      </div> */}

        {/* <a
      className={classNames(styles.no_blue)}
      href="https://vb-backoffice-staging.osc-fr1.scalingo.io/contact"
      target="_blank"
      rel="noreferrer"
    >
      <div className={classNames(styles.tile)}>
        <div className={classNames(styles.light)}></div>
        <div className={classNames(styles.topbar, "col-12")}>
         
          <p className={classNames(styles.logotext)}>{t("Catalogue")}</p>
          <div className={classNames(styles.text_icon, styles.DojaCat)}>
            <img
              className={classNames(styles.cloud)}
              src={cloud}
              alt="Cloud"
            />
          </div>
        </div>
        <div className={classNames(styles.app_metrics)}>
          <div className={classNames(styles.onecontentzoneoee, "row")}>
            <div className={classNames(styles.dashtrs, "row")}>
              <div
                className={classNames(
                  styles.numberzone,
                  styles.doublegraph
                )}
              >
                <div className={classNames(styles.linedashzone)}>
                  <div className={classNames(styles.titleline)}>
                    <p>{t("Number")}</p>
                    <p>{t("month")}</p>
                  </div>
                  <div className={classNames(styles.linedash)}>
                    <div
                      style={{ width: "75%" }}
                      className={classNames(
                        styles.value,
                        styles.greenvalue
                      )}
                    ></div>
                    <div
                      style={{ width: "25%" }}
                      className={classNames(styles.background)}
                    ></div>
                  </div>
                  <p className={classNames(styles.linefont)}>
                    6 {t("books_used")} 8
                  </p>
                </div>
                <div className={classNames(styles.linedashzone)}>
                  <div className={classNames(styles.titleline)}>
                    <p>{t("campaigns")}</p>
                    <p>{t("month")}</p>
                  </div>
                  <div className={classNames(styles.linedash)}>
                    <div
                      style={{ width: "55%" }}
                      className={classNames(
                        styles.value,
                        styles.greenvalue
                      )}
                    ></div>
                    <div
                      style={{ width: "45%" }}
                      className={classNames(styles.background)}
                    ></div>
                  </div>
                  <p className={classNames(styles.linefont)}>
                    16 {t("campaigns_sent")} 30
                  </p>
                </div>
              </div>
            </div> */}
        {auth.authenticated ? (
          <>
            {/* <div
            className={classNames(
              styles.padding,
              styles.ht1,
              styles.float_left,
              styles.ht0sm,
              "col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3"
            )}
          >
            <div className={classNames(styles.tile, styles.textcolor)}>
              <div className={classNames(styles.light)}>
                <a
                  href="https://landing-page-connect.mgi-labs.com/#Abonnements"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className={classNames(styles.btn)}>
                    <p>{t("upgrade")}</p>
                  </div>
                </a>
              </div>
              <div className={classNames(styles.topbar, "col-12")}>
                <p>{t("Subscriptions")}</p>
              </div>
              <div className={classNames(styles.content, "col-12")}>
                <p>{t("Starter_text")}</p>
                <p>
                  {t("Starter_text_1")} {t("Starter_text_2")}
                </p>
                <p>{t("Starter_text_3")}</p>
                <p>- Dashboard - JetForce</p>
              </div>
            </div>
          </div> */}
            <div
              className={classNames(
                styles.padding,
                styles.ht1,
                styles.float_left,
                styles.ht0sm,
                "col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
              )}
            >
              <div className={classNames(styles.tile, styles.textcolor)}>
                <div className={classNames(styles.light)}>
                  <Link to="/applications">
                    <div className={classNames(styles.btn)}>
                      <p>{t("Applications")}</p>
                    </div>
                  </Link>
                </div>
                <div className={classNames(styles.topbar, "col-12")}>
                  <p>{t("Applications")}</p>
                  <div className={classNames(styles.text_icon)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 0 24 24"
                      width="24px"
                      fill="#FFFFFF"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z" />
                    </svg>
                    {/* <img className={classNames(styles.cloud)} src={eye} alt="Eye" /> */}
                  </div>
                </div>
                <div className={classNames(styles.content, "col-12")}>
                  <p>{t("Applicationstexte")}</p>
                </div>
              </div>
            </div>

            <div
              className={classNames(
                styles.padding,
                styles.ht1,
                styles.float_left,
                styles.ht0sm,

                "col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
              )}
            >
              <div
                className={classNames(
                  styles.tile,
                  styles.bkgblue,
                  styles.textcolor
                )}
              >
                <div className={classNames(styles.light)}>
                  <Link
                    onClick={() => setIsVisibleEquipment(true)}
                    to="/workteam#addequipment"
                  >
                    <div className={classNames(styles.btn)}>
                      <p>{t("addequipment")}</p>
                    </div>
                  </Link>
                </div>
                <div
                  className={classNames(
                    styles.topbar,
                    styles.bkgblue,
                    styles.borderbottombkgheighcolor,
                    "col-12"
                  )}
                >
                  <p>{t("addequipment")}</p>
                  <div className={classNames(styles.text_icon)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                      fill="#FFFFFF"
                    >
                      <path d="M720-680H240v-160h480v160Zm0 220q17 0 28.5-11.5T760-500q0-17-11.5-28.5T720-540q-17 0-28.5 11.5T680-500q0 17 11.5 28.5T720-460Zm-80 260v-160H320v160h320Zm80 80H240v-160H80v-240q0-51 35-85.5t85-34.5h560q51 0 85.5 34.5T880-520v240H720v160Z" />
                    </svg>
                    {/* <img className={classNames(styles.cloud)} src={eye} alt="Eye" /> */}
                  </div>
                </div>
                <div className={classNames(styles.content, "col-12")}>
                  <p>
                    {isharrisbruno()
                      ? t("addequipmenttexthb")
                      : t("addequipmenttext")}
                  </p>
                </div>
              </div>
            </div>

            {/* 
            Tuile register
            
            <div
              data-theme={"dark"}
              className={classNames(
                styles.padding,
                styles.ht1,
                styles.ht1sm,
                styles.float_left,

                "col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
              )}
            >
              <div
                className={classNames(
                  styles.tile,
                  styles.align,
                  styles.registerbackground
                )}
              >
                <a
                  href={process.env.REACT_APP_REGISTER_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className={classNames(styles.light)}></div>
                </a>
                <div className={classNames(styles.topbardecouvrir, "col-12")}>
                  <img src={register_logo_alone} alt="Logo Register" />

                  <p className={classNames(styles.logotext)}>REGISTER</p>
                </div>
                <div className={classNames(styles.contentdecouvrir, "col-12")}>
           
                </div>
      

                <div
                  className={classNames(
                    styles.botbardecouvrir,
                    styles.bottbarbluecolor,
                    "col-12"
                  )}
                >
                  <div></div>

                  <div className={classNames(styles.btngroup)}>
                    <div
                      className={classNames(
                        styles.btn,
                        styles.positionrelativebtn
                      )}
                    >
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={process.env.REACT_APP_REGISTER_URL}
                      >
                        <p>{t("Registerproduct")}</p>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={process.env.REACT_APP_REGISTER_URL}
                      >
                        <p>{t("Ouvrir")}</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        ) : (
          <></>
        )}
        {/* <div
          data-theme={"dark"}
          className={classNames(
            styles.padding,
            styles.ht1,
            styles.ht1sm,
            styles.float_left,

            "col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
          )}
        >
          {/*          <div
            className={classNames(
              styles.tile,
              styles.align,
              styles.shopfoilbackground
            )}
          >
            <a
              href={process.env.REACT_APP_SHOPFOIL_URL}
              target="_blank"
              rel="noreferrer"
            >
              <div className={classNames(styles.light)}></div>
            </a>
            <div className={classNames(styles.topbardecouvrir, "col-12")}>
              <img src={mgi_logo_alone} alt="Logo MGI" />

              <p className={classNames(styles.logotext)}>SHOP FOIL</p>
            </div>
            <div className={classNames(styles.contentdecouvrir, "col-12")}>
              {/* <p>
              Donnez plus de relief <br /> à vos projets, avec MGI Approve
            </p>
            <img src={ecran} alt="screen" /> 
            </div>
            {/* <img src={approve} alt="Video de approve" /> 

            <div
              className={classNames(
                styles.botbardecouvrir,
                styles.bottbarmaxwidth,
                "col-12"
              )}
            >
              <div></div>

              <div className={classNames(styles.btngroup)}>
                <p>{t("Shopfoilproduct")}</p>
                <div
                  className={classNames(styles.btn, styles.positionrelativebtn)}
                >
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={process.env.REACT_APP_SHOPFOIL_URL}
                  >
                    <p>{t("vistsite")}</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {isVisibleEquipment ? (
          <Addequipmentdesktop
            setIsVisibleEquipment={setIsVisibleEquipment}
            mutate={mutate}
          ></Addequipmentdesktop>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export function chartcolors(value: number) {
  if (value > 0) {
    if (value < 50) {
      return "rgb(252, 62, 114)";
    } else if (value > 50 && value < 70) {
      return "rgb(252, 167, 62)";
    } else {
      return "rgb(13, 222, 126)";
    }
  }
}

export default Homepage;
